import { Checkbox, CheckboxGroup, Menu } from "@mantine/core";
import React, { ReactElement } from "react";
import { BUNKER_REQUESTS_STATUSES } from "../../constants";

type FilterStatusMenuProps = {
  controlButton: ReactElement;
  onChange: (selectedValues: string[]) => void;
  initialValues: string[];
};

export default function FilterStatusMenu(props: FilterStatusMenuProps) {
  const { controlButton, onChange, initialValues } = props;

  return (
    <Menu
      control={controlButton}
      styles={(theme) => {
        return {
          label: {
            fontSize: 16,
          },
          body: {
            width: 175,
          },
        };
      }}
    >
      <CheckboxGroup
        onChange={onChange}
        orientation={"vertical"}
        defaultValue={initialValues}
        spacing={"sm"}
        m={"sm"}
        sx={(theme) => ({
          "& > div": {
            padding: 0,
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      >
        {BUNKER_REQUESTS_STATUSES.map((status) => (
          <Checkbox
            label={status.label}
            value={status.value}
            key={status.value}
          />
        ))}
      </CheckboxGroup>
    </Menu>
  );
}
