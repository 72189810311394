import React, {useEffect, useState} from "react";
import {Center, Loader,} from '@mantine/core';
import {Location, PatchableLocation} from "../../../../types";
import LocationForm from "./LocationForm";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useMutateLocationData from "../../../hooks/location/useMutateLocationData";
import {fetchLocation, updateLocation} from "../../../api/locations";

type EditLocationFormProps = {
    locationId: string;
    onClose: () => void;
}

function EditLocationForm(props: EditLocationFormProps) {
    const { locationId, onClose } = props;
    const [location, setLocation] = useState<Location | undefined>();

    const onSuccess = (values: Location) => {
        showNotification({
            title: 'Location updated',
            icon: <Check/>,
            message: `Location '${values.name}' has been updated!`,
        })

        onClose()
    }

    const {mutate, isLoading} = useMutateLocationData(updateLocation, onSuccess);

    const onSubmit = (values: PatchableLocation) => {
        mutate(values)
    }

    const onLoad = async () => {
        let data = await fetchLocation(locationId);
        setLocation(data);
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <>
            {!location ?
                <Center>
                    <Loader height={200}/>
                </Center> :
                <LocationForm
                    isLoading={isLoading}
                    location={location}
                    submitAction={(values) => onSubmit(values)}
                />
            }
        </>
    );
}

export default EditLocationForm;
