import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { Button, Group, Select, TextInput } from "@mantine/core";
import {
  NotificationSetting,
  PatchableNotificationSetting,
} from "../../../../types";
import { validateString } from "../../../validator";
import {
  NOTIFICATION_SETTING_EVENTS,
  NOTIFICATION_SETTING_METHODS,
} from "../../../constants";
import { RoleSelect } from "../../selects/RoleSelect";
import { UserSelect } from "../../selects/UserSelect";
import { Mail, Phone } from "tabler-icons-react";

type NotificationSettingFormProps = {
  notificationSetting?: NotificationSetting;
  submitText?: string;
  submitAction: (values: PatchableNotificationSetting) => void;
  isLoading?: boolean;
};

function NotificationSettingForm(props: NotificationSettingFormProps) {
  const {
    notificationSetting,
    submitText,
    submitAction,
    isLoading = false,
  } = props;
  const [defaultNotifiableValue, setDefaultNotifiableValue] = useState<string>(
    notificationSetting?.notifiable?.name ?? ""
  );

  const form = useForm<PatchableNotificationSetting>({
    initialValues: {
      id: notificationSetting?.id ?? "",
      notifiable_id: notificationSetting?.notifiable_id ?? null,
      notifiable_type: notificationSetting?.notifiable_type ?? null,
      type: notificationSetting?.type ?? "",
      event: notificationSetting?.event ?? "",
      method: notificationSetting?.method ?? "",
      phone: notificationSetting?.phone ?? null,
      email: notificationSetting?.email ?? null,
    },

    validate: {
      type: (value) => validateString(value),
    },
  });

  useEffect(() => {
    setDefaultNotifiableValue("");

    if (form.values.type === "user")
      form.setFieldValue("notifiable_type", "App\\Models\\User");
    else if (form.values.type === "role")
      form.setFieldValue("notifiable_type", "App\\Models\\Role");
    else form.setFieldValue("notifiable_type", null);

    form.values.type === "email" && form.setFieldValue("phone", "");
    form.values.type === "phone" && form.setFieldValue("email", "");
  }, [form.values.type]);

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <Select
        pb={"sm"}
        label={"Event"}
        clearable
        required
        data={NOTIFICATION_SETTING_EVENTS}
        {...form.getInputProps("event")}
        styles={(theme) => ({
          item: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <Select
        pb={"sm"}
        label={"Recipient"}
        clearable
        required
        data={[
          { label: "User", value: "user" },
          { label: "Role", value: "role" },
          { label: "Phone nummer", value: "phone" },
          { label: "E-mail address", value: "email" },
        ]}
        {...form.getInputProps("type")}
        styles={(theme) => ({
          item: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      {["user", "role"].includes(form.values.type) && (
        <Select
          pb={"sm"}
          label={"Method"}
          clearable
          required
          data={NOTIFICATION_SETTING_METHODS}
          {...form.getInputProps("method")}
          styles={(theme) => ({
            item: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      )}

      {form.values.type === "phone" && (
        <TextInput
          required
          label="Phone number"
          placeholder={"+31 528 123456"}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            description: {
              color: "#8C9196",
            },
            icon: {
              color: "#d5d9de",
            },
            required: {
              color: "#004F65",
            },
          })}
          icon={<Phone size={16} />}
        />
      )}

      {form.values.type === "email" && (
        <TextInput
          type={"email"}
          required
          label="E-mail address"
          {...form.getInputProps("email")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            description: {
              color: "#8C9196",
            },
            icon: {
              color: "#d5d9de",
            },
            required: {
              color: "#004F65",
            },
          })}
          icon={<Mail size={16} />}
        />
      )}

      {form.values.type === "role" && (
        <RoleSelect
          required
          defaultValue={defaultNotifiableValue}
          onClear={() => {
            form.setFieldValue("notifiable_id", "");
          }}
          onItemSubmit={(value) => {
            form.setFieldValue("notifiable_id", value.role.id);
          }}
        />
      )}

      {form.values.type === "user" && (
        <UserSelect
          required
          defaultValue={defaultNotifiableValue}
          onClear={() => {
            form.setFieldValue("notifiable_id", "");
          }}
          onItemSubmit={(value) => {
            form.setFieldValue("notifiable_id", value.user.id);
          }}
        />
      )}

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Save"}
        </Button>
      </Group>
    </form>
  );
}

export default NotificationSettingForm;
