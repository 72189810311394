import useMutateShipData from "../../../hooks/ship/useMutateShipData";
import {fetchShip, updateShip} from "../../../api/ships";
import ShipForm from "./ShipForm";
import {PatchableShip, Ship} from "../../../../types";
import React, {useEffect, useState} from "react";
import {Center, Loader} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

type Props = {
    id: string;
    onClose: () => void,
}

export default function EditShipForm(props: Props) {
    const {id, onClose} = props;
    const [ship, setShip] = useState<Ship | undefined>();

    const onSuccess = (ship: Ship) => {
        showNotification({
            title: 'Ship updated',
            icon: <Check/>,
            message: `Ship '${ship.name}' has been updated!`,
        });

        onClose();
    }

    const onSubmit = (values: PatchableShip) => {
        mutate(values);
    }

    const {mutate, isLoading} = useMutateShipData(updateShip, onSuccess);

    const onLoad = async () => {
        setShip(await fetchShip(id));
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <>
            {!ship ? (
                <Center>
                    <Loader height={200}/>
                </Center>
            ) : (
                <ShipForm submitAction={onSubmit} ship={ship} isLoading={isLoading}/>
            )}
        </>
    );
}