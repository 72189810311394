import React from "react";
import { Card, createStyles, Group, Switch, Text, Title } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  title: {
    lineHeight: 1,
  },
}));

interface SwitchesCardProps {
  title: string;
  description?: string;
  data: {
    title: string;
    description: string;
    onChange: (value: boolean) => void;
    initialValue?: boolean;
  }[];
}

export function UserSettings({ title, description, data }: SwitchesCardProps) {
  const { classes } = useStyles();

  const items = data.map((item) => (
    <Card className={classes.card} withBorder>
      <Group position="apart" noWrap spacing="xl">
        <div>
          <Text>{item.title}</Text>
          {item.description && <Text size="xs">{item.description}</Text>}
        </div>
        <Switch
          onLabel="ON"
          offLabel="OFF"
          checked={item.initialValue}
          className={classes.switch}
          size="lg"
          onChange={(event) => item.onChange(event.currentTarget.checked)}
        />
      </Group>
    </Card>
  ));

  return (
    <>
      <Title order={3}>{title}</Title>
      <Text size="xs" mt={3} mb={"md"}>
        {description}
      </Text>
      {items}
    </>
  );
}
