import {createContext, ReactNode, useEffect, useState} from "react";
import {SortingRule} from "react-table";
import {useLocation} from "react-router-dom";

type Props = {
    children?: ReactNode;
}

type FancyTableContextProps = {
    sortingState: SortingRule<object>[],
    setSortingState: (sortingState: SortingRule<object>[]) => void,
}

export const FancyTableContext = createContext<FancyTableContextProps>({
    sortingState: [],
    setSortingState: () => {},
});

export default function FancyTableProvider(props: Props) {
    const [sortingState, setSortingState] = useState<SortingRule<object>[]>([]);
    const location = useLocation();

    /**
     * Clear the sorting state when the location changes.
     */
    useEffect(() => {
        setSortingState([]);
    }, [location]);

    return (
        <FancyTableContext.Provider value={{sortingState, setSortingState}}>
            {props.children}
        </FancyTableContext.Provider>
    );
}