import { Location } from "../../../types";
import { Anchor, createStyles, Table } from "@mantine/core";
import React from "react";
import useLocationMap from "../../hooks/location/useLocationMap";

type Props = {
  location: Location;
};

const useStyles = createStyles((theme) => ({
  table: {
    border: "none",
    width: "auto",

    "tbody tr td": {
      border: "none",
      padding: "2px 50px 2px 0",
    },
  },
}));

export default function LocationDetailTable(props: Props) {
  const { location } = props;
  const { classes } = useStyles();
  const { openModal: openMapModal } = useLocationMap();

  return (
    <Table
      className={classes.table}
      sx={(theme) => ({
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[0]
            : theme.colors["custom-gray"][0],
      })}
    >
      <tbody>
        <tr>
          <td>Phone</td>
          <td>
            <Anchor size={"sm"} href={`tel:${location.phone}`}>
              {location.phone}
            </Anchor>
          </td>
        </tr>
        <tr>
          <td>E-mail address</td>
          <td>
            <Anchor size={"sm"} href={`mailto:${location.email}`}>
              {location.email}
            </Anchor>
          </td>
        </tr>
        <tr>
          <td>Location</td>
          <td>
            <Anchor
              title={"Show location on map"}
              size={"sm"}
              onClick={() =>
                openMapModal({
                  latitude: location.lat,
                  longitude: location.lon,
                })
              }
            >
              {location.location}
            </Anchor>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
