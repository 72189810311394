import React, { useContext } from "react";
import { AppShell, Footer, Text } from "@mantine/core";
import FullRouteCollection from "./components/routing/FullRouteCollection";
import { useLocation } from "react-router-dom";
import AppHeader from "./components/AppShell/AppHeader";
import { NavbarContext } from "./providers/navbar";
import AppNavbar from "./components/AppShell/AppNavbar";

/**
 * Determine if a route is either /login or /verify* (* as in wildcard)
 */
function isAuthRoute(pathname: string) {
  return [
    new RegExp("\\/login"),
    new RegExp("\\/verify/*"),
    new RegExp("\\/password-*"),
  ].some((route) => !!pathname.match(route));
}

export default function App() {
  const { opened, setOpened } = useContext(NavbarContext);

  const { pathname } = useLocation();
  const authRoute = isAuthRoute(pathname);

  return authRoute ? (
    <FullRouteCollection />
  ) : (
    <AppShell
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      header={<AppHeader opened={opened} setOpened={setOpened} />}
      navbar={<AppNavbar opened={opened} />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
      })}
      footer={
        <Footer height={60} p="md">
          <Text color={"dimmed"}>&copy; Webba B.V.</Text>
        </Footer>
      }
    >
      <FullRouteCollection />
    </AppShell>
  );
}
