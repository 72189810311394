import React from "react";
import {useModals} from "@mantine/modals";
import CreateRoleForm from "../../components/forms/role/CreateRoleForm";

export default function useCreateRole() {
    const modals = useModals();

    const openModal = () => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Create role',
            closeOnClickOutside: false,
            children: (
                <>
                    <CreateRoleForm onClose={() => closeModal(modalId)} />
                </>
            ),
        });
    }

    const closeModal = function(modalId: string) {
        modals.closeModal(modalId);
    }

    return {
        openModal,
    }
}