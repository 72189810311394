import React, {useContext} from 'react';
import {Group, useMantineTheme} from '@mantine/core';
import {useNavigate} from "react-router-dom";
import {NavbarContext} from "../../providers/navbar";
import {useMediaQuery} from "@mantine/hooks";

export function Brand() {
    const theme = useMantineTheme();
    const smallScreen = useMediaQuery('(max-width: 576px)');
    const {setOpened: setNavbarOpened} = useContext(NavbarContext);
    const navigate = useNavigate();

    const navigateAway = () => {
        navigate("/")
        setNavbarOpened(false)
    }

    return (
      <Group
        style={{ cursor: "pointer" }}
        position="apart"
        onClick={navigateAway}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="73.598"
          height="38.02"
          viewBox="0 0 73.598 38.02"
        >
          <g transform="translate(-1.7 -.8)">
            <g
              fill="#289323"
              data-name="Group 81"
              transform="translate(1.7 14.455)"
            >
              <path
                d="M9.216 46.838L1.7 23.6h5.261l5.2 16.348h.063l5.26-16.348h5.261l-7.766 23.238z"
                data-name="Path 300"
                transform="translate(-1.7 -22.974)"
              ></path>
              <path
                d="M35.5 27.321V23.5h4.635v3.821zm4.635 2.631V46.8H35.5V29.952z"
                data-name="Path 301"
                transform="translate(-14.329 -22.936)"
              ></path>
              <path
                d="M58.882 46.277a9.322 9.322 0 01-3.382.689 12.045 12.045 0 01-4.823-.94 9.346 9.346 0 01-3.57-2.568 10.654 10.654 0 01-2.255-3.821 14.587 14.587 0 01-.752-4.76 14.971 14.971 0 01.752-4.823 10.921 10.921 0 012.255-3.883 10.584 10.584 0 013.57-2.631 11.833 11.833 0 014.823-.94 10.457 10.457 0 013.445.564 9.805 9.805 0 013.007 1.566 9.931 9.931 0 012.254 2.57 8.859 8.859 0 011.065 3.508h-4.886a5 5 0 00-1.754-2.944 5.335 5.335 0 00-3.132-1 6.193 6.193 0 00-2.944.689 5.407 5.407 0 00-1.942 1.816 7.2 7.2 0 00-1.065 2.568 11.47 11.47 0 00-.313 2.944 13.393 13.393 0 00.313 2.819 8.178 8.178 0 001.066 2.5 5.242 5.242 0 001.942 1.754 6.016 6.016 0 002.944.689 5.562 5.562 0 003.946-1.315 5.815 5.815 0 001.629-3.758h-5.137v-3.821h9.772v12.59h-3.258l-.5-2.631a8.717 8.717 0 01-3.07 2.569z"
                data-name="Path 302"
                transform="translate(-17.542 -22.6)"
              ></path>
              <path
                d="M80.326 38.261a9.3 9.3 0 011.754-2.819 8.449 8.449 0 012.756-1.816 10.488 10.488 0 017.141 0 7.56 7.56 0 012.756 1.816 7.987 7.987 0 011.754 2.819 9.845 9.845 0 01.626 3.633 9.622 9.622 0 01-.626 3.633 8.3 8.3 0 01-1.754 2.756 7.421 7.421 0 01-2.756 1.754 10.488 10.488 0 01-7.141 0 7.529 7.529 0 01-4.51-4.51 9.622 9.622 0 01-.626-3.633 11.033 11.033 0 01.626-3.633zm4.2 5.637a6.02 6.02 0 00.689 1.754 3.257 3.257 0 001.253 1.19 4.519 4.519 0 003.883 0 3.8 3.8 0 001.253-1.19 4.9 4.9 0 00.686-1.752 10.2 10.2 0 00.188-1.942 10.854 10.854 0 00-.188-2 5.4 5.4 0 00-.69-1.758 3.8 3.8 0 00-1.253-1.19 4.013 4.013 0 00-3.883 0 4.558 4.558 0 00-1.253 1.19 4.9 4.9 0 00-.689 1.754 10.367 10.367 0 00-.188 2 10.2 10.2 0 00.189 1.946z"
                data-name="Path 303"
                transform="translate(-30.844 -26.486)"
              ></path>
            </g>
            <g
              fill="#a9d4a7"
              data-name="Group 82"
              transform="translate(31.891 7.001)"
            >
              <path
                d="M50.276 18.252a.376.376 0 11.376-.376.4.4 0 01-.376.376z"
                data-name="Path 304"
                transform="translate(-49.9 -13.241)"
              ></path>
              <path
                d="M54.489 15.278a.689.689 0 11.689-.689.7.7 0 01-.689.689z"
                data-name="Path 305"
                transform="translate(-51.357 -11.896)"
              ></path>
              <path
                d="M59.877 13.454a.877.877 0 11.877-.877.856.856 0 01-.877.877z"
                data-name="Path 306"
                transform="translate(-53.3 -11.074)"
              ></path>
              <path
                d="M66.1 12.7a1 1 0 111-1 1.03 1.03 0 01-1 1z"
                data-name="Path 307"
                transform="translate(-55.579 -10.7)"
              ></path>
              <circle
                cx="1.065"
                cy="1.065"
                r="1.065"
                data-name="Ellipse 277"
                transform="translate(13.342 .564)"
              ></circle>
              <path
                d="M78.065 16.33a1.065 1.065 0 111.065-1.065 1.1 1.1 0 01-1.065 1.065z"
                data-name="Path 308"
                transform="translate(-60.026 -12.008)"
              ></path>
              <path
                d="M83.4 19.7a1 1 0 111-1 .989.989 0 01-1 1z"
                data-name="Path 309"
                transform="translate(-62.043 -13.315)"
              ></path>
              <path
                d="M88.54 23.379a.94.94 0 11.94-.94.963.963 0 01-.94.94z"
                data-name="Path 310"
                transform="translate(-63.986 -14.735)"
              ></path>
              <path
                d="M93.814 26.729a.814.814 0 11.814-.814.829.829 0 01-.814.814z"
                data-name="Path 311"
                transform="translate(-66.004 -16.08)"
              ></path>
              <path
                d="M99.389 29.578a.689.689 0 11.689-.689.7.7 0 01-.689.689z"
                data-name="Path 312"
                transform="translate(-68.133 -17.239)"
              ></path>
              <path
                d="M104.964 31.627a.564.564 0 11.564-.564.561.561 0 01-.564.564z"
                data-name="Path 313"
                transform="translate(-70.263 -18.098)"
              ></path>
              <path
                d="M110.038 32.577a.438.438 0 11.438-.438.528.528 0 01-.438.438z"
                data-name="Path 314"
                transform="translate(-72.206 -18.546)"
              ></path>
              <path
                d="M118.788 30.876a.188.188 0 11.188-.188.282.282 0 01-.188.188z"
                data-name="Path 315"
                transform="translate(-75.569 -18.098)"
              ></path>
              <path
                d="M114.713 32.526a.313.313 0 11.313-.313.3.3 0 01-.313.313z"
                data-name="Path 316"
                transform="translate(-74 -18.621)"
              ></path>
            </g>
            <g
              fill="#68b365"
              data-name="Group 83"
              transform="translate(31.953 3.869)"
            >
              <path
                d="M50.376 13.652a.376.376 0 11.376-.376.361.361 0 01-.376.376z"
                data-name="Path 317"
                transform="translate(-50 -8.39)"
              ></path>
              <path
                d="M54.789 10.378a.689.689 0 11.689-.689.7.7 0 01-.689.689z"
                data-name="Path 318"
                transform="translate(-51.532 -6.933)"
              ></path>
              <path
                d="M60.277 8.354a.877.877 0 11.877-.877.856.856 0 01-.877.877z"
                data-name="Path 319"
                transform="translate(-53.512 -6.036)"
              ></path>
              <path
                d="M66.5 7.7a1 1 0 111-1 1.03 1.03 0 01-1 1z"
                data-name="Path 320"
                transform="translate(-55.791 -5.7)"
              ></path>
              <circle
                cx="1.065"
                cy="1.065"
                r="1.065"
                data-name="Ellipse 278"
                transform="translate(13.529 .564)"
              ></circle>
              <circle
                cx="1.065"
                cy="1.065"
                r="1.065"
                data-name="Ellipse 279"
                transform="translate(17.162 2.13)"
              ></circle>
              <path
                d="M83.8 14.7a1 1 0 111-1 1.03 1.03 0 01-1 1z"
                data-name="Path 321"
                transform="translate(-62.255 -8.315)"
              ></path>
              <circle
                cx="0.94"
                cy="0.94"
                r="0.94"
                data-name="Ellipse 280"
                transform="translate(23.802 6.702)"
              ></circle>
              <path
                d="M94.114 22.029a.814.814 0 11.814-.814.79.79 0 01-.814.814z"
                data-name="Path 322"
                transform="translate(-66.178 -11.192)"
              ></path>
              <path
                d="M99.452 25.3a.752.752 0 11.752-.752.724.724 0 01-.752.752z"
                data-name="Path 323"
                transform="translate(-68.196 -12.463)"
              ></path>
              <path
                d="M104.964 27.827a.564.564 0 11.564-.564.561.561 0 01-.564.564z"
                data-name="Path 324"
                transform="translate(-70.326 -13.546)"
              ></path>
              <circle
                cx="0.438"
                cy="0.438"
                r="0.438"
                data-name="Ellipse 281"
                transform="translate(37.269 13.968)"
              ></circle>
              <circle
                cx="0.251"
                cy="0.251"
                r="0.251"
                data-name="Ellipse 282"
                transform="translate(42.843 12.966)"
              ></circle>
              <circle
                cx="0.313"
                cy="0.313"
                r="0.313"
                data-name="Ellipse 283"
                transform="translate(40.275 14.031)"
              ></circle>
            </g>
            <g
              fill="#289323"
              data-name="Group 84"
              transform="translate(32.079 .8)"
            >
              <path
                d="M50.576 8.752a.376.376 0 11.376-.376.361.361 0 01-.376.376z"
                data-name="Path 325"
                transform="translate(-50.2 -3.49)"
              ></path>
              <path
                d="M54.989 5.678a.689.689 0 11.689-.689.74.74 0 01-.689.689z"
                data-name="Path 326"
                transform="translate(-51.732 -2.108)"
              ></path>
              <path
                d="M60.677 3.554a.856.856 0 01-.877-.877.877.877 0 011.754 0 .856.856 0 01-.877.877z"
                data-name="Path 327"
                transform="translate(-53.787 -1.174)"
              ></path>
              <path
                d="M66.9 2.8a.989.989 0 01-1-1 1 1 0 112 0 .989.989 0 01-1 1z"
                data-name="Path 328"
                transform="translate(-56.066 -.8)"
              ></path>
              <circle
                cx="1.065"
                cy="1.065"
                r="1.065"
                data-name="Ellipse 284"
                transform="translate(13.655 .564)"
              ></circle>
              <path
                d="M78.865 6.33A1.1 1.1 0 0177.8 5.265a1.065 1.065 0 112.13 0 1.056 1.056 0 01-1.065 1.065z"
                data-name="Path 329"
                transform="translate(-60.512 -2.07)"
              ></path>
              <path
                d="M84.2 9.8a1 1 0 111-1 .989.989 0 01-1 1z"
                data-name="Path 330"
                transform="translate(-62.53 -3.415)"
              ></path>
              <path
                d="M89.34 13.379a.94.94 0 11.94-.94.922.922 0 01-.94.94z"
                data-name="Path 331"
                transform="translate(-64.473 -4.798)"
              ></path>
              <path
                d="M94.414 17.329a.814.814 0 11.814-.814.829.829 0 01-.814.814z"
                data-name="Path 332"
                transform="translate(-66.416 -6.367)"
              ></path>
              <path
                d="M99.552 20.9a.752.752 0 11.752-.752.724.724 0 01-.752.752z"
                data-name="Path 333"
                transform="translate(-68.359 -7.75)"
              ></path>
              <circle
                cx="0.626"
                cy="0.626"
                r="0.626"
                data-name="Ellipse 285"
                transform="translate(33.824 13.592)"
              ></circle>
              <path
                d="M109.838 25.177a.438.438 0 11.438-.438.428.428 0 01-.438.438z"
                data-name="Path 334"
                transform="translate(-72.319 -9.58)"
              ></path>
              <path
                d="M118.551 23.6a.251.251 0 11.251-.251.229.229 0 01-.251.251z"
                data-name="Path 335"
                transform="translate(-75.645 -9.132)"
              ></path>
              <path
                d="M114.513 25.226a.313.313 0 11.313-.313.336.336 0 01-.313.313z"
                data-name="Path 336"
                transform="translate(-74.113 -9.693)"
              ></path>
            </g>
          </g>
        </svg>
      </Group>
    );
}
