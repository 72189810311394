import {generateSortString, handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import {Location} from "../../types";
import {saveAs} from "file-saver";

export const fetchLocations = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/location?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchLocation = async (id: string) => {
    return await fetch(`${API_URL}/location/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createLocation(project: Location) {
    return await fetch(`${API_URL}/location`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(project)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateLocation(location: Location) {
    return await fetch(`${API_URL}/location/${location.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(location)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function deleteLocation (ids: string[]) {
    return await fetch(`${API_URL}/location`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
        body: JSON.stringify({
            "locations": ids
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}

export const exportLocationData = async (sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/export/location?${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'locations.csv'))
        .catch((error) => {
            throw error.message;
        });
};
