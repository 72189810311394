import { Button, Card, Grid, Group, Loader, Paper, Text } from "@mantine/core";
import { useQuery } from "react-query";
import React from "react";
import { fetchTimeSlots } from "../../api/timeSlots";
import { TimeSlot } from "../../../types";
import { Pencil, Trash } from "tabler-icons-react";
import { DAYS_OF_WEEK } from "../../constants";

type TimeSlotTableProps = {
  locationId: string;

  onEdit?: (id: string) => void;
  onDelete?: (ids: string[]) => void;
};

export default function TimeSlotTable(props: TimeSlotTableProps) {
  const { locationId, onEdit, onDelete } = props;

  const {
    status,
    isError,
    isFetching,
    isSuccess,
    data: originalData,
  } = useQuery(
    ["timeSlots", locationId],
    () => fetchTimeSlots(0, 100, null, locationId),
    { keepPreviousData: true }
  );

  const data = React.useMemo(() => {
    return isSuccess ? originalData : { data: [], meta: { total: 0 } };
  }, [originalData]);

  const isLoading = status === "loading" || isFetching || isError;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid columns={7}>
            {DAYS_OF_WEEK.map((day: any) => (
              <Grid.Col sm={7} md={3} xl={1} key={day.value}>
                <Paper
                  withBorder
                  shadow="sm"
                  p="md"
                  style={{ minHeight: "100%" }}
                  sx={(theme) => ({
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.colors["custom-gray"][0],
                  })}
                >
                  <Text weight={"500"} mb={"sm"}>
                    {day.label}
                  </Text>

                  {data
                    .filter(
                      (value: TimeSlot) => value.day?.toString() === day.value
                    )
                    .map((value: TimeSlot) => {
                      return (
                        <Card key={value.id} withBorder mb={"md"} p={"md"}>
                          <Group
                            position={"apart"}
                            sx={(theme) => ({
                              color:
                                theme.colorScheme === "dark"
                                  ? theme.colors.dark[0]
                                  : theme.colors["custom-gray"][0],
                            })}
                          >
                            <Text>
                              {value.start_time} - {value.end_time}
                            </Text>

                            <Group spacing={5}>
                              {onEdit && (
                                <Button
                                  size={"sm"}
                                  variant={"outline"}
                                  onClick={() => onEdit(value.id)}
                                >
                                  <Pencil size={18} />
                                </Button>
                              )}

                              {onDelete && (
                                <Button
                                  size={"sm"}
                                  variant={"outline"}
                                  color={"custom-red"}
                                  onClick={() => onDelete([value.id])}
                                >
                                  <Trash size={18} />
                                </Button>
                              )}
                            </Group>
                          </Group>
                        </Card>
                      );
                    })}
                </Paper>
              </Grid.Col>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
