import React, {useEffect, useState} from 'react';
import {Button, Group, Title} from "@mantine/core";
import Calendar from 'rc-year-calendar';
import CreateBunkerRequestButton from "../../components/buttons/CreateBunkerRequestButton";
import {Menu2} from "tabler-icons-react";
import {Link, useNavigate} from "react-router-dom";
import {fetchBunkerRequestsCalendar} from "../../api/bunkerRequests";
import dayjs from "dayjs";
import {BUNKER_REQUESTS_STATUSES} from "../../constants";

export default function BunkerRequestsCalendar() {
    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear())
    const [data, setData] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBunkerRequestsCalendar(currentYear)
            .then((data) => setData(data.data))
    }, [currentYear])

    const formattedData = (data ?? []).map((value: any) => {
        let date = dayjs(value.date)

        return {
            id: value.date,
            startDate: date,
            endDate: date,
        };
    })

    const onYearChange = (evt: any) => {
        setCurrentYear(evt.currentYear)
    }

    const dataSourceRenderer = (elt: any) => {
        elt.className = "day-content indicator";
        elt.style.fontWeight = 800;
        return elt;
    }

    const onDateClick = (evt: any) => {
        let date = evt.date

        navigate("/requests", {
            state: {
                filters: {
                    selectedStatuses: BUNKER_REQUESTS_STATUSES.map((value) => value.value),
                    startDate: date,
                    endDate: date,
                }
            }
        })
    }

    return (
        <>
            <Group position={"apart"}>
                <Title>Bunker requests</Title>

                <Group>
                    <Button
                        component={Link}
                        to={"/requests"}
                        pl={10}
                        pr={10}
                    >
                        <Menu2/>
                    </Button>

                    <CreateBunkerRequestButton/>
                </Group>
            </Group>

            <Calendar
                onYearChanged={onYearChange}
                defaultYear={currentYear}
                dataSource={formattedData}
                style={'custom'}
                onDayClick={onDateClick}
                customDataSourceRenderer={dataSourceRenderer}
            />
        </>
    )
}
