import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { Check } from "tabler-icons-react";
import { BunkerRequest, PatchableBunkerRequest } from "../../../../types";
import {
  fetchBunkerRequest,
  updateBunkerRequest,
} from "../../../api/bunkerRequests";
import useMutateBunkerRequestData from "../../../hooks/bunkerRequest/useMutateBunkerRequestData";
import BunkerRequestForm from "./BunkerRequestForm";

type EditBunkerRequestFormProps = {
  bunkerRequestId: string;
  onClose: () => void;
};

function EditBunkerRequestForm(props: EditBunkerRequestFormProps) {
  const { bunkerRequestId, onClose } = props;
  const [bunkerRequest, setBunkerRequest] = useState<
    BunkerRequest | undefined
  >();

  const onSuccess = (values: BunkerRequest) => {
    showNotification({
      title: "Bunker request updated",
      icon: <Check />,
      message: `Bunker request has been updated!`,
    });

    onClose();
  };

  const { mutate, isLoading } = useMutateBunkerRequestData(
    updateBunkerRequest,
    onSuccess
  );

  const onSubmit = (values: PatchableBunkerRequest) => {
    mutate(values);
  };

  const onLoad = async () => {
    let data = await fetchBunkerRequest(bunkerRequestId);
    setBunkerRequest(data);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      {!bunkerRequest ? (
        <Center>
          <Loader height={200} />
        </Center>
      ) : (
        <BunkerRequestForm
          isLoading={isLoading}
          bunkerRequest={bunkerRequest}
          submitAction={(values) => onSubmit(values)}
          isEditing
        />
      )}
    </>
  );
}

export default EditBunkerRequestForm;
