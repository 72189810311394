import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./js/App";
import Providers from "./js/providers";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { env } from "./js/tools";

Sentry.init({
  dsn: env("SENTRY_DSN"),

  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: parseFloat(env("SENTRY_TRACES_SAMPLE_RATE", "0.0")),
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </React.StrictMode>
);
