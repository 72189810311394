import { Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";
import useMutateNotificationSettingData from "./useMutateNotificationSettingData";
import { deleteNotificationSetting } from "../../api/notificationSettings";

export default function useDeleteNotificationSettings() {
  const modals = useModals();

  const onSuccess = () => {
    showNotification({
      title: "Notification setting deleted",
      icon: <Check />,
      message: `The notification setting has been deleted!`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Something went wrong`,
      message: `An error occurred while deleting the notification setting. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateNotificationSettingData(
    deleteNotificationSetting,
    onSuccess,
    onError
  );

  const openModal = (id: string, locationId: string, onConfirm?: () => void) =>
    modals.openConfirmModal({
      centered: true,
      title: "Are you sure?",
      children: (
        <Text
          size="sm"
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          })}
        >
          This action cannot be undone. Are you sure you want to delete this
          notification setting?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "custom-red" },
      onCancel: () => {},
      onConfirm: async () => {
        await mutate({
          id: id,
          locationId: locationId,
        });

        onConfirm?.();
      },
    });

  return {
    openModal,
  };
}
