import { Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import useMutateTimeSlotExceptionData from "./useMutateTimeSlotExceptionData";
import { deleteTimeSlotException } from "../../api/timeSlotExceptions";
import { showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";

export default function useDeleteTimeSlotException() {
  const modals = useModals();

  const onSuccess = () => {
    showNotification({
      title: "Time slot exception deleted",
      icon: <Check />,
      message: `The time slot exception has been deleted!`,
    });
  };

  const onError = () => {
    showNotification({
      title: `Something went wrong`,
      message: `An error occurred while deleting the time slot exception.`,
      color: "red",
    });
  };

  const { mutate } = useMutateTimeSlotExceptionData(
    deleteTimeSlotException,
    onSuccess,
    onError
  );

  const openModal = (timeSlotExceptionId: string, onConfirm?: () => void) =>
    modals.openConfirmModal({
      centered: true,
      title: "Are you sure?",
      children: (
        <Text
          size="sm"
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          })}
        >
          This action cannot be undone. Are you sure you want to delete this
          time slot exception?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "custom-red" },
      onCancel: () => {},
      onConfirm: async () => {
        await mutate(timeSlotExceptionId);
        onConfirm?.();
      },
    });

  return {
    openModal,
  };
}
