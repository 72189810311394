import { Button, Group, TextInput } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { TimeSlotException } from "../../../../types";
import { convertTimeToDate } from "../../../../tools";

type Props = {
  timeSlotException?: TimeSlotException;
  date?: Date;
  location_id?: string;
  onSubmit: (values: TimeSlotException) => void;
  submitText?: string;
  isLoading: boolean;
};

export default function TimeSlotExceptionForm(props: Props) {
  const {
    timeSlotException,
    location_id,
    onSubmit,
    submitText,
    date,
    isLoading,
  } = props;

  const form = useForm<TimeSlotException>({
    initialValues: {
      id: timeSlotException?.id ?? "",
      description: timeSlotException?.description ?? "",
      date: new Date(timeSlotException?.date ?? date ?? ""),
      start_time: timeSlotException?.start_time
        ? convertTimeToDate(timeSlotException?.start_time)
        : null,
      end_time: timeSlotException?.end_time
        ? convertTimeToDate(timeSlotException?.end_time)
        : null,
      location_id: timeSlotException?.location_id ?? location_id ?? "",
    },
    validate: {
      description: (value) => null,
      date: (value) => null,
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Group grow mb="sm">
        <TextInput
          required
          label="Description"
          placeholder="Holiday"
          {...form.getInputProps("description")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
          })}
        />

        <DatePicker
          required
          allowFreeInput
          label="Date"
          placeholder="January 1, 2025"
          {...form.getInputProps("date")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
          })}
        />
      </Group>

      <Group grow mb="sm">
        <TimeInput
          clearable
          label="Start time"
          mx="auto"
          {...form.getInputProps("start_time")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
          })}
        />
        <TimeInput
          clearable
          label="End time"
          mx="auto"
          {...form.getInputProps("end_time")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
          })}
        />
      </Group>

      <Group position="right">
        <Button type="submit" loading={isLoading}>
          {submitText ?? "Create"}
        </Button>
      </Group>
    </form>
  );
}
