import React from 'react';
import {Button, Group, Title} from "@mantine/core";
import CustomerTable from "../../components/tables/CustomerTable";
import CreateCustomerButton from "../../components/buttons/CreateCustomerButton";
import {exportCustomerData} from "../../api/customers";
import useExportTableData from "../../hooks/useExportTableData";

export default function Customers() {
    const {exportTable, isLoading} = useExportTableData({exportFunction: exportCustomerData});

    return (
        <>
            <Group position={"apart"}>
                <Title>Customers</Title>

                <Group>
                    <Button loading={isLoading} onClick={() => exportTable()}>Export data</Button>
                    <CreateCustomerButton/>
                </Group>
            </Group>

            <CustomerTable />
        </>
    )
}