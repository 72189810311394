import {Title, Group, Button, Anchor} from "@mantine/core";
import NotificationSettingTable from "../../components/tables/NotificationSettingTable";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import useCreateNotificationSetting from "../../hooks/notificationSetting/useCreateNotificationSetting";
import useEditNotificationSetting from "../../hooks/notificationSetting/useEditNotificationSetting";
import useDeleteNotificationSettings from "../../hooks/notificationSetting/useDeleteNotificationSetting";
import useLocationData from "../../hooks/location/useLocationData";
import React from "react";

export default function Reporting() {
    const {id: locationId} = useParams();
    const navigate = useNavigate();
    const {openModal: openCreateModal} = useCreateNotificationSetting();
    const {openModal: openEditModal} = useEditNotificationSetting();
    const {openModal: openDeleteModal} = useDeleteNotificationSettings();

    const {status, data, isFetching, isError} = useLocationData(locationId);
    const isLoading = status === "loading" || isFetching;

    if (locationId === undefined || locationId === null || isError) {
        return <Navigate replace to={"/locations"} />
    }

    return (
        <>
            <Group position={"apart"} mb={"md"}>
                <Group direction={"column"} align={"flex-start"} spacing={0}>
                    <Anchor onClick={() => navigate(`/locations/${locationId}`)}>Return to location</Anchor>
                    <Title>{isLoading ? "Location" : data.data.name} reporting settings</Title>
                </Group>

                <Button onClick={() => openCreateModal(locationId)}>Add notification</Button>
            </Group>

            <NotificationSettingTable
                locationId={locationId}
                onEdit={(id) => openEditModal(id, locationId)}
                onDelete={(id) => openDeleteModal(id, locationId)}
            />
        </>
    );
}