import ShipForm from "./ShipForm";
import {PatchableShip, Ship} from "../../../../types";
import useMutateShipData from "../../../hooks/ship/useMutateShipData";
import {createShip} from "../../../api/ships";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

type CreateShipFormProps = {
    onClose: () => void;
}

export default function CreateShipForm(props: CreateShipFormProps) {
    const { onClose } = props;

    const onSuccess = (ship: Ship) => {
        showNotification({
            title: 'Ship added',
            icon: <Check/>,
            message: `Ship '${ship.name}' has been added!`,
        });

        onClose();
    }

    const onError = (error: string) => {
        showNotification({
            title: 'Something went wrong',
            message: `An error occurred while adding the ship. ${error}`,
            color: 'red',
        });
    }

    const {mutate, isLoading} = useMutateShipData(createShip, onSuccess, onError);

    const onSubmit = (values: PatchableShip) => {
        mutate(values);
    }

    return (
        <ShipForm submitAction={onSubmit} isLoading={isLoading}/>
    );
}