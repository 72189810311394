import React, {useEffect, useState} from "react";
import {Center, Loader,} from '@mantine/core';
import {Customer, PatchableCustomer} from "../../../../types";
import CustomerForm from "./CustomerForm";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useMutateCustomerData from "../../../hooks/customer/useMutateCustomerData";
import {fetchCustomer, updateCustomer} from "../../../api/customers";

type EditCustomerFormProps = {
    customerId: string;
    onClose: () => void;
}

function EditCustomerForm(props: EditCustomerFormProps) {
    const { customerId, onClose } = props;
    const [customer, setCustomer] = useState<Customer | undefined>();

    const onSuccess = (values: Customer) => {
        showNotification({
            title: 'Customer updated',
            icon: <Check/>,
            message: `Customer '${values.name}' has been updated!`,
        })

        onClose()
    }

    const {mutate, isLoading} = useMutateCustomerData(updateCustomer, onSuccess);

    const onSubmit = (values: PatchableCustomer) => {
        mutate(values)
    }

    const onLoad = async () => {
        let data = await fetchCustomer(customerId);
        setCustomer(data);
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <>
            {!customer ?
                <Center>
                    <Loader height={200}/>
                </Center> :
                <CustomerForm
                    isLoading={isLoading}
                    customer={customer}
                    submitAction={(values) => onSubmit(values)}
                />
            }
        </>
    );
}

export default EditCustomerForm;
