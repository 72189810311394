import RoleForm from "./RoleForm";
import {Role} from "../../../../types";
import useMutateRoleData from "../../../hooks/role/useMutateRoleData";
import {createRole} from "../../../api/roles";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import React from "react";

type CreateRoleFormProps = {
    onClose: () => void,
}

export default function CreateRoleForm(props: CreateRoleFormProps) {
    const {onClose} = props;

    const onSuccess = function(value: any) {
        showNotification({
            title: 'Role created',
            icon: <Check/>,
            message: `Role '${value.data.name}' has been created!`,
        });

        onClose();
    }

    const onError = function(error: string) {
        showNotification({
            title: 'Something went wrong',
            color: "red",
            message: `The role could not be created. ${error}`,
        });
    }

    const {mutate, isLoading} = useMutateRoleData(createRole, onSuccess, onError);

    const onSubmit = function(value: Role) {
        mutate(value);
    }

    return (
        <RoleForm onSubmit={onSubmit} loading={isLoading} submitText={"Create"} />
    );
}