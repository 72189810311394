import { Anchor, Center, Container, Group, Paper, Text } from "@mantine/core";
import LoginForm from "../components/forms/LoginForm";
import React from "react";
import { useNavigate } from "react-router-dom";
import useContactPropertyData from "../hooks/admin/useContactPropertyData";
import { Brand } from "../components/AppShell/_brand";

export default function Login() {
  const navigate = useNavigate();
  const { data: contactData, isLoading: isLoadingContactData } =
    useContactPropertyData();
  const contactEmail =
    !isLoadingContactData &&
    contactData.data.find((i: any) => i.name === "main_mail")?.value;

  return (
    <Container size={420} my={40}>
      <Paper withBorder shadow="md" radius="md" p="xl">
        <Center mb={"md"}>
          <Brand />
        </Center>

        <LoginForm />

        <Group mt={"md"} position="apart">
          <Anchor onClick={() => navigate("/password-forgotten")}>
            <Text>Forgot your password?</Text>
          </Anchor>
          {!isLoadingContactData && contactEmail && (
            <Anchor href={`mailto:${contactEmail}`}>
              <Text>Become a customer</Text>
            </Anchor>
          )}
        </Group>
      </Paper>
    </Container>
  );
}
