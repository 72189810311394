import {PatchableTimeSlot, TimeSlot} from "../../../../types";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useMutateTimeSlotData from "../../../hooks/timeSlot/useMutateTimeSlotData";
import {createTimeSlot} from "../../../api/timeSlots";
import TimeSlotForm from "./TimeSlotForm";

type CreateTimeSlotFormProps = {
    locationId?: string;
    onClose: () => void;
}

function CreateTimeSlotForm(props: CreateTimeSlotFormProps) {
    const { locationId, onClose } = props;

    const onSuccess = (values: TimeSlot) => {
        showNotification({
            title: 'Time slot created',
            icon: <Check/>,
            message: `Time slot has been created!`,
        })

        onClose()
    }

    const onError = (error: string) => {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while creating the time slot. ${error}`,
            color: 'red'
        })
    }

    const {mutate, isLoading} = useMutateTimeSlotData(createTimeSlot, onSuccess, onError);

    const onSubmit = (values: PatchableTimeSlot) => {
        mutate(values)
    }

    return (
        <>
            <TimeSlotForm
                locationId={locationId}
                isLoading={isLoading}
                submitAction={(values: PatchableTimeSlot) => onSubmit(values)}
            />
        </>
    );
}

export default CreateTimeSlotForm;
