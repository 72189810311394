import {Button} from "@mantine/core";
import React, {useContext} from "react";
import {UserContext} from "../../providers/user";
import useCreateTimeSlot from "../../hooks/timeSlot/useCreateTimeSlot";

type Props = {
    buttonText?: string,
}

export default function CreateTimeSlotButton(props: Props) {
    const {buttonText} = props;
    const {isAuthorized} = useContext(UserContext);
    const {openModal: openCreateModal} = useCreateTimeSlot();

    return (
        <>
            {isAuthorized(['time_slot_store']) && <Button onClick={openCreateModal}>{buttonText ?? "Create time slot"}</Button>}
        </>
    )
}