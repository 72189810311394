import {generateSortString, handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import {Customer} from "../../types";
import {saveAs} from "file-saver";

export const fetchCustomers = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/customer?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchCustomer = async (id: string) => {
    return await fetch(`${API_URL}/customer/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createCustomer(project: Customer) {
    return await fetch(`${API_URL}/customer`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(project)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateCustomer(customer: Customer) {
    return await fetch(`${API_URL}/customer/${customer.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(customer)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function deleteCustomer (ids: string[]) {
    return await fetch(`${API_URL}/customer`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
        body: JSON.stringify({
            "customers": ids
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}

export const exportCustomerData = async (sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/export/customer?${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'customers.csv'))
        .catch((error) => {
            throw error.message;
        });
};
