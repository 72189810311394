import React from "react";
import {Customer, PatchableCustomer} from "../../../../types";
import CustomerForm from "./CustomerForm";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useMutateCustomerData from "../../../hooks/customer/useMutateCustomerData";
import {createCustomer} from "../../../api/customers";

type CreateCustomerFormProps = {
    onClose: () => void;
}

function CreateCustomerForm(props: CreateCustomerFormProps) {
    const { onClose } = props;

    const onSuccess = (values: Customer) => {
        showNotification({
            title: 'Customer created',
            icon: <Check/>,
            message: `Customer '${values.name}' has been created!`,
        })

        onClose()
    }

    const onError = (error: string) => {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while creating the customer. ${error}`,
            color: 'red'
        })
    }

    const {mutate, isLoading} = useMutateCustomerData(createCustomer, onSuccess, onError);

    const onSubmit = (values: PatchableCustomer) => {
        mutate(values)
    }

    return (
        <>
            <CustomerForm
                isLoading={isLoading}
                submitAction={(values) => onSubmit(values)}
            />
        </>
    );
}

export default CreateCustomerForm;
