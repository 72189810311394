import { GasStation, Plus, Ship, UserPlus } from "tabler-icons-react";
import { Stack } from "@mantine/core";
import QuickActionCard from "../cards/QuickActionCard";
import useCreateUser from "../../hooks/user/useCreateUser";
import useCreateCustomer from "../../hooks/customer/useCreateCustomer";
import useCreateBunkerRequest from "../../hooks/bunkerRequest/useCreateBunkerRequest";
import useCreateShip from "../../hooks/ship/useCreateShip";
import { useContext } from "react";
import { UserContext } from "../../providers/user";

export default function QuickActions() {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openCreateUserModal } = useCreateUser();
  const { openModal: openCreateCustomerModal } = useCreateCustomer();
  const { openModal: openCreateBunkerRequestModal } = useCreateBunkerRequest();
  const { openModal: openCreateShipModal } = useCreateShip();

  const quickActions = [
    ...(isAuthorized("user_store")
      ? [
          {
            Icon: UserPlus,
            text: "Add user",
            ButtonIcon: Plus,
            buttonHandler: openCreateUserModal,
          },
        ]
      : []),
    ...(isAuthorized("customer_store")
      ? [
          {
            Icon: UserPlus,
            text: "Add customer",
            ButtonIcon: Plus,
            buttonHandler: openCreateCustomerModal,
          },
        ]
      : []),
    ...(isAuthorized("ship_store")
      ? [
          {
            Icon: Ship,
            text: "Add ship",
            ButtonIcon: Plus,
            buttonHandler: openCreateShipModal,
          },
        ]
      : []),
    {
      Icon: GasStation,
      text: "Add request",
      ButtonIcon: Plus,
      buttonHandler: openCreateBunkerRequestModal,
    },
  ];

  return (
    <Stack>
      {quickActions.map((quickAction) => (
        <QuickActionCard
          Icon={quickAction.Icon}
          text={quickAction.text}
          ButtonIcon={quickAction.ButtonIcon}
          buttonAction={quickAction.buttonHandler}
          key={quickAction.text}
        />
      ))}
    </Stack>
  );
}
