import { Indicator, LoadingOverlay } from "@mantine/core";
import { DatePicker, DatePickerProps } from "@mantine/dates";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TimeSlotException } from "../../../types";
import { fetchTimeSlotExceptions } from "../../api/timeSlotExceptions";

interface BunkerRequestDatePickerProps extends DatePickerProps {
  locationId: string;
  onChange: (selectedDate: Date | null) => void;
  value: any;
  isEditing?: boolean;
}

export default function BunkerRequestDatePicker(
  props: BunkerRequestDatePickerProps
) {
  const {
    locationId,
    onChange,
    value,
    isEditing = false,
    ...restProps
  } = props;
  const [selectedDate, setSelectedDate] = useState(value);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TimeSlotException[]>();

  // Excluded dates are dates that have no start_time or end_time,
  // If they have no start_time or end_time, they are disabled for the entirety of the day
  const excludedDates = data
    ?.filter((item: TimeSlotException) => !item.start_time || !item.end_time)
    ?.map((item: TimeSlotException) => new Date(item.date));

  const warningDates = data
    ?.filter((item: TimeSlotException) => item.start_time && item.end_time)
    ?.map((item: TimeSlotException) => new Date(item.date));

  const minDate = dayjs().startOf("day").toDate();

  const onSelectedDateChange = (date: Date) => {
    onChange(date);
    setSelectedDate(date);
  };

  const isDateExcluded = (date: Date) => {
    return !excludedDates
      ? false
      : !!excludedDates?.find(
          (dateException: Date) =>
            dateException.toDateString() === date.toDateString()
        );
  };

  const isDateWarning = (date: Date) => {
    return !warningDates
      ? false
      : !!warningDates?.find(
          (dateException: Date) =>
            dateException.toDateString() === date.toDateString()
        );
  };

  const getTimeSlotExceptions = async () => {
    setLoading(true);

    const data = await fetchTimeSlotExceptions(locationId);

    if (!data?.data) {
      return;
    }

    setData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    if (!locationId) {
      setSelectedDate(null);
      return;
    }

    getTimeSlotExceptions();
  }, [locationId]);

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay loaderProps={{ size: "sm", mt: 15 }} visible={loading} />

      <DatePicker
        allowFreeInput
        disabled={!locationId}
        label="Date"
        placeholder={"Select a date"}
        onChange={onSelectedDateChange}
        value={selectedDate}
        {...(!isEditing && { minDate })}
        excludeDate={(date) => isDateExcluded(date)}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
        renderDay={(date) => (
          <Indicator
            offset={8}
            title={
              isDateExcluded(date)
                ? "Time slot exception active"
                : "Limited time slot exception active"
            }
            color={isDateExcluded(date) ? "custom-red" : "orange"}
            disabled={!isDateExcluded(date) && !isDateWarning(date)}
            zIndex={0}
          >
            {date.getDate()}
          </Indicator>
        )}
        {...restProps}
      />
    </div>
  );
}
