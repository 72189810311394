import {Button, Group} from "@mantine/core";
import {ChevronDown} from "tabler-icons-react";
import React from "react";
import useDeleteShip from "../../hooks/ship/useDeleteShip";
import useEditShip from "../../hooks/ship/useEditShip";
import ShipOptionsMenu from "../menus/ShipOptionsMenu";

type Props = {
    id: string;
}

export default function ShipOptionsMenuButton(props: Props) {
    const {id} = props;
    const {openModal: openDeleteModal} = useDeleteShip(true);
    const {openModal: openEditModal} = useEditShip();

    return (
        <ShipOptionsMenu
            onEdit={() => {
                openEditModal(id)
            }}
            onDelete={() => {
                openDeleteModal([id])
            }}
            controlButton={
                <Button>
                    <Group spacing={"sm"}>
                        Options
                        <ChevronDown size={16}/>
                    </Group>
                </Button>
            }
        />
    );
}
