import React from "react";
import { useForm } from "@mantine/form";
import {
  AutocompleteItem,
  Button,
  createStyles,
  Group,
  TextInput,
} from "@mantine/core";
import { Location, PatchableLocation } from "../../../../types";
import {
  validateEmail,
  validateNumber,
  validateString,
} from "../../../validator";
import { MarkerDragEvent } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { LocationSelectorMap } from "../../LocationSelectorMap";
import { GeocodingSelect } from "../../selects/GeocodingSelect";
import { Mail, Phone } from "tabler-icons-react";

type LocationFormProps = {
  location?: Location;
  submitText?: string;
  submitAction: (values: PatchableLocation) => void;
  isLoading?: boolean;
};

const useStyles = createStyles((theme, _params, getRef) => ({
  group: {
    alignItems: "flex-start",
  },
}));

function LocationForm(props: LocationFormProps) {
  const { location, submitText, submitAction, isLoading = false } = props;
  const { classes } = useStyles();

  const form = useForm<PatchableLocation>({
    initialValues: {
      id: location?.id ?? "",
      name: location?.name ?? "",
      phone: location?.phone ?? "",
      phone2: location?.phone2 ?? "",
      email: location?.email ?? "",
      location: location?.location ?? "",
      lat: location?.lat ?? 0,
      lon: location?.lon ?? 0,
      threshold: location?.threshold ?? 24,
    },

    validate: {
      name: (value) => validateString(value),
      phone: (value) => validateString(value),
      email: (value) => validateEmail(value),
      threshold: (value) => validateNumber(value, "Threshold"),
      location: (value) => validateString(value),
    },
  });

  const initialLocation =
    form.values.lat && form.values.lon
      ? { latitude: form.values.lat, longitude: form.values.lon }
      : undefined;

  const onGeocodingSelect = (item: AutocompleteItem) => {
    form.setFieldValue("location", item.value);

    if (item.location) {
      form.setFieldValue("lat", item.location?.center[1]);
      form.setFieldValue("lon", item.location?.center[0]);
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput
        pb={"sm"}
        required
        label="Name"
        {...form.getInputProps("name")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          label="Phone number"
          placeholder={"+31 528 123456"}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            icon: {
              color: "#d5d9de",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
          icon={<Phone size={16} />}
        />

        <TextInput
          label="Alternative phone number"
          placeholder={"+31 528 123456"}
          {...form.getInputProps("phone2")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            icon: {
              color: "#d5d9de",
            },
          })}
          icon={<Phone size={16} />}
        />
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          type={"email"}
          label="E-mail address"
          {...form.getInputProps("email")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            icon: {
              color: "#d5d9de",
            },
            required: {
              color: "#004F65",
            },
          })}
          icon={<Mail size={16} />}
        />

        <></>
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          min={1}
          type={"number"}
          label="Threshold"
          description={"Threshold in hours"}
          {...form.getInputProps("threshold")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />

        <></>
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <GeocodingSelect
          required
          label="Location"
          defaultValue={form.values.location}
          onItemSubmit={onGeocodingSelect}
        />

        <LocationSelectorMap
          initialLocation={initialLocation}
          onMarkerDragEnd={(e: MarkerDragEvent) => {
            form.setFieldValue("lon", e.lngLat.lng);
            form.setFieldValue("lat", e.lngLat.lat);
          }}
        />
      </Group>

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Save"}
        </Button>
      </Group>
    </form>
  );
}

export default LocationForm;
