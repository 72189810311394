import {useModals} from "@mantine/modals";
import React from "react";
import CreateTimeSlotForm from "../../components/forms/timeSlot/CreateTimeSlotForm";
import {useParams} from "react-router-dom";

export default function useCreateTimeSlot() {
    const modals = useModals();
    const {id} = useParams();

    const openModal = () => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Create time slot',
            closeOnClickOutside: false,
            children: (
                <>
                    <CreateTimeSlotForm locationId={id} onClose={() => closeModal(modalId)} />
                </>
            ),
        });
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
    }

    return {
        openModal
    }
}
