import React, { useState } from "react";
import { useForm } from "@mantine/form";
import { Button, createStyles, Group, TextInput } from "@mantine/core";
import { BunkerRequest, PatchableBunkerRequest } from "../../../../types";
import { ShipSelect } from "../../selects/ShipSelect";
import { TimeSlotSelect } from "../../selects/TimeSlotSelect";
import { LocationSelect } from "../../selects/LocationSelect";
import { convertToDate } from "../../../../tools";
import {
  validateDate,
  validateNumber,
  validateString,
} from "../../../validator";
import BunkerRequestDatePicker from "../../datepickers/BunkerRequestDatePicker";

type BunkerRequestFormProps = {
  bunkerRequest?: BunkerRequest;
  submitText?: string;
  submitAction: (values: PatchableBunkerRequest) => void;
  isLoading?: boolean;
  isEditing?: boolean;
};

const useStyles = createStyles((theme, _params, getRef) => ({
  group: {
    alignItems: "flex-start",
  },
}));

function BunkerRequestForm(props: BunkerRequestFormProps) {
  const {
    bunkerRequest,
    submitText,
    submitAction,
    isLoading = false,
    isEditing = false,
  } = props;
  const [locationId, setLocationId] = useState<string | undefined>(
    !bunkerRequest?.time_slot?.location?.deleted_at
      ? bunkerRequest?.time_slot?.location?.id
      : undefined
  );
  const { classes } = useStyles();

  const form = useForm<PatchableBunkerRequest>({
    initialValues: {
      id: bunkerRequest?.id ?? "",
      date: convertToDate(bunkerRequest?.date),
      expected_amount: bunkerRequest?.expected_amount?.toString() ?? "",
      bunkered_amount: bunkerRequest?.bunkered_amount?.toString() ?? "",
      time_slot_id: bunkerRequest?.time_slot?.id ?? "",
      ship_id:
        bunkerRequest?.ship && !bunkerRequest?.ship.deleted_at
          ? bunkerRequest?.ship?.id
          : "",
    },

    validate: {
      date: (value) => validateDate(value),
      expected_amount: (value) => validateNumber(value),
      bunkered_amount: (value) =>
        validateNumber(value, "Bunkered amount", false),
      ship_id: (value) => validateString(value, "A valid ship"),
      time_slot_id: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          type={"number"}
          required
          label="Expected amount"
          description={"In kilograms"}
          {...form.getInputProps("expected_amount")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />

        <TextInput
          type={"number"}
          step={0.01}
          label="Bunkered amount"
          description={"In kilograms"}
          {...form.getInputProps("bunkered_amount")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      </Group>

      <ShipSelect
        error={form.errors.ship_id ?? null}
        required
        pb={"sm"}
        defaultValue={
          bunkerRequest?.ship && !bunkerRequest?.ship.deleted_at
            ? `${bunkerRequest.ship.name} (${bunkerRequest.ship.imo_number})`
            : ""
        }
        onClear={() => {
          form.setFieldValue("ship_id", "");
        }}
        onItemSubmit={(value) => {
          form.setFieldValue("ship_id", value.ship.id);
        }}
      />

      <LocationSelect
        required
        pb={"sm"}
        defaultValue={locationId}
        onClear={() => setLocationId(undefined)}
        onItemSubmit={(value) => setLocationId(value.location?.id)}
      />

      <BunkerRequestDatePicker
        pb={"sm"}
        required
        locationId={locationId ?? ""}
        onChange={(date) => form.setFieldValue("date", date)}
        value={form.values.date}
        isEditing={isEditing}
      />

      <TimeSlotSelect
        bunkerRequestId={bunkerRequest?.id}
        required={true}
        disabled={!form.values.date || !locationId}
        location={locationId ?? ""}
        date={form.values.date ?? new Date()}
        pb={"sm"}
        error={form.errors.time_slot_id ?? null}
        defaultValue={bunkerRequest ? bunkerRequest?.time_slot?.id : ""}
        onChange={(value) => {
          form.setFieldValue("time_slot_id", value ?? "");
        }}
      />

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Save"}
        </Button>
      </Group>
    </form>
  );
}

export default BunkerRequestForm;
