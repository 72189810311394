import {useModals} from "@mantine/modals";
import {useNavigate} from "react-router-dom";
import React from "react";
import EditCustomerForm from "../../components/forms/customer/EditCustomerForm";

export default function useEditCustomer(navigateAway: boolean = false) {
    const modals = useModals();
    const navigate = useNavigate();

    const openModal = (id: string) => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Edit customer',
            closeOnClickOutside: false,
            children: (
                <>
                    <EditCustomerForm customerId={id} onClose={() => closeModal(modalId)}/>
                </>
            ),
        });
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
        navigateAway && navigate("/customers")
    }

    return {
        openModal
    }
}
