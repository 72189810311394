import {API_URL, FETCH_HEADERS} from "../constants";
import {ContactProperties} from "../../types";
import {handleRequestErrors} from "../../tools";

export const fetchContactProperties = async () => {
    return await fetch(`${API_URL}/contact-property`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function updateContactProperty(contactProperties: ContactProperties) {
    return await fetch(`${API_URL}/contact-property`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify({
            contact_properties: contactProperties,
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}