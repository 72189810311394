import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import React from "react";
import CreateBunkerRequestForm from "../../components/forms/bunkerRequest/CreateBunkerRequestForm";

export default function useCreateBunkerRequest(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = () => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Create bunker request",
      closeOnClickOutside: false,
      children: <CreateBunkerRequestForm onClose={() => closeModal(modalId)} />,
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/requests`);
  };

  return {
    openModal,
  };
}
