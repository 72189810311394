import { useNavigate, useParams } from "react-router-dom";
import useShipData from "../../hooks/ship/useShipData";
import React, { useContext, useEffect } from "react";
import { FullPageSpinner } from "../../components/FullPageSpinner";
import { Anchor, Button, Group, Title, useMantineTheme } from "@mantine/core";
import ShipDetailTable from "../../components/tables/ShipDetailTable";
import ShipOptionsMenuButton from "../../components/buttons/ShipOptionsMenuButton";
import ShipBunkerRequestTable from "../../components/tables/ShipBunkerRequestTable";
import useCreateBunkerRequest from "../../hooks/bunkerRequest/useCreateBunkerRequest";
import { UserContext } from "../../providers/user";

export default function ShipDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useMantineTheme();
  const { data, isFetching, isRefetching } = useShipData(id);
  const { openModal: openCreateBunkerRequestModal } = useCreateBunkerRequest();
  const { isAuthorized } = useContext(UserContext);

  useEffect(() => {
    if (!isFetching && data === undefined) {
      navigate("/ships");
    }
  }, [isFetching]);

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      {!data || isRefetching ? (
        <FullPageSpinner
          overlayColor={
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0]
          }
        />
      ) : (
        <>
          <Anchor onClick={() => navigate("/ships")}>
            Return to the overview
          </Anchor>

          <Group position="apart">
            <Title>Ship {data.data.name}</Title>

            {isAuthorized(["ship_update", "ship_destroy"]) && (
              <Group>
                <ShipOptionsMenuButton id={data.data.id} />
              </Group>
            )}
          </Group>

          <Group>
            <ShipDetailTable ship={data.data} />
          </Group>

          <Group position="apart" my={"sm"}>
            <Title order={2}>Bunker requests</Title>
            <Button onClick={() => openCreateBunkerRequestModal()}>
              Create bunker request
            </Button>
          </Group>

          <ShipBunkerRequestTable shipId={id} />
        </>
      )}
    </div>
  );
}
