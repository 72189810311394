import {useModals} from "@mantine/modals";
import BunkerRequestAttachmentOverview from "../../components/attachments/BunkerRequestAttachmentOverview";

export default function useViewBunkerRequestAttachments() {
    const modals = useModals();

    const openModal = (id: string) => {
        modals.openModal({
            centered: true,
            title: 'Attachments',
            closeOnClickOutside: false,
            size: "xl",
            children: (
                <div style={{position: "relative"}}>
                    <BunkerRequestAttachmentOverview id={id} />
                </div>
            ),
        })
    };

    return {
        openModal
    }
}
