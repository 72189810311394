import { Menu, MenuLabel } from "@mantine/core";
import { Logout, Settings } from "tabler-icons-react";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";

export default function UserMenu(props: any) {
  const { logout } = useLogout();
  const navigate = useNavigate();

  return (
    <Menu
      styles={(theme) => ({
        item: {
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
      })}
      {...props}
    >
      <MenuLabel>Account</MenuLabel>
      <Menu.Item
        icon={<Settings size={16} />}
        onClick={() => navigate("/settings")}
      >
        Settings
      </Menu.Item>
      <Menu.Item icon={<Logout size={16} />} onClick={() => logout()}>
        Logout
      </Menu.Item>
    </Menu>
  );
}
