import {useMutation, useQueryClient} from 'react-query'
import {Ship} from "../../../types";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

const onMutateSuccess = (values: Ship) => {
    showNotification({
        title: 'Ship updated',
        icon: <Check/>,
        message: `Ship '${values.name}' has been updated!`,
    })
}

const onMutateError = (error: string) => {
    showNotification({
        title: `Something went wrong`,
        message: `An error occurred while updating this ship. ${error}`,
        color: 'red'
    })
}

export default function useMutateShipData(mutationFn: (args: any) => Promise<unknown>, onSuccess: any = onMutateSuccess, onError: any = onMutateError) {
    const queryClient = useQueryClient();
    return useMutation(mutationFn, {
        onSuccess: (data) => {
            // Reload user data
            queryClient.invalidateQueries(['ships']);

            onSuccess?.(data)
        },
        onError: (data) => onError?.(data)
    });
}
