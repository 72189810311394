import { Loader, TransferList, TransferListData } from "@mantine/core";
import { useEffect, useState } from "react";
import { fetchPermissions } from "../../api/permissions";
import { Maybe, Permission } from "../../../types";

type PermissionTransferListProps = {
  onSelectedPermissionsChange: (selectedPermissionIds: Permission[]) => void;
  initialSelectedValues?: Maybe<Permission[]>;
};

export default function PermissionTransferList(
  props: PermissionTransferListProps
) {
  const { onSelectedPermissionsChange, initialSelectedValues = [] } = props;
  const [selectedPermissions, setSelectedPermissions] =
    useState<TransferListData>([[], []]);
  const [loading, setLoading] = useState(true);

  /**
   * Event handler that is called when a change occurs on the TransferList.
   * @param value
   */
  const onChange = function (value: TransferListData) {
    // Pass an array with the ids of the selected items.
    onSelectedPermissionsChange(
      value[1].map((item) => {
        return {
          id: parseInt(item.value),
        };
      })
    );

    setSelectedPermissions(value);
  };

  /**
   * Format the given array in the TransferList format.
   * @param values
   */
  const formatList = function (values: Permission[]) {
    return values.map((permission: Permission) => {
      return {
        value: `${permission.id}`,
        label: permission?.description,
        group: permission?.group,
      };
    });
  };

  /**
   * Get the difference from the initialSelectedValues array.
   * Returns a new array where items that are present in the initialSelectedValues array are excluded.
   * @param values
   */
  const filterData = function (values: Permission[]) {
    // Return the given values if no initial values are selected.
    if (!initialSelectedValues) {
      return values;
    }

    return values.filter((item: Permission) => {
      // Only return true if the current item does not exist in the initialSelectedValues array.
      return !initialSelectedValues.find(
        (val: Permission) => val.id === item.id
      );
    });
  };

  /**
   * Gets the permission data and sets it formatted in the TransferList.
   */
  const onLoad = async function () {
    const response = await fetchPermissions();

    if (!response?.data) {
      return;
    }

    // @ts-ignore
    setSelectedPermissions([
      // @ts-ignore
      formatList(filterData(response.data)), // @ts-ignore
      formatList(initialSelectedValues),
    ]);
    setLoading(false);
  };

  /**
   * Call onLoad on mount.
   */
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <TransferList
      value={selectedPermissions}
      onChange={onChange}
      titles={["Permissions", "Selected permission"]}
      searchPlaceholder="Search permissions..."
      nothingFound={loading ? <Loader /> : "No permissions found."}
      breakpoint="xs"
      listHeight={350}
      styles={(theme) => ({
        transferListTitle: {
          fontSize: 14,
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
        transferListItem: {
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        },
        label: {
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
        required: {
          color: "#004F65",
        },
        input: {
          borderColor: theme.colors["custom-gray"][7],
          "&:focus": {
            borderColor: theme.colors["bright-green"][0],
          },
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
      })}
    />
  );
}
