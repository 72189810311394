import React from "react";
import { useForm } from "@mantine/form";
import { Button, Text, TextInput } from "@mantine/core";
import { validateString } from "../../../validator";
import { useModals } from "@mantine/modals";
import { Settings } from "../../../../types";
import { NOTIFICATION_SETTINGS } from "../../../constants";

type SettingsFormProps = {
  defaultValues: Settings;
  onSubmit: (values: any) => void;
  isLoading?: boolean;
};

function SettingsForm(props: SettingsFormProps) {
  const { defaultValues, onSubmit: onSubmitProps, isLoading = false } = props;
  const modals = useModals();

  const onSubmit = (values: any) => {
    modals.openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text
          size="sm"
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          })}
        >
          Saving system settings is dangerous. Therefore you are required to
          confirm your action. Please click one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "custom-red" },
      centered: true,
      onConfirm: () => onSubmitProps(values),
    });
  };

  const form = useForm<Settings>({
    initialValues: {
      mail_host: defaultValues.mail_host ?? "",
      mail_port: defaultValues.mail_port ?? "",
      mail_username: defaultValues.mail_username ?? "",
      mail_password: defaultValues.mail_password ?? "",
      mail_encryption: defaultValues.mail_encryption ?? "",
      mail_from_address: defaultValues.mail_from_address ?? "",
      mail_from_name: defaultValues.mail_from_name ?? "",
    },

    validate: {
      mail_host: (value) => validateString(value),
      mail_port: (value) => validateString(value),
      mail_username: (value) => validateString(value),
      mail_password: (value) => validateString(value),
      mail_encryption: (value) => validateString(value),
      mail_from_address: (value) => validateString(value),
      mail_from_name: (value) => validateString(value),
    },
  });

  return (
    <>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        {NOTIFICATION_SETTINGS.map((i: string, key) => (
          <TextInput
            key={key}
            pb={"sm"}
            required
            label={i}
            {...form.getInputProps(i as keyof Settings)}
            styles={(theme) => ({
              input: {
                "&::placeholder": {
                  color: theme.colors["custom-gray"][8],
                },
                borderColor: theme.colors["custom-gray"][7],
                "&:focus": {
                  borderColor: theme.colors["bright-green"][0],
                },
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              },
              label: {
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              },
              icon: {
                color: "#d5d9de",
              },
              required: {
                color: "#004F65",
              },
            })}
          />
        ))}

        <Button type={"submit"} loading={isLoading}>
          Save
        </Button>
      </form>
    </>
  );
}

export default SettingsForm;
