import { Anchor, Text } from "@mantine/core";
import React, { useContext } from "react";
import { fetchCustomers } from "../../api/customers";
import useDeleteCustomer from "../../hooks/customer/useDeleteCustomer";
import useEditCustomer from "../../hooks/customer/useEditCustomer";
import { UserContext } from "../../providers/user";
import FancyTable from "./FancyTable";

function CustomerTable() {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openEditModal } = useEditCustomer();
  const { openModal: openDeleteModal } = useDeleteCustomer();

  const columns = React.useMemo(
    () => [
      {
        id: "customer-table",
        columns: [
          {
            id: "name",
            Header: "Name",
            accessor: (d: any) => <Text size={"sm"}>{d.name}</Text>,
          },
          {
            id: "phone",
            Header: "Phone number",
            accessor: (d: any) => {
              return (
                <Anchor size={"sm"} href={`tel:${d.phone}`}>
                  {d.phone}
                </Anchor>
              );
            },
          },
          {
            id: "email_finance",
            Header: "E-mail address finance",
            accessor: (d: any) => {
              return (
                <Anchor size={"sm"} href={`mailto:${d.email_finance}`}>
                  {d.email_finance}
                </Anchor>
              );
            },
          },
          {
            id: "email_operations",
            Header: "E-mail address operations",
            accessor: (d: any) => {
              return (
                <Anchor size={"sm"} href={`mailto:${d.email_operations}`}>
                  {d.email_operations}
                </Anchor>
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <FancyTable
        queryKey={"customers"}
        queryFn={fetchCustomers}
        columns={columns}
        onDelete={isAuthorized("customer_destroy") ? openDeleteModal : undefined}
        onEdit={isAuthorized("customer_update") ? openEditModal : undefined}
      />
    </>
  );
}

export default CustomerTable;
