import {useQuery} from 'react-query'
import {fetchContactProperties} from "../../api/contactProperties";

export default function useContactPropertyData(onSuccess?: any, onError?: any) {
    return useQuery(['contactProperties'], () => fetchContactProperties(),
        {
            onSuccess,
            onError,
            keepPreviousData: false,
        }
    );
}
