import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";
import { completeBunkerRequest } from "../../api/bunkerRequests";
import CompleteBunkerRequestForm from "../../components/forms/bunkerRequest/CompleteBunkerRequestForm";
import useMutateBunkerRequestData from "./useMutateBunkerRequestData";

export default function useCompleteBunkerRequest() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Bunker request completed",
      icon: <Check />,
      message: `The bunker request has been completed!`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Something went wrong`,
      message: `An error occurred while completing the bunker request. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateBunkerRequestData(completeBunkerRequest, onSuccess, onError);

  const onSubmit = async (modalId: string, id: number | string, extraTime: number) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Updating bunker request",
      message: "Completing the bunker request...",
    });

    await mutate({ id: id, extraTime });
  };

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Are you sure?",
      children: (
        <CompleteBunkerRequestForm
          onClose={() => modals.closeModal(modalId)}
          onSubmit={(extraTime: number) => onSubmit(modalId, id, extraTime)}
        />
      ),
    });
  };

  return {
    openModal,
  };
}
