import { Card, Group, Progress, Text, createStyles } from "@mantine/core";

type Props = {
  dateText: string;
  reservedTimeSlots: number;
  totalTimeSlots: number;
  progressColor?: string;
};

const useStyles = createStyles((theme) => ({
  paper: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

export default function BunkerRequestOverviewCard(props: Props) {
  const {
    dateText,
    reservedTimeSlots,
    totalTimeSlots,
    progressColor = "#289323",
  } = props;
  const { classes } = useStyles();

  return (
    <Card className={classes.paper}>
      <Group position="apart">
        <Text>{dateText}</Text>
        <Text>
          <span style={{ fontWeight: "bold" }}>{reservedTimeSlots}</span> /{" "}
          <span style={{ fontWeight: "bold" }}>{totalTimeSlots}</span> bunker
          requests
        </Text>
      </Group>

      <Progress
        value={(reservedTimeSlots / totalTimeSlots) * 100}
        color={progressColor}
      />
    </Card>
  );
}
