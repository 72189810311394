import {generateSortString, handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import {Maybe, PatchableTimeSlot} from "../../types";
import dayjs from "dayjs";

export const fetchTimeSlots = async (page: number, pageSize: number, date: (Date | null) = null, locationId: string, bunkerRequestId: string = "", sortByData: SortingRule<object>[] = [], query: string = "") => {
    let formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
    let sortByString = generateSortString(sortByData);

    return await fetch(`${API_URL}/time-slot?page=${page + 1}&limit=${pageSize}${sortByString}&query=${query}&location_id=${locationId}&bunker_request_id=${bunkerRequestId}&date=${formattedDate}`, {
        ...FETCH_HEADERS(),
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchTimeSlot = async (id: string) => {
    return await fetch(`${API_URL}/time-slot/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createTimeSlot(timeSlot: PatchableTimeSlot) {
    return await fetch(`${API_URL}/time-slot`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify({
            ...timeSlot,
            start_time: dayjs(timeSlot.start_time).format("HH:mm"),
            end_time: dayjs(timeSlot.end_time).format("HH:mm"),
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateTimeSlot(timeSlot: PatchableTimeSlot) {
    return await fetch(`${API_URL}/time-slot/${timeSlot.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify({
            ...timeSlot,
            start_time: dayjs(timeSlot.start_time).format("HH:mm"),
            end_time: dayjs(timeSlot.end_time).format("HH:mm"),
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function deleteTimeSlot (ids: string[]) {
    return await fetch(`${API_URL}/time-slot`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
        body: JSON.stringify({
            "timeslots": ids
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}
