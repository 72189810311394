import {generateFilterString, generateSortString, handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import {TableDataFilter} from "../../types";

export const fetchNotificationSettings = async (page: number, pageSize: number, sortByData: SortingRule<object>[] = [], query: string = "", filters: TableDataFilter[] = []) => {
    let sortByString = generateSortString(sortByData);
    let locationId = filters.find(i => i.column === "locationId")?.value ?? "";
    let filterString = generateFilterString(filters.filter(i => i.column !== "locationId"))

    return await fetch(`${API_URL}/location/${locationId}/notification-setting?page=${page + 1}&limit=${pageSize}${sortByString}${filterString}&query=${query}`, {
        ...FETCH_HEADERS(),
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchNotificationSetting = async (id: string, locationId: string) => {
    return await fetch(`${API_URL}/location/${locationId}/notification-setting/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createNotificationSetting(data: any) {
    return await fetch(`${API_URL}/location/${data.locationId}/notification-setting`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(data.notificationSetting)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateNotificationSetting(data: any) {
    return await fetch(`${API_URL}/location/${data.locationId}/notification-setting/${data.notificationSetting.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(data.notificationSetting)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function deleteNotificationSetting(data: any) {
    return await fetch(`${API_URL}/location/${data.locationId}/notification-setting/${data.id}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}
