import { Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";
import useMutateBunkerRequestData from "./useMutateBunkerRequestData";
import { processBunkerRequest } from "../../api/bunkerRequests";

export default function useApproveBunkerRequest() {
  const modals = useModals();

  const onSuccess = () => {
    showNotification({
      title: "Bunker request approved",
      icon: <Check />,
      message: `The bunker request has been approved!`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Something went wrong`,
      message: `An error occurred while approving the bunker request. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateBunkerRequestData(
    processBunkerRequest,
    onSuccess,
    onError
  );

  const openModal = (id: string) =>
    modals.openConfirmModal({
      centered: true,
      title: "Are you sure?",
      children: (
        <Text
          size="sm"
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          })}
        >
          You are about to approve a bunker request. The customer will be
          informed of this. Are you sure?
        </Text>
      ),
      labels: { confirm: "Approve", cancel: "Cancel" },
      onCancel: () => {},
      onConfirm: async () => {
        await mutate({
          id: id,
          is_approved: true,
        });
      },
    });

  return {
    openModal,
  };
}
