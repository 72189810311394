import { useForm } from "@mantine/form";
import {
  Button,
  Code,
  Group,
  Stack,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { Role } from "../../../../types";
import PermissionTransferList from "../../transferLists/PermissionTransferList";
import { validateString } from "../../../validator";

type RoleFormProps = {
  onSubmit: (values: any) => void;
  role?: Role;
  loading: boolean;
  submitText?: string;
};

const useStyles = createStyles((theme) => ({
  paper: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

export default function RoleForm(props: RoleFormProps) {
  const { onSubmit, role, loading, submitText } = props;
  const { classes } = useStyles();

  const form = useForm<Role>({
    initialValues: {
      id: role?.id ?? 0,
      name: role?.name ?? "",
      permissions: role?.permissions,
    },
    validate: {
      name: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack className={classes.paper}>
        <TextInput
          label="Name"
          placeholder="The name of the role"
          required
          {...form.getInputProps("name")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
          })}
        />

        <PermissionTransferList
          onSelectedPermissionsChange={(values) =>
            form.setFieldValue("permissions", values)
          }
          initialSelectedValues={role?.permissions}
        />

        <Text size="sm">
          Some permissions give access to other permissions in order for the
          application to function properly. For instance, the permission{" "}
          <Code>Update roles</Code> gives access to the permission{" "}
          <Code>View roles</Code> even if <Code>View roles</Code> is not
          selected.
        </Text>

        <Group position="right">
          <Button type="submit" loading={loading}>
            {submitText ?? "Save"}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
