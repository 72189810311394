import AccountForm from "../forms/settings/AccountForm";
import {useContext} from "react";
import {UserContext} from "../../providers/user";
import {Container, LoadingOverlay, Title} from "@mantine/core";
import useMutateSelfData from "../../hooks/user/useMutateSelfData";
import {updateSelf} from "../../api/users";
import {PatchableUser} from "../../../types";

export default function AccountSettingsTab() {
    const {data} = useContext(UserContext);
    const {mutate, isLoading} = useMutateSelfData(updateSelf);

    const onSubmit = function(data: PatchableUser) {
        mutate(data);
    }

    return !data ? (
        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={true} />
        </div>
    ) : (
        <>
            <Title order={3}>General</Title>

            <AccountForm
                user={data}
                onSubmit={onSubmit}
                loading={isLoading}
            />
        </>
    );
}