import {handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {Settings} from "../../types";

export const fetchSettings = async () => {
    return await fetch(`${API_URL}/settings`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function updateSettings(settings: Settings) {
    return await fetch(`${API_URL}/settings`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify({
            settings: settings
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}
