import { Stack } from "@mantine/core";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BunkerRequest, Customer, Location, Ship, User } from "../../../types";
import { fetchBunkerRequests } from "../../api/bunkerRequests";
import { fetchCustomers } from "../../api/customers";
import { fetchLocations } from "../../api/locations";
import { fetchShips } from "../../api/ships";
import { fetchUsers } from "../../api/users";
import { SearchContext } from "../../providers/search";
import { UserContext } from "../../providers/user";
import { SearchSelect } from "../selects/SearchSelect";

export default function QuickSearches() {
  const navigate = useNavigate();
  const { setQuery, setEmptyOnNavigate } = useContext(SearchContext);
  const { isAuthorized } = useContext(UserContext);

  const quickSearches = [
    {
      placeholder: "Search bunker requests...",
      onItemSubmit: (value: BunkerRequest) => {
        setEmptyOnNavigate(false);
        setQuery(value?.ship?.name ?? "");
        navigate(`/requests`);
      },
      fetch: fetchBunkerRequests,
      valueFormatter: (value: BunkerRequest) =>
        `Date: ${value.date}
                Ship: ${value.ship?.name ?? "N/A"}
                Location: ${value.time_slot?.location?.name ?? "N/A"}`,
    },

    {
      placeholder: "Search locations...",
      onItemSubmit: (value: Location) => navigate(`/locations/${value.id}`),
      fetch: fetchLocations,
      valueFormatter: (value: Location) => value.name,
    },

    ...(isAuthorized([
      "customer_index",
      "customer_show",
      "customer_store",
      "customer_update",
      "customer_destroy",
    ])
      ? [
          {
            placeholder: "Search customers...",
            onItemSubmit: (value: Customer) => {
              setEmptyOnNavigate(false);
              setQuery(value.name);
              navigate("/customers");
            },
            fetch: fetchCustomers,
            valueFormatter: (value: Customer) => value.name,
          },
        ]
      : []),

    ...(isAuthorized(["user_index", "user_show", "user_store", "user_update", "user_destroy"])
      ? [
          {
            placeholder: "Search users...",
            onItemSubmit: (value: User) => {
              setEmptyOnNavigate(false);
              setQuery(value.name);
              navigate(`/users`);
            },
            fetch: fetchUsers,
            valueFormatter: (value: User) => value.name,
          },
        ]
      : []),

    {
      placeholder: "Search ships...",
      onItemSubmit: (value: Ship) => navigate(`/ships/${value.id}`),
      fetch: fetchShips,
      valueFormatter: (value: Ship) => `Name: ${value.name} (${value.imo_number})`,
    },
  ];

  return (
    <Stack>
      {quickSearches.map((value) => (
        <SearchSelect
          placeholder={value.placeholder}
          onItemSubmit={value.onItemSubmit}
          fetch={value.fetch}
          valueFormatter={value.valueFormatter}
          key={value.placeholder}
        />
      ))}
    </Stack>
  );
}
