import {useModals} from "@mantine/modals";
import {useNavigate} from "react-router-dom";
import React from "react";
import EditUserForm from "../../components/forms/user/EditUserForm";

export default function useEditUser(navigateAway: boolean = false) {
    const modals = useModals();
    const navigate = useNavigate();

    const openModal = (id: string) => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Edit user',
            closeOnClickOutside: false,
            children: (
                <>
                    <EditUserForm userId={id} onClose={() => closeModal(modalId)}/>
                </>
            ),
        });
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
        navigateAway && navigate("/users")
    }

    return {
        openModal
    }
}
