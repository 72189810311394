import React from "react";
import { BunkerRequest, PatchableBunkerRequest } from "../../../../types";
import BunkerRequestForm from "./BunkerRequestForm";
import { showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";
import useMutateBunkerRequestData from "../../../hooks/bunkerRequest/useMutateBunkerRequestData";
import { createBunkerRequest } from "../../../api/bunkerRequests";

type CreateBunkerRequestFormProps = {
  onClose: () => void;
};

function CreateBunkerRequestForm(props: CreateBunkerRequestFormProps) {
  const { onClose } = props;

  const onSuccess = (values: BunkerRequest) => {
    showNotification({
      title: "Bunker request created",
      icon: <Check />,
      message: `Bunker request has been created!`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Something went wrong`,
      message: `An error occurred while creating the bunker request. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateBunkerRequestData(createBunkerRequest, onSuccess, onError);

  const onSubmit = (values: PatchableBunkerRequest) => {
    mutate(values);
  };

  return <BunkerRequestForm isLoading={isLoading} submitAction={(values) => onSubmit(values)} />;
}

export default CreateBunkerRequestForm;
