import {Button} from "@mantine/core";
import useCreateShip from "../../hooks/ship/useCreateShip";
import {useContext} from "react";
import {UserContext} from "../../providers/user";

type Props = {
    buttonText?: string,
}

export default function CreateShipButton(props: Props) {
    const {buttonText} = props;
    const {isAuthorized} = useContext(UserContext);
    const {openModal} = useCreateShip();

    return (
        <>
            {isAuthorized("ship_store") && <Button onClick={() => openModal()}>{buttonText ?? "Create ship"}</Button>}
        </>
    )
}