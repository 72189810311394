import {generateSortString, handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import {Password, PatchableUser} from "../../types";

export const fetchUsers = async (page: number, pageSize: number, sortByData: SortingRule<object>[], query: string = "") => {
    return await fetch(`${API_URL}/user?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchUser = async (id: string) => {
    return await fetch(`${API_URL}/user/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createUser(user: PatchableUser) {
    return await fetch(`${API_URL}/user`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(user)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateUser(user: PatchableUser) {
    return await fetch(`${API_URL}/user/${user.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(user)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateSelf(user: PatchableUser) {
    return await fetch(`${API_URL}/auth/profile`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(user)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updatePassword(values: Password) {
    return await fetch(`${API_URL}/auth/change-password`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(values)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function deleteUser(ids: string[]) {
    return await fetch(`${API_URL}/user`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
        body: JSON.stringify({
            "users": ids
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}
