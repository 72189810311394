import ChangePasswordForm from "../forms/settings/ChangePasswordForm";
import {Container, Title} from "@mantine/core";
import {updatePassword} from "../../api/users";
import {Password} from "../../../types";
import useMutateSelfData from "../../hooks/user/useMutateSelfData";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

export default function ChangePasswordTab() {
    const onSuccess = function() {
        showNotification({
            title: 'Password changed',
            icon: <Check/>,
            message: `Your password has been changed.`,
        })
    }

    const onError = function(error: string) {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while changing your password. ${error}`,
            color: 'red'
        })
    }

    const {mutate, isLoading} = useMutateSelfData(updatePassword, onSuccess, onError);

    const onSubmit = function(data: Password) {
        mutate(data);
    }

    return (
        <>
            <Title order={3}>Change password</Title>

            <ChangePasswordForm
                onSubmit={onSubmit}
                loading={isLoading}
            />
        </>
    );
}