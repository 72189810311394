import { LoadingOverlay, useMantineTheme } from "@mantine/core";
import Calendar from "rc-year-calendar";
import { useContext } from "react";
import { TimeSlotException } from "../../../types";
import useCreateTimeSlotException from "../../hooks/timeSlotsExceptions/useCreateTimeSlotException";
import useDeleteTimeSlotException from "../../hooks/timeSlotsExceptions/useDeleteTimeSlotException";
import useEditTimeSlotException from "../../hooks/timeSlotsExceptions/useEditTimeSlotException";
import useTimeSlotExceptionsData from "../../hooks/timeSlotsExceptions/useTimeSlotExceptionsData";
import ErrorView from "../../pages/ErrorView";
import { UserContext } from "../../providers/user";

type TimeSlotExceptionsCalendarProps = {
  locationId: string;
};

export default function TimeSlotExceptionsCalendar(props: TimeSlotExceptionsCalendarProps) {
  const { locationId } = props;
  const { status, isFetching, isError, data } = useTimeSlotExceptionsData(locationId);
  const theme = useMantineTheme();
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openCreateModal } = useCreateTimeSlotException();
  const { openModal: openEditModal } = useEditTimeSlotException();
  const { openModal: openDeleteModal } = useDeleteTimeSlotException();

  const onDayClick = function (date: any) {
    openCreateModal(locationId, date.date);
  };

  const onUpdate = function (value: any) {
    openEditModal(value.id);
  };

  const onDelete = function (value: any) {
    openDeleteModal(value.id);
  };

  const formattedData = (data ? data.data : []).map((value: TimeSlotException) => {
    let date = new Date(value.date);

    return {
      id: value.id,
      name: value.description,
      startDate: date,
      endDate: date,
    };
  });

  const contextMenuItems = [
    ...(!isAuthorized(["time_slot_exception_update", "time_slot_exception_destroy"])
      ? [{ text: "No options available" }]
      : []),
    ...(isAuthorized(["time_slot_exception_update"])
      ? [{ text: "Update", click: (value: any) => onUpdate(value) }]
      : []),
    ...(isAuthorized(["time_slot_exception_destroy"])
      ? [{ text: "Delete", click: (value: any) => onDelete(value) }]
      : []),
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay
        visible={status === "loading" || isFetching}
        overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0]}
      />
      {isError ? (
        <ErrorView />
      ) : (
        <Calendar
          contextMenuItems={contextMenuItems}
          enableContextMenu={true}
          onDayClick={isAuthorized(["time_slot_exception_store"]) ? onDayClick : undefined}
          dataSource={formattedData}
        />
      )}
    </div>
  );
}
