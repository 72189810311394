import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import React from "react";
import CreateUserForm from "../../components/forms/user/CreateUserForm";

export default function useCreateUser(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = () => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Create user",
      closeOnClickOutside: false,
      children: (
        <>
          <CreateUserForm onClose={() => closeModal(modalId)} />
        </>
      ),
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/users`);
  };

  return {
    openModal,
  };
}
