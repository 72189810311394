import {Button} from "@mantine/core";
import {useContext} from "react";
import {UserContext} from "../../providers/user";
import useCreateCustomer from "../../hooks/customer/useCreateCustomer";

type Props = {
    buttonText?: string,
}

export default function CreateCustomerButton(props: Props) {
    const {buttonText} = props;
    const {isAuthorized} = useContext(UserContext);
    const {openModal} = useCreateCustomer();

    return (
        <>
            {isAuthorized("customer_store") && <Button onClick={() => openModal()}>{buttonText ?? "Create customer"}</Button>}
        </>
    )
}