import {TimeSlotException} from "../../../../types";
import TimeSlotExceptionForm from "./TimeSlotExceptionForm";
import useMutateTimeSlotExceptionData from "../../../hooks/timeSlotsExceptions/useMutateTimeSlotExceptionData";
import {fetchTimeSlotException, updateTimeSlotException} from "../../../api/timeSlotExceptions";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import React, {useEffect, useState} from "react";
import {FullPageSpinner} from "../../FullPageSpinner";

type Props = {
    timeSlotExceptionId: string,
    onClose: () => void,
}

export default function EditTimeSlotExceptionForm(props: Props) {
    const {timeSlotExceptionId, onClose} = props;
    const [timeSlotException, setTimeSlotException] = useState();

    const onSuccess = function(values: TimeSlotException) {
        showNotification({
            title: 'Time slot exception updated',
            icon: <Check/>,
            message: `Time slot exception '${values.description}' has been updated!`,
        });

        onClose();
    }

    const {mutate, isLoading} = useMutateTimeSlotExceptionData(updateTimeSlotException, onSuccess);

    const onSubmit = async function(timeSlotException: TimeSlotException) {
        await mutate(timeSlotException);
    }

    const onLoad = async function() {
        setTimeSlotException(await fetchTimeSlotException(timeSlotExceptionId));
    }

    useEffect(() => {
        onLoad();
    }, []);

    return !timeSlotException ? (
        <FullPageSpinner />
    ) : (
        <TimeSlotExceptionForm timeSlotException={timeSlotException} onSubmit={onSubmit} isLoading={isLoading} submitText={"Save"} />
    );
}
