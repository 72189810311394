import { Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import useMutateRoleData from "./useMutateRoleData";
import { deleteRole } from "../../api/roles";
import { showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";

export default function useDeleteRole() {
  const modals = useModals();

  const onSuccess = function (value: any) {
    showNotification({
      title: "Role deleted",
      icon: <Check />,
      message: `Role${value.data.count > 1 ? "s have" : " has"} been deleted!`,
    });
  };

  const onError = function (error: string) {
    showNotification({
      title: "Something went wrong",
      color: "red",
      message: `The role could not be deleted. ${error}`,
    });
  };

  const { mutate } = useMutateRoleData(deleteRole, onSuccess, onError);

  const openModal = function (ids: string[]) {
    modals.openConfirmModal({
      centered: true,
      title: "Are you sure?",
      children: (
        <Text
          size="sm"
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          })}
        >
          This action cannot be undone. Are you sure you want to delete{" "}
          {ids.length > 1 ? `these ${ids.length} roles` : "this role"}?
          {ids.length > 1 ? ` These roles` : ` This role`} will be removed from
          all assigned users.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "custom-red" },
      onCancel: () => {},
      onConfirm: () => mutate(ids),
    });
  };

  return {
    openModal,
  };
}
