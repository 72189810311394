import { Text } from "@mantine/core";
import dayjs from "dayjs";
import { SortingRule } from "react-table";
import { BunkerRequest, Maybe, TableDataFilter } from "./types";

export function generateSortString(sort: SortingRule<object>[]) {
  let columns = sort
    .map((s: SortingRule<object>) => `${s.desc ? "-" : ""}${s.id}`)
    .join(",");

  return `&sort=${columns}`;
}

export function env(name: string, defaultValue: string = "") {
  return process.env[`REACT_APP_${name}`] || defaultValue;
}

/**
 * Test if localstorage has a token
 */
export function hasAuthToken() {
  return Boolean(getAuthToken());
}

/**
 * Test if localstorage has a token
 */
export function getAuthToken() {
  return localStorage.getItem("token");
}

/**
 * Remove authorization token from localStorage
 */
export function removeAuthToken(): void {
  localStorage.removeItem("token");
}

export function isEmptyArray(input: Maybe<any[]>) {
  return Array.isArray(input) && !input.length;
}

export function isArray(input: Maybe<any[]>) {
  return Array.isArray(input);
}

export function generateFilterString(filters: TableDataFilter[]): string {
  let output = "";
  for (const filtersKey in filters) {
    if (typeof filters[filtersKey].value === "string") {
      output = output.concat(
        `&${filters[filtersKey].column}=${filters[filtersKey].value}`
      );
      continue;
    }

    let formattedArray = "";
    for (const arrayItem of filters[filtersKey].value) {
      formattedArray = `${formattedArray}&${filters[filtersKey].column}[]=${arrayItem}`;
    }

    output = output.concat(formattedArray);
  }

  return output;
}

/**
 * Format errors from backend
 */
export function formatErrors(response: any) {
  if (response?.errors?.stacktrace) {
    delete response.errors.stacktrace;
  }

  let messages: Array<string> = [];

  response.message && messages.push(response.message);

  response.errors &&
    Object.values(response.errors).map((errors: any) =>
      Object.values(errors).map((err: any) => messages.push(err))
    );

  response.error && messages.push(response.error);

  return messages;
}

export async function handleRequestErrors(response: Response) {
  if (!response.ok) {
    let responseClone = response.clone();
    let errors;

    try {
      errors = formatErrors(JSON.parse(await responseClone.text()));
    } catch (err) {
      throw new Error(response.statusText);
    }

    if (!isEmptyArray(errors)) {
      throw new Error(errors[0]);
    }

    throw new Error("An unknown error occurred.");
  }

  return response;
}

export function convertToDate(date: any, invalid: any = undefined) {
  if (date === undefined) return undefined;

  let dateObject = dayjs(date);

  return dateObject.isValid() ? dateObject.toDate() : invalid;
}

export function convertTimeToDate(time: any, invalid: any = undefined) {
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);

  if (time === undefined) return undefined;

  let dateObject = dayjs(time, "HH:mm");

  return dateObject.isValid() ? dateObject.toDate() : invalid;
}

export function convertDateToTime(date: Maybe<Date>) {
  return date ? dayjs(date).format("HH:mm") : null;
}

export function formatFileSizeMetric(fileSize: number) {
  let size = Math.abs(fileSize);

  if (Number.isNaN(size)) {
    return "N/A";
  }

  if (size === 0) {
    return "0 bytes";
  }

  const units = ["bytes", "kB", "MB", "GB", "TB"];
  let quotient = Math.floor(Math.log10(size) / 3);
  quotient = quotient < units.length ? quotient : units.length - 1;
  size /= 1000 ** quotient;

  return `${+size.toFixed(2)} ${units[quotient]}`;
}

export function formattedRequestStatus(bunkerRequest?: BunkerRequest) {
  if (!bunkerRequest) return <Text size={"sm"}>N/A</Text>;

  if (bunkerRequest.completed_at) return <Text size={"sm"}>Completed</Text>;
  if (bunkerRequest.cancelled_at)
    return (
      <Text
        size={"sm"}
        color={"custom-red"}
        title={bunkerRequest?.cancellation_reason ?? "N/A"}
      >
        Cancelled
      </Text>
    );

  if (bunkerRequest.processed_at) {
    return bunkerRequest.is_approved ? (
      <Text size={"sm"} color={"bright-green"}>
        Approved
      </Text>
    ) : (
      <Text size={"sm"} color={"custom-red"}>
        Rejected
      </Text>
    );
  }

  return <Text size={"sm"}>Pending</Text>;
}

export function formattedRequestAmount(d?: BunkerRequest) {
  if (!d) return <Text size={"sm"}>N/A</Text>;

  return `${d.bunkered_amount ? `${d.bunkered_amount} kg / ` : ""}${
    d.expected_amount
  } kg`;
}
