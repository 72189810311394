import {useModals} from "@mantine/modals";
import React from "react";
import CreateNotificationSettingForm from "../../components/forms/notificationSettings/CreateNotificationSettingForm";

export default function useCreateNotificationSetting() {
    const modals = useModals();

    const openModal = (locationId: string) => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Create notification setting',
            closeOnClickOutside: false,
            children: (
                <>
                    <CreateNotificationSettingForm locationId={locationId} onClose={() => closeModal(modalId)} />
                </>
            ),
        });
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
    }

    return {
        openModal
    }
}
