import { Menu } from "@mantine/core";
import { ReactElement, useContext } from "react";
import { Edit, Trash } from "tabler-icons-react";
import { UserContext } from "../../providers/user";

type Props = {
  onEdit: () => void;
  onDelete: () => void;
  controlButton: ReactElement;
};

export default function ShipOptionsMenu(props: Props) {
  const { onEdit, onDelete, controlButton } = props;
  const { isAuthorized } = useContext(UserContext);

  return (
    <Menu control={controlButton}>
      <Menu.Label>Options</Menu.Label>

      {isAuthorized("ship_update") && (
        <Menu.Item
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          })}
          icon={<Edit size={20} />}
          onClick={() => onEdit()}
        >
          Edit
        </Menu.Item>
      )}

      {isAuthorized("ship_destroy") && (
        <Menu.Item
          color="custom-red"
          icon={<Trash size={20} />}
          onClick={() => onDelete()}
          sx={(theme) => ({
            "&:hover": {
              backgroundColor: theme.colors["custom-red"][1],
            },
          })}
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  );
}
