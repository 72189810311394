import {useQuery} from 'react-query'
import {API_URL, FETCH_HEADERS} from "../../constants";
import {handleRequestErrors} from "../../../tools";

export const fetchData = async () => {
    return await fetch(`${API_URL}/dashboard`, {
        ...FETCH_HEADERS(),
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => data?.data)
        .catch((error) => {
            throw error.message;
        });
}

export default function useDashboardData() {
    return useQuery(['dashboard'], () => fetchData(),
        {
            keepPreviousData: false,
        }
    );
}
