import { InputWrapper, LoadingOverlay, MultiSelect } from "@mantine/core";
import { ReactNode, useEffect, useState } from "react";
import {
  InputWrapperBaseProps,
  InputWrapperStylesNames,
} from "@mantine/core/lib/components/InputWrapper/InputWrapper";
import { DefaultProps } from "@mantine/styles";

type FancyMultiSelectProps<T> = DefaultProps<InputWrapperStylesNames> &
  InputWrapperBaseProps & {
    onSelectedValuesChange: (selectedShips: string[]) => void;
    defaultValue?: string[];
    queryFn: (...params: any) => any;
    placeholder?: string;
    labelRenderer: (value: T) => ReactNode;
    disabled?: boolean;
  };

export default function FancyMultiSelect<T extends { id?: number | string }>(
  props: FancyMultiSelectProps<T>
) {
  const {
    onSelectedValuesChange,
    queryFn,
    defaultValue,
    label,
    labelRenderer,
    placeholder = "",
    disabled = false,
    ...restProps
  } = props;
  const [data, setData] = useState([]);
  const [values, setValues] = useState<string[]>();
  const [loading, setLoading] = useState(true);

  /**
   * Event handler that is triggered when the selected values on the MultiSelect have been changed.
   * @param values
   */
  const onChange = function (values: string[]) {
    // Parse the selected values back to Ship objects and return it to the onSelectedShipsChange function.
    onSelectedValuesChange(values);
    setValues(values);
  };

  /**
   * Fetch the ships data and set it formatted for the MultiSelect.
   */
  const onLoad = async function () {
    const data = await queryFn();

    if (!data?.data) {
      return;
    }

    setData(
      data.data.map((value: T) => {
        return {
          value: `${value.id}`,
          label: labelRenderer(value),
        };
      })
    );

    setLoading(false);
  };

  /**
   * Fetch ships data on mount.
   */
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <InputWrapper
      label={label}
      styles={(theme) => ({
        label: {
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
      })}
      {...restProps}
    >
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <LoadingOverlay visible={loading} loaderProps={{ size: "sm" }} />

        <MultiSelect
          data={data}
          value={values}
          defaultValue={defaultValue}
          onChange={onChange}
          searchable
          placeholder={placeholder}
          maxDropdownHeight={280}
          disabled={disabled}
          styles={(theme) => ({
            item: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      </div>
    </InputWrapper>
  );
}
