import React, {useEffect, useState} from "react";
import {Center, Loader,} from '@mantine/core';
import {NotificationSetting, PatchableNotificationSetting} from "../../../../types";
import NotificationSettingForm from "./NotificationSettingForm";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import {fetchNotificationSetting, updateNotificationSetting} from "../../../api/notificationSettings";
import useMutateNotificationSettingData from "../../../hooks/notificationSetting/useMutateNotificationSettingData";

type EditNotificationSettingFormProps = {
    locationId: string;
    notificationSettingId: string;
    onClose: () => void;
}

function EditNotificationSettingForm(props: EditNotificationSettingFormProps) {
    const { locationId, notificationSettingId, onClose } = props;
    const [notificationSetting, setNotificationSetting] = useState<NotificationSetting | undefined>();

    const onSuccess = (values: NotificationSetting) => {
        showNotification({
            title: 'Notification setting updated',
            icon: <Check/>,
            message: `Notification setting has been updated!`,
        })

        onClose()
    }

    const {mutate, isLoading} = useMutateNotificationSettingData(updateNotificationSetting, onSuccess);

    const onSubmit = (values: PatchableNotificationSetting) => {
        mutate({
            locationId: locationId,
            notificationSetting: values
        })
    }

    const onLoad = async () => {
        let data = await fetchNotificationSetting(notificationSettingId, locationId);
        setNotificationSetting(data);
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <>
            {!notificationSetting ?
                <Center>
                    <Loader height={200}/>
                </Center> :
                <NotificationSettingForm
                    isLoading={isLoading}
                    notificationSetting={notificationSetting}
                    submitAction={(values: PatchableNotificationSetting) => onSubmit(values)}
                />
            }
        </>
    );
}

export default EditNotificationSettingForm;
