import React from 'react';
import {FileText, FileTypography, FileUnknown, Photo} from "tabler-icons-react";

type BunkerRequestAttachmentOverviewProps = {
    mimeType: string;
}

const MimeTypeIcon = (props: BunkerRequestAttachmentOverviewProps) => {
    const {mimeType} = props;
    let icon = <FileUnknown size={64}/>;

    switch(mimeType) {
        case "application/pdf":
            icon = <FileText size={64}/>;
            break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/msword":
            icon = <FileTypography size={64}/>;
            break;
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
            icon = <Photo size={64}/>;
            break;
    }

    return (
        <>
            {icon}
        </>
    );
};

export default MimeTypeIcon;
