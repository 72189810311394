import React, { useContext } from "react";
import { createStyles, Group, Text, UnstyledButton } from "@mantine/core";
import { Icon as TablerIcon } from "tabler-icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbarContext } from "../../providers/navbar";

type LinksGroupProps = {
  icon: TablerIcon;
  label: string;
  link: string;
};

const useStyles = createStyles((theme) => ({
  link: {
    cursor: "pointer",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  activeLink: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[1],
    },
  },
}));

export default function LinksGroup(props: LinksGroupProps) {
  const { icon: Icon, label, link } = props;
  const { setOpened: setNavbarOpened } = useContext(NavbarContext);
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    navigate(link);
    setNavbarOpened(false);
  };

  return (
    <UnstyledButton
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[0]
            : theme.colors["custom-gray"][0],

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
      onClick={() => onClick()}
      className={cx(
        classes.link,
        location.pathname === link && classes.activeLink
      )}
    >
      <Group>
        <Icon size={16} />
        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}
