import React, { useEffect, useState } from "react";
import {
  Anchor,
  Center,
  Container,
  createStyles,
  Grid,
  Group,
  Paper,
  ScrollArea,
  Text,
  Title,
} from "@mantine/core";
import ForecastVolumeChart from "../components/charts/ForecastVolumeChart";
import ForecastPerMonthChart from "../components/charts/ForecastPerMonthChart";
import { DashboardData } from "../../types";
import UnconfirmedBunkerRequestCard from "../components/cards/UnconfirmedBunkerRequestCard";
import { FullPageSpinner } from "../components/FullPageSpinner";
import QuickActions from "../components/groups/QuickActions";
import QuickSearches from "../components/groups/QuickSearches";
import BunkerRequestOverviewCard from "../components/cards/BunkerRequestOverviewCard";
import { useNavigate } from "react-router-dom";
import useDashboardData from "../hooks/dashboard/useDashboardData";

const useStyles = createStyles((theme) => ({
  paper: {
    height: "100%",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

const HomeGridCol = (props: any) => {
  return (
    <Grid.Col span={6} xs={3} xl={2}>
      {props.children}
    </Grid.Col>
  );
};

export default function Home() {
  const navigate = useNavigate();
  const { data: fetchedData } = useDashboardData();
  const { classes } = useStyles();
  const [data, setData] = useState<DashboardData>();

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
    }
  }, [fetchedData]);

  return !data ? (
    <FullPageSpinner />
  ) : (
    <Container size="xl">
      <Title>Dashboard</Title>

      <Grid columns={6}>
        <HomeGridCol>
          <Paper radius="md" p="md" withBorder className={classes.paper}>
            <Title order={4}>Volume forecast</Title>
            <Center style={{ width: "100%", height: "100%" }}>
              <ForecastVolumeChart
                title={`Week ${data.current_week}`}
                labels={data.volume_forecast_current_week.map(
                  (value) => value.day
                )}
                bunkeredAmount={data.volume_forecast_current_week.map(
                  (value) => value.bunkered_amount
                )}
                expectedAmount={data.volume_forecast_current_week.map(
                  (value) => value.expected_amount
                )}
              />
            </Center>
          </Paper>
        </HomeGridCol>

        <HomeGridCol>
          <Paper radius="md" p="md" withBorder className={classes.paper}>
            <Title order={4}>Forecast per month</Title>
            {data.volume_forecast_current_month.bunkered_amount === 0 &&
            data.volume_forecast_current_month.expected_amount === 0 ? (
              <Text mt={10}>No data available yet.</Text>
            ) : (
              <Center style={{ width: "100%", height: "100%" }}>
                <ForecastPerMonthChart
                  bunkeredAmount={
                    data.volume_forecast_current_month.bunkered_amount
                  }
                  expectedAmount={
                    data.volume_forecast_current_month.expected_amount
                  }
                />
              </Center>
            )}
          </Paper>
        </HomeGridCol>

        <HomeGridCol>
          <Paper radius="md" p="md" withBorder className={classes.paper}>
            <Group position="apart" spacing={0}>
              <Title order={4}>
                Unconfirmed bunker requests (
                {data.unconfirmed_bunker_requests_count})
              </Title>
              <Anchor onClick={() => navigate("/requests")}>View all</Anchor>
            </Group>

            {data.unconfirmed_bunker_requests.length < 1 ? (
              <Text mt={10}>No unconfirmed bunker requests.</Text>
            ) : (
              <ScrollArea style={{ height: 500 }}>
                {data.unconfirmed_bunker_requests.map((value) => (
                  <UnconfirmedBunkerRequestCard
                    bunkerRequest={value}
                    key={value.id}
                  />
                ))}
              </ScrollArea>
            )}
          </Paper>
        </HomeGridCol>

        <HomeGridCol>
          <Paper radius="md" p="md" withBorder className={classes.paper}>
            <Title order={4}>Bunker requests overview</Title>
            {data.week_overview_bunker_requests.map((value: any) => (
              <BunkerRequestOverviewCard
                dateText={value.week}
                reservedTimeSlots={value.reserved_time_slots}
                totalTimeSlots={value.total_time_slots}
                progressColor={
                  value.week === "This week" ? "#7DCB7D" : undefined
                }
                key={value.week}
              />
            ))}

            <BunkerRequestOverviewCard
              dateText={"This month"}
              reservedTimeSlots={
                data.month_overview_bunker_requests.reserved_time_slots
              }
              totalTimeSlots={
                data.month_overview_bunker_requests.total_time_slots
              }
            />
          </Paper>
        </HomeGridCol>

        <HomeGridCol>
          <Paper radius="md" p="md" withBorder className={classes.paper}>
            <Title mb={"md"} order={4}>
              Quick search
            </Title>
            <QuickSearches />
          </Paper>
        </HomeGridCol>

        <HomeGridCol>
          <Paper radius="md" p="md" withBorder className={classes.paper}>
            <Title mb={"md"} order={4}>
              Quick actions
            </Title>
            <QuickActions />
          </Paper>
        </HomeGridCol>
      </Grid>
    </Container>
  );
}
