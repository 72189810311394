import {
  AutocompleteItem,
  Input,
  InputWrapper,
  LoadingOverlay,
  Select,
} from "@mantine/core";
import { AutocompleteStylesNames } from "@mantine/core/lib/components/Autocomplete/Autocomplete";
import { DefaultProps } from "@mantine/styles";
import React, { useEffect, useState } from "react";
import { TimeSlot } from "../../../types";
import { fetchTimeSlots } from "../../api/timeSlots";

interface TimeSlotSelectProps extends DefaultProps<AutocompleteStylesNames> {
  bunkerRequestId?: string;
  error?: React.ReactNode;
  defaultValue?: string;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  date: Date;
  location: string;
}

export function TimeSlotSelect(props: TimeSlotSelectProps) {
  const {
    bunkerRequestId,
    onChange,
    disabled = false,
    defaultValue,
    error,
    required,
    label,
    date,
    location,
    placeholder = "Select a time slot",
  } = props;
  const [value, setValue] = useState<string | null>(defaultValue ?? "");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AutocompleteItem[]>([]);

  useEffect(() => {
    !disabled && onLoad();
  }, []);

  useEffect(() => {
    !disabled && onReload();
  }, [date, location]);

  const onReload = () => {
    setLoading(true);
    onLoad();
  };

  const onLoad = async () => {
    let filteredData = await fetchTimeSlots(
      0,
      100,
      date,
      location,
      bunkerRequestId
    );

    setData(
      filteredData.map((c: TimeSlot) => {
        return {
          value: c.id,
          label: `${c.start_time} - ${c.end_time}`,
        };
      })
    );

    setLoading(false);
  };

  const onValueChange = (value: string | null) => {
    setValue(value);
    onChange(value);
  };

  return (
    <InputWrapper
      required={required}
      label={label ?? "Time slot"}
      styles={(theme) => ({
        label: {
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
        required: {
          color: "#004F65",
        },
      })}
    >
      {loading || disabled ? (
        <div style={{ position: "relative" }}>
          <LoadingOverlay
            loaderProps={{ size: "sm" }}
            visible={loading && !disabled}
          />
          <Input disabled={disabled} placeholder={placeholder} />
        </div>
      ) : (
        <Select
          nothingFound={"No time slots available"}
          data={data}
          value={value}
          clearable
          error={error}
          required={required}
          onChange={onValueChange}
          maxDropdownHeight={280}
          placeholder={placeholder}
          styles={(theme) => ({
            item: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      )}
    </InputWrapper>
  );
}
