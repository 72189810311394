import {useQuery} from 'react-query'
import {API_URL, FETCH_HEADERS} from "../../constants";
import {handleRequestErrors} from "../../../tools";

export const fetchData = async (id?: string) => {
    return await fetch(`${API_URL}/location/${id}`, {
        ...FETCH_HEADERS(),
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}

export default function useLocationData(id?: string, onSuccess?: any, onError?: any) {
    return useQuery(['locations'], () => fetchData(id),
        {
            onSuccess,
            onError,
            keepPreviousData: false,
        }
    );
}
