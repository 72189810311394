import {NotificationSetting, PatchableNotificationSetting} from "../../../../types";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import {createNotificationSetting} from "../../../api/notificationSettings";
import NotificationSettingForm from "./NotificationSettingForm";
import useMutateNotificationSettingData from "../../../hooks/notificationSetting/useMutateNotificationSettingData";

type CreateNotificationSettingFormProps = {
    locationId: string;
    onClose: () => void;
}

function CreateNotificationSettingForm(props: CreateNotificationSettingFormProps) {
    const { locationId, onClose } = props;

    const onSuccess = (values: NotificationSetting) => {
        showNotification({
            title: 'Notification setting created',
            icon: <Check/>,
            message: `Notification setting has been created!`,
        })

        onClose()
    }

    const onError = (error: string) => {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while creating the notification setting. ${error}`,
            color: 'red'
        })
    }

    const {mutate, isLoading} = useMutateNotificationSettingData(createNotificationSetting, onSuccess, onError);

    const onSubmit = (values: PatchableNotificationSetting) => {
        mutate({
            locationId: locationId,
            notificationSetting: values
        })
    }

    return (
        <>
            <NotificationSettingForm
                isLoading={isLoading}
                submitAction={(values: PatchableNotificationSetting) => onSubmit(values)}
            />
        </>
    );
}

export default CreateNotificationSettingForm;
