import React, { useEffect, useState } from "react";
import Map, {
  Marker,
  MarkerDragEvent,
  NavigationControl,
  useMap,
} from "react-map-gl";
import { MAPBOX_TOKEN } from "../constants";
import { MapPin } from "tabler-icons-react";
import mapboxgl from "mapbox-gl";
import { useDidUpdate } from "@mantine/hooks";

// See https://github.com/visgl/react-map-gl/issues/1266#issuecomment-771121904 to know why this is needed.
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = // See https://github.com/visgl/react-map-gl/issues/1266#issuecomment-771121904 to know why this is needed.
  /* eslint-disable @typescript-eslint/no-var-requires */
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type LocationMarker = {
  latitude: number;
  longitude: number;
};

type LocationSelectorMapProp = {
  initialLocation?: LocationMarker;
  onMarkerDragEnd?: (e: MarkerDragEvent) => void;
  markerDraggable?: boolean;
};

export function LocationSelectorMap(props: LocationSelectorMapProp) {
  const { initialLocation, onMarkerDragEnd, markerDraggable = true } = props;
  const { locationSelectorMap } = useMap();

  // Default to the center of the Netherlands
  const [marker, setMarker] = useState<LocationMarker>(
    initialLocation ?? {
      latitude: 52.22,
      longitude: 5.21,
    }
  );

  const onMarkerDrag = (event: MarkerDragEvent) => {
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  };

  useDidUpdate(() => {
    if (initialLocation) {
      setMarker(initialLocation);

      locationSelectorMap?.flyTo({
        center: [initialLocation.longitude, initialLocation.latitude],
      });
    }
  }, [initialLocation]);

  return (
    <Map
      id={"locationSelectorMap"}
      mapboxAccessToken={MAPBOX_TOKEN}
      initialViewState={{
        longitude: marker.longitude,
        latitude: marker.latitude,
        zoom: 6,
      }}
      style={{ width: "100%", height: 400 }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
      <Marker
        longitude={marker.longitude}
        latitude={marker.latitude}
        anchor="bottom"
        draggable={markerDraggable}
        onDrag={onMarkerDrag}
        onDragEnd={onMarkerDragEnd}
      >
        <MapPin color={"#C5043F"} size={24} />
      </Marker>

      <NavigationControl />
    </Map>
  );
}
