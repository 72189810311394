import React from "react";
import {useModals} from "@mantine/modals";
import {LocationSelectorMap} from "../../components/LocationSelectorMap";

export default function useLocationMap() {
    const modals = useModals();

    const openModal = (initialLocation?: {latitude: number, longitude: number}) => {
        modals.openModal({
            size: "xl",
            title: 'Map',
            closeOnClickOutside: false,
            children: (
                <>
                    <LocationSelectorMap initialLocation={initialLocation} markerDraggable={false}/>
                </>
            ),
        });
    }

    return {
        openModal
    };
}