import React, {createContext, ReactNode, useState} from "react";

type Props = {
    children?: ReactNode;
};

type NavbarContextProps = {
    opened: boolean;
    setOpened: (value: boolean) => void;
};

export const NavbarContext = createContext<NavbarContextProps>({
    opened: false,
    setOpened: () => {}
});

export default function NavbarProvider(props: Props) {
    const [opened, setOpenedState] = useState(false);

    function setOpened(value: boolean) {
        setOpenedState(value);
    }

    return (
        <NavbarContext.Provider value={{opened, setOpened}}>
            {props.children}
        </NavbarContext.Provider>
    );
}
