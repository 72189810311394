import React from "react";
import { useModals } from "@mantine/modals";
import CreateTimeSlotExceptionForm from "../../components/forms/timeSlotException/CreateTimeSlotExceptionForm";

export default function useCreateTimeSlotException() {
  const modals = useModals();

  const openModal = function (location_id: string, date?: Date) {
    const modalId = modals.openModal({
      size: "xl",
      title: "Create time slot exception",
      closeOnClickOutside: false,
      children: (
        <CreateTimeSlotExceptionForm
          date={date}
          location_id={location_id}
          onClose={() => closeModal(modalId)}
        />
      ),
    });
  };

  const closeModal = function (modalId: string) {
    modals.closeModal(modalId);
  };

  return {
    openModal,
  };
}
