import { Anchor, Group, Text } from "@mantine/core";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Trash } from "tabler-icons-react";
import { fetchShips } from "../../api/ships";
import useDeleteShip from "../../hooks/ship/useDeleteShip";
import useEditShip from "../../hooks/ship/useEditShip";
import { UserContext } from "../../providers/user";
import FancyTable from "./FancyTable";

export default function ShipTable() {
  const navigate = useNavigate();
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteShip();
  const { openModal: openEditModal } = useEditShip();

  const columns = React.useMemo(
    () => [
      {
        id: "ship-table",
        columns: [
          {
            id: "name",
            Header: "Name",
            accessor: (d: any) => (
              <Anchor size={"sm"} component={Link} to={`/ships/${d.id}`}>
                {d.name}
              </Anchor>
            ),
          },
          {
            id: "phone",
            Header: "Phone number ship",
            accessor: (d: any) => {
              return (
                <Anchor size={"sm"} href={`tel:${d.phone}`}>
                  {d.phone}
                </Anchor>
              );
            },
          },
          {
            id: "imo_number",
            Header: "Identification number",
            accessor: (d: any) => <Text size={"sm"}>{d.imo_number}</Text>,
          },
          {
            id: "storage_size",
            Header: "Storage size",
            accessor: (d: any) => <Text size={"sm"}>{d.storage_size} kg</Text>,
          },
          {
            id: "customer.name",
            Header: "Customer",
            accessor: (d: any) => {
              return (
                <Group noWrap spacing={5}>
                  {d.customer?.deleted_at && (
                    <Group title={"Customer has been deleted"}>
                      <Trash size={"16"} color={"red"} />
                    </Group>
                  )}
                  <Text size={"sm"}>{d.customer?.name}</Text>
                </Group>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const onDetails = (id: string) => navigate(`/ships/${id}`);

  return (
    <>
      <FancyTable
        queryKey={"ships"}
        queryFn={fetchShips}
        columns={columns}
        onDelete={isAuthorized("ship_destroy") ? openDeleteModal : undefined}
        onEdit={isAuthorized("ship_update") ? openEditModal : undefined}
        onDetails={onDetails}
      />
    </>
  );
}
