import React, { useContext } from "react";
import FancyTable from "./FancyTable";
import { fetchBunkerRequests } from "../../api/bunkerRequests";
import useEditBunkerRequest from "../../hooks/bunkerRequest/useEditBunkerRequest";
import { Anchor, Button, createStyles, Group, Menu, Text } from "@mantine/core";
import {
  Ban,
  Check,
  Checks,
  CloudUpload,
  Dots,
  File,
  Files,
  FileUnknown,
  Gavel,
  Pencil,
  Trash,
  X,
} from "tabler-icons-react";
import useApproveBunkerRequest from "../../hooks/bunkerRequest/useApproveBunkerRequest";
import useRejectBunkerRequest from "../../hooks/bunkerRequest/useRejectBunkerRequest";
import { Link } from "react-router-dom";
import useAttachDocumentsToBunkerRequest from "../../hooks/bunkerRequest/useAttachDocumentsToBunkerRequest";
import useViewBunkerRequestAttachments from "../../hooks/bunkerRequest/useViewBunkerRequestAttachments";
import useCancelBunkerRequest from "../../hooks/bunkerRequest/useCancelBunkerRequest";
import { formattedRequestStatus } from "../../../tools";
import useCompleteBunkerRequest from "../../hooks/bunkerRequest/useCompleteBunkerRequest";
import { UserContext } from "../../providers/user";
import { TableDataFilter } from "../../../types";

const useStyles = createStyles((theme) => ({
  tableDataCell: {
    whiteSpace: "nowrap",
  },
}));

type BunkerRequestsTableProps = {
  filters?: TableDataFilter[];
};

function BunkerRequestsTable(props: BunkerRequestsTableProps) {
  const { filters } = props;
  const { classes } = useStyles();
  const { openModal: openEditModal } = useEditBunkerRequest();
  const { openModal: openApproveModal } = useApproveBunkerRequest();
  const { openModal: openRejectModal } = useRejectBunkerRequest();
  const { openModal: openAttachDocumentsModal } =
    useAttachDocumentsToBunkerRequest();
  const { openModal: openViewAttachmentsModal } =
    useViewBunkerRequestAttachments();
  const { openModal: openCancelBunkerRequest } = useCancelBunkerRequest();
  const { openModal: openCompleteBunkerRequest } = useCompleteBunkerRequest();
  const { isAuthorized } = useContext(UserContext);

  const columns = React.useMemo(
    () => [
      {
        id: "bunkerRequest-table",
        columns: [
          {
            id: "ship.name",
            Header: "Ship",
            accessor: (d: any) => {
              return (
                <Group noWrap spacing={5}>
                  {d.ship.deleted_at && (
                    <Group title={"Ship has been deleted"}>
                      <Trash size={"16"} color={"#C5043F"} />
                    </Group>
                  )}
                  {isAuthorized("ship_show") ? (
                    <Anchor
                      size={"sm"}
                      component={Link}
                      to={`/ships/${d.ship?.id}`}
                    >
                      {d.ship?.name}
                    </Anchor>
                  ) : (
                    <Text size={"sm"}>{d.ship?.name}</Text>
                  )}
                </Group>
              );
            },
          },
          {
            id: "customer.name",
            Header: "Customer",
            accessor: (d: any) => {
              return (
                <Group noWrap spacing={5}>
                  {d.ship?.customer?.deleted_at && (
                    <Group title={"Customer has been deleted"}>
                      <Trash size={"16"} color={"#C5043F"} />
                    </Group>
                  )}
                  <Text size={"sm"}>{d.ship?.customer?.name}</Text>
                </Group>
              );
            },
          },
          {
            id: "location.name",
            Header: "Location",
            accessor: (d: any) => {
              return (
                <Group noWrap spacing={5}>
                  {d.time_slot?.location?.deleted_at && (
                    <Group title={"Location has been deleted"}>
                      <Trash size={"16"} color={"#C5043F"} />
                    </Group>
                  )}
                  {d.time_slot?.location?.deleted_at ? (
                    <Text size={"sm"}>{d.time_slot?.location?.name}</Text>
                  ) : (
                    <Anchor
                      size={"sm"}
                      component={Link}
                      to={`/locations/${d.time_slot?.location?.id}`}
                    >
                      {d.time_slot?.location?.name}
                    </Anchor>
                  )}
                </Group>
              );
            },
          },
          {
            id: "user.name",
            Header: "Requested by",
            accessor: (d: any) => d.user?.name ?? "N/A",
          },
          {
            id: "status",
            Header: "Status",
            disableSortBy: true,
            accessor: (d: any) => {
              return formattedRequestStatus(d);
            },
          },
          {
            id: "date",
            Header: "Date",
            accessor: "date",
          },
          {
            id: "timeslot",
            Header: "Timeslot",
            disableSortBy: true,
            accessor: (d: any) => {
              return (
                <Group noWrap spacing={5}>
                  {d.time_slot.deleted_at && (
                    <Group title={"Time slot has been deleted"}>
                      <Trash size={"16"} color={"#C5043F"} />
                    </Group>
                  )}
                  <Text size={"sm"}>
                    {d.time_slot
                      ? `${d.time_slot?.start_time} - ${d.time_slot?.end_time}`
                      : "N/A"}
                  </Text>
                </Group>
              );
            },
          },
          {
            id: "expected_amount",
            Header: "Expected amount",
            accessor: (d: any) =>
              `${d.expected_amount ? `${d.expected_amount} kg` : "N/A"}`,
          },
          {
            id: "bunkered_amount",
            Header: "Bunkered amount",
            accessor: (d: any) =>
              `${d.bunkered_amount ? `${d.bunkered_amount} kg` : "N/A"}`,
          },
        ],
      },
    ],
    []
  );

  const subComponentRenderer = (row: any) => {
    return (
      <>
        <td className={classes.tableDataCell}>
          <p>Identification number: {row.original.ship?.imo_number}</p>
          <p>Tel: {row.original.ship?.phone}</p>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className={classes.tableDataCell}>
          <p>
            Bunkered amount:{" "}
            {row.original.bunkered_amount
              ? `${row.original.bunkered_amount} kg`
              : "N/A"}
          </p>
          <p>Storage tank size: {row.original.ship?.storage_size} kg</p>
        </td>
        <td></td>
      </>
    );
  };

  const additionalActionsRenderer = (row: any) => {
    const hasAttachments = row.original.attachments.length > 0;
    const fileButtonDisabled =
      !hasAttachments &&
      (!!row.original.cancelled_at || !row.original.is_approved);
    const uploadButtonDisabled =
      !!row.original.cancelled_at ||
      !!row.original.completed_at ||
      !row.original.processed_at ||
      (!!row.original.processed_at && !row.original.is_approved);
    const additionalActionsHidden = !isAuthorized([
      "bunker_request_complete",
      "bunker_request_destroy",
      "bunker_request_update",
      "bunker_request_update_24h",
    ]);

    return (
      <>
        <Group noWrap spacing={0}>
          <Menu
            control={
              <Button
                color={hasAttachments ? "bright-green" : undefined}
                disabled={fileButtonDisabled}
              >
                <File />
              </Button>
            }
            transition="pop"
            placement="end"
            styles={(theme) => ({
              item: {
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              },
            })}
          >
            <Menu.Item
              icon={<CloudUpload size={16} />}
              disabled={uploadButtonDisabled}
              onClick={() => openAttachDocumentsModal(row.original.id)}
            >
              Upload file(s)
            </Menu.Item>
            <Menu.Item
              icon={<Files size={16} />}
              disabled={!hasAttachments || !isAuthorized("bunker_request_show")}
              onClick={() => openViewAttachmentsModal(row.original.id)}
            >
              View documents
            </Menu.Item>
          </Menu>
        </Group>

        <Group
          noWrap
          spacing={0}
          hidden={
            !isAuthorized(["bunker_request_approve", "bunker_request_reject"])
          }
        >
          <Menu
            title={"Process bunker request"}
            control={
              <Button
                disabled={
                  !!row.original.cancelled_at ||
                  !!row.original.completed_at ||
                  (row.original.processed_at && !row.original.is_approved)
                }
              >
                <Gavel />
              </Button>
            }
            transition="pop"
            placement="end"
            styles={(theme) => ({
              item: {
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              },
            })}
          >
            <Menu.Item
              icon={<Check size={16} color={"green"} />}
              disabled={row.original.processed_at && row.original.is_approved}
              hidden={!isAuthorized("bunker_request_approve")}
              onClick={() => openApproveModal(row.original.id)}
            >
              Approve
            </Menu.Item>
            <Menu.Item
              icon={<Ban size={16} color={"#C5043F"} />}
              hidden={!isAuthorized("bunker_request_reject")}
              onClick={() => openRejectModal(row.original.id)}
            >
              Reject
            </Menu.Item>
          </Menu>
        </Group>

        <Group noWrap spacing={0} hidden={additionalActionsHidden}>
          <Menu
            title={"Additional actions"}
            control={
              <Button
                disabled={
                  !!row.original.cancelled_at || !!row.original.completed_at
                }
              >
                <Dots />
              </Button>
            }
            transition="pop"
            placement="end"
            styles={(theme) => ({
              item: {
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              },
            })}
          >
            <Menu.Item
              icon={<Pencil size={16} />}
              hidden={
                !isAuthorized([
                  "bunker_request_update",
                  "bunker_request_update_24h",
                ])
              }
              disabled={row.original.processed_at && !row.original.is_approved}
              onClick={() => openEditModal(row.original.id)}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              hidden={!isAuthorized("bunker_request_destroy")}
              icon={<X size={16} color={"#C5043F"} />}
              onClick={() => openCancelBunkerRequest(row.original.id)}
            >
              Cancel
            </Menu.Item>
            <Menu.Item
              icon={<Checks size={16} color={"green"} />}
              disabled={
                !hasAttachments ||
                !row.original.bunkered_amount ||
                (row.original.processed_at && !row.original.is_approved)
              }
              hidden={!isAuthorized("bunker_request_complete")}
              onClick={() => openCompleteBunkerRequest(row.original.id)}
              rightSection={!hasAttachments && <FileUnknown size={16} />}
            >
              Complete
            </Menu.Item>
          </Menu>
        </Group>
      </>
    );
  };

  return (
    <>
      <FancyTable
        queryKey={"bunkerRequests"}
        queryFn={fetchBunkerRequests}
        columns={columns}
        subComponentRenderer={subComponentRenderer}
        additionalActionsRenderer={additionalActionsRenderer}
        filters={filters}
        initialSortBy={
          [{ id: "date", desc: true }] as { id: string; desc: boolean }[]
        }
      />
    </>
  );
}

export default BunkerRequestsTable;
