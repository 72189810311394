import {useQuery} from 'react-query'
import {fetchBunkerRequest} from "../../api/bunkerRequests";

export default function useBunkerRequestData(id?: string, onSuccess?: any, onError?: any) {
    return useQuery(['bunkerRequests'], () => fetchBunkerRequest(id),
        {
            onSuccess,
            onError,
            keepPreviousData: false,
        }
    );
}
