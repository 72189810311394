import LocationOptionsMenu from "../menus/LocationOptionsMenu";
import { Button, Group } from "@mantine/core";
import { ChevronDown } from "tabler-icons-react";
import React from "react";
import useDeleteLocation from "../../hooks/location/useDeleteLocation";
import useEditLocation from "../../hooks/location/useEditLocation";
import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
};

export default function LocationOptionsMenuButton(props: Props) {
  const { id } = props;
  const navigate = useNavigate();
  const { openModal: openDeleteModal } = useDeleteLocation(true);
  const { openModal: openEditModal } = useEditLocation();

  return (
    <LocationOptionsMenu
      onEdit={() => openEditModal(id)}
      onDelete={() => openDeleteModal([id])}
      onReporting={() => navigate(`/locations/${id}/reporting`)}
      controlButton={
        <Button>
          <Group spacing={"sm"}>
            Options
            <ChevronDown size={16} />
          </Group>
        </Button>
      }
    />
  );
}
