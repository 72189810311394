import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

type ForecastVolumeChartProps = {
  title: string;
  labels: string[];
  bunkeredAmount: number[];
  expectedAmount: number[];
};

export default function ForecastVolumeChart(props: ForecastVolumeChartProps) {
  const { title, labels, bunkeredAmount, expectedAmount } = props;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    plugins: {
      title: {
        display: true,
        text: title,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        // Do not stack the data sets on top of each other.
        stacked: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    datasets: {
      bar: {
        barThickness: 10,
        minBarLength: 5,
        borderRadius: 50,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Bunkered amount",
        data: bunkeredAmount,
        backgroundColor: "#7DCB7D",
      },
      {
        label: "Expected amount",
        data: expectedAmount,
        backgroundColor: "#289323",
      },
    ],
  };

  return <Bar height={300} options={options} data={data} />;
}
