import {createContext, ReactNode, useEffect, useState} from 'react';
import {useDebouncedValue} from "@mantine/hooks";
import {DEBOUNCE_TIMEOUT} from "../constants";
import {useLocation} from "react-router-dom";

type Props = {
    children?: ReactNode,
}

type SearchContextProps = {
    query: string,
    debouncedQuery: string,
    setQuery: (searchQuery: string) => void,
    setEmptyOnNavigate: (state: boolean) => void,
}

export const SearchContext = createContext<SearchContextProps>({
    query: "",
    debouncedQuery: "",
    setQuery: () => {},
    setEmptyOnNavigate: () => {},
});

export default function SearchProvider(props: Props) {
    const [query, setQueryState] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, DEBOUNCE_TIMEOUT);
    const [emptyOnNavigate, setEmptyOnNavigate] = useState(true);
    const location = useLocation();

    // Empty query on navigate
    useEffect(() => {
        if (emptyOnNavigate) {
            setQuery("");
            return;
        }

        setEmptyOnNavigate(true);
    } , [location]);

    function setQuery(query: string) {
        setQueryState(query);
    }

    return (
        <SearchContext.Provider value={{query, debouncedQuery, setQuery, setEmptyOnNavigate}}>
            {props.children}
        </SearchContext.Provider>
    );
}