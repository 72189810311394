import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {API_URL} from "../constants";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useLazyFetch from "./useLazyFetch";
import {formatErrors, isEmptyArray} from "../../tools";

/**
 * Hook for handling email verification
 */
export default function useResetPassword() {
    const [formData, setFormData] = useState({
        'password': '',
        'password_confirmation': '',
    });

    const {token, email, expires, signature} = useParams();
    const navigate = useNavigate();

    const {doFetch, loading} = useLazyFetch({
        url: `${API_URL}/auth/password-reset`,
        options: {
            method: "POST",
            body: JSON.stringify({
                token: token,
                email: email,
                expires: expires,
                signature: signature,
                ...formData
            })
        },
    });

    const onSubmit = async () => {
        const res = await doFetch();
        const errors = formatErrors(res);

        if (!isEmptyArray(errors)) {
            showNotification({
                title: `Something went wrong`,
                message: `We could not set your password. ${errors[0]}`,
                color: 'red',
                autoClose: 10000
            })

            return;
        }

        showNotification({
            title: 'Password set',
            icon: <Check/>,
            message: `Your new password has been set, you can now log in!`,
        });

        navigate("/login")
    };

    return {
        onSubmit,
        setFormData,
        loading,
    };
}
