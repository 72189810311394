import React from "react";
import {fetchNotificationSettings} from "../../api/notificationSettings";
import {NotificationSetting} from "../../../types";
import {NOTIFICATION_SETTING_EVENTS, NOTIFICATION_SETTING_METHODS} from "../../constants";
import FancyTable from "./FancyTable";

type NotificationSettingTableProps = {
    locationId: string;

    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
}

export default function NotificationSettingsTable(props: NotificationSettingTableProps) {
    const {locationId, onEdit, onDelete} = props;

    const notifiableReceiver = (item: NotificationSetting) => {
        if (item.notifiable_type) return item.notifiable?.name ?? "N/A";
        if (item.email) return item.email;
        if (item.phone) return item.phone;

        return "Unknown";
    }

    const columns = React.useMemo(
        () => [
            {
                id: 'notificationSetting-table',
                columns: [
                    {
                        id: 'type',
                        Header: 'Type',
                        accessor: (d: any) => {
                            return NOTIFICATION_SETTING_EVENTS.find(s => s.value === d.event)?.label ?? "N/A";
                        }
                    },
                    {
                        id: 'recipient',
                        Header: 'Recipient',
                        accessor: (d: any) => {
                            return `${notifiableReceiver(d)} (${d.type})`;
                        }
                    },
                    {
                        id: 'method',
                        Header: 'Method',
                        accessor: (d: any) => {
                            return NOTIFICATION_SETTING_METHODS.find(s => s.value === d.method)?.label ?? "N/A";
                        }
                    },
                ],
            },
        ],
        []
    )

    return (
        <>
            <FancyTable
                queryKey={'notificationSettings'}
                queryFn={fetchNotificationSettings}
                filters={[{column: 'locationId', value: locationId}]}
                columns={columns}
                onDelete={onDelete ? (ids: string[]) => onDelete(ids[0]) : undefined}
                onEdit={onEdit}
                disableSortBy={true}
                disableMultiSelect={true}
            />
        </>
    );
}