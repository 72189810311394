import { Button, Card, Group, Text, createStyles } from "@mantine/core";
import { Icon } from "tabler-icons-react";

type Props = {
  Icon: Icon;
  text: string;
  ButtonIcon: Icon;
  buttonAction: () => void;
};

const useStyles = createStyles((theme) => ({
  paper: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

export default function QuickActionCard(props: Props) {
  const { Icon, text, ButtonIcon, buttonAction } = props;
  const { classes } = useStyles();

  return (
    <Card withBorder className={classes.paper}>
      <Group position={"apart"} noWrap>
        <Group>
          <Icon />

          <Text>{text}</Text>
        </Group>

        <Button
          onClick={buttonAction}
          variant="subtle"
          p="0px 5px 0px 5px"
          sx={(theme) => ({
            "&:hover": {
              backgroundColor: theme.colors["bright-green"][1],
            },
          })}
        >
          <ButtonIcon />
        </Button>
      </Group>
    </Card>
  );
}
