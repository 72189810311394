import {useModals} from "@mantine/modals";
import React from "react";
import EditNotificationSettingForm from "../../components/forms/notificationSettings/EditNotificationSettingForm";

export default function useEditNotificationSetting() {
    const modals = useModals();

    const openModal = (id: string, locationId: string) => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Edit notification setting',
            closeOnClickOutside: false,
            children: (
                <>
                    <EditNotificationSettingForm locationId={locationId} notificationSettingId={id} onClose={() => closeModal(modalId)}/>
                </>
            ),
        });
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
    }

    return {
        openModal
    }
}
