import React, { useContext } from "react";
import FancyTable from "./FancyTable";
import { fetchLocations } from "../../api/locations";
import useDeleteLocation from "../../hooks/location/useDeleteLocation";
import useEditLocation from "../../hooks/location/useEditLocation";
import { useNavigate } from "react-router-dom";
import { Anchor, Stack, Text } from "@mantine/core";
import useLocationMap from "../../hooks/location/useLocationMap";
import { UserContext } from "../../providers/user";
import { Location } from "../../../types";

function LocationsTable() {
  const { openModal: openEditModal } = useEditLocation();
  const { openModal: openDeleteModal } = useDeleteLocation();
  const { openModal: openMapModal } = useLocationMap();
  const navigate = useNavigate();
  const { isAuthorized } = useContext(UserContext);

  const columns = React.useMemo(
    () => [
      {
        id: "location-table",
        columns: [
          {
            id: "name",
            Header: "Name",
            accessor: (d: any) => {
              return (
                <Anchor size={"sm"} onClick={() => navigate(`/locations/${d.id}`)}>
                  {d.name}
                </Anchor>
              );
            },
          },
          {
            id: "phone",
            Header: "Phone number",
            accessor: (d: Location) => {
              return (
                <Stack spacing={5}>
                  <Text size={"sm"}>
                    Primary:{" "}
                    <Anchor size={"sm"} href={`tel:${d.phone}`}>
                      {d.phone}
                    </Anchor>
                  </Text>

                  {d.phone2 ? (
                    <Text size={"sm"}>
                      Alternative:{" "}
                      <Anchor size={"sm"} href={`tel:${d.phone2}`}>
                        {d.phone2}
                      </Anchor>
                    </Text>
                  ) : null}
                </Stack>
              );
            },
          },
          {
            id: "email",
            Header: "E-mail address",
            accessor: (d: Location) => {
              return (
                <Anchor size={"sm"} href={`mailto:${d.email}`}>
                  {d.email}
                </Anchor>
              );
            },
          },
          {
            id: "location",
            Header: "Location",
            accessor: (d: Location) => {
              return (
                <Anchor
                  size={"sm"}
                  onClick={() => openMapModal({ latitude: d.lat, longitude: d.lon })}
                >
                  {d.location}
                </Anchor>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const onDetails = (id: string) => navigate(`/locations/${id}`);

  return (
    <>
      <FancyTable
        queryKey={"locations"}
        queryFn={fetchLocations}
        columns={columns}
        onDelete={isAuthorized(["location_destroy"]) ? openDeleteModal : undefined}
        onEdit={isAuthorized(["location_update"]) ? openEditModal : undefined}
        onDetails={onDetails}
      />
    </>
  );
}

export default LocationsTable;
