import { env, getAuthToken } from "../tools";

/**
 * The application name
 * Used for setting the title
 */
export const APP_NAME = env("APP_NAME", "ViGo bioenergy");

/**
 * The API url
 */
export const API_URL = env("API_URL");

/**
 * The Mapbox public token
 */
export const MAPBOX_TOKEN = env("MAPBOX_TOKEN");

/**
 * Debounce timeout
 * See https://mantine.dev/hooks/use-debounced-value
 */
export const DEBOUNCE_TIMEOUT = 300;

/**
 * Get the default fetch headers
 */
export const FETCH_HEADERS = () => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
};

export const DAYS_OF_WEEK = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "0",
  },
];

export const NOTIFICATION_SETTING_EVENTS = [
  { label: "New request", value: "new_request" },
  { label: "Updated request", value: "updated_request" },
  { label: "Approved request", value: "approved_request" },
  { label: "Rejected request", value: "rejected_request" },
  { label: "Finished request", value: "finished_request" },
  { label: "Cancelled request", value: "cancelled_request" },
];

export const NOTIFICATION_SETTING_METHODS = [
  { label: "SMS", value: "sms" },
  { label: "E-mail", value: "mail" },
];

export const NOTIFICATION_SETTINGS = [
  "mail_host",
  "mail_port",
  "mail_username",
  "mail_password",
  "mail_encryption",
  "mail_from_address",
  "mail_from_name",
];

export const CONTACT_PROPERTIES = [
  "name",
  "location",
  "main_phone",
  "main_mail",
  "helpdesk_phone",
  "helpdesk_mail",
];

export const BUNKER_REQUESTS_STATUSES = [
  { label: "Pending", value: "pending" },
  { label: "Rejected", value: "rejected" },
  { label: "Approved", value: "approved" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Completed", value: "completed" },
];
