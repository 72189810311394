import { Tabs } from "@mantine/core";
import { User, Settings as SettingsIcon, Briefcase } from "tabler-icons-react";
import AccountSettingsTab from "../../components/tabs/AccountSettingsTab";
import ChangePasswordTab from "../../components/tabs/ChangePasswordTab";
import ApplicationSettingsTab from "../../components/tabs/ApplicationSettingsTab";

export default function Settings() {
  return (
    <Tabs>
      <Tabs.Tab
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        })}
        label="Account"
        icon={<User size={20} />}
      >
        <AccountSettingsTab />
      </Tabs.Tab>
      <Tabs.Tab
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        })}
        label="Password"
        icon={<Briefcase size={20} />}
      >
        <ChangePasswordTab />
      </Tabs.Tab>
      <Tabs.Tab
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        })}
        label="Application"
        icon={<SettingsIcon size={20} />}
      >
        <ApplicationSettingsTab />
      </Tabs.Tab>
    </Tabs>
  );
}
