import React from "react";
import {PatchableUser, User} from "../../../../types";
import UserForm from "./UserForm";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useMutateUserData from "../../../hooks/user/useMutateUserData";
import {createUser} from "../../../api/users";

type CreateUserFormProps = {
    onClose: () => void;
}

function CreateUserForm(props: CreateUserFormProps) {
    const { onClose } = props;

    const onSuccess = (values: User) => {
        showNotification({
            title: 'User created',
            icon: <Check/>,
            message: `User '${values.name}' has been created!`,
        })

        onClose()
    }

    const onError = (error: string) => {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while creating the user. ${error}`,
            color: 'red'
        })
    }

    const {mutate, isLoading} = useMutateUserData(createUser, onSuccess, onError);

    const onSubmit = (values: PatchableUser) => {
        mutate(values)
    }

    return (
        <>
            <UserForm
                isLoading={isLoading}
                submitAction={(values) => onSubmit(values)}
            />
        </>
    );
}

export default CreateUserForm;
