import {Location, PatchableLocation} from "../../../../types";
import LocationForm from "./LocationForm";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useMutateLocationData from "../../../hooks/location/useMutateLocationData";
import {createLocation} from "../../../api/locations";

type CreateLocationFormProps = {
    onClose: () => void;
}

function CreateLocationForm(props: CreateLocationFormProps) {
    const { onClose } = props;

    const onSuccess = (values: Location) => {
        showNotification({
            title: 'Location created',
            icon: <Check/>,
            message: `Location '${values.name}' has been created!`,
        })

        onClose()
    }

    const onError = (error: string) => {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while creating the location. ${error}`,
            color: 'red'
        })
    }

    const {mutate, isLoading} = useMutateLocationData(createLocation, onSuccess, onError);

    const onSubmit = (values: PatchableLocation) => {
        mutate(values)
    }

    return (
        <>
            <LocationForm
                isLoading={isLoading}
                submitAction={(values) => onSubmit(values)}
            />
        </>
    );
}

export default CreateLocationForm;
