import {useQuery} from 'react-query'
import {fetchSettings} from "../../api/emailSettings";

export default function useEmailSettingsData(onSuccess?: any, onError?: any) {
    return useQuery(['emailSettings'], () => fetchSettings(),
        {
            onSuccess,
            onError,
            keepPreviousData: false,
        }
    );
}
