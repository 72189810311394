import {useModals} from "@mantine/modals";
import EditRoleForm from "../../components/forms/role/EditRoleForm";

export default function useEditRole() {
    const modals = useModals();

    const openModal = function(roleId: string) {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Edit role',
            closeOnClickOutside: false,
            children: (
                <>
                    <EditRoleForm onClose={() => closeModal(modalId)} roleId={roleId} />
                </>
            ),
        });
    }

    const closeModal = function(modalId: string) {
        modals.closeModal(modalId);
    }

    return {
        openModal,
    }
}