import { Button, Group, Text, TextInput } from "@mantine/core";
import { useState } from "react";

type CompleteBunkerRequestFormProps = {
  onClose: () => void;
  onSubmit: (extraTime: number) => void;
};

function CompleteBunkerRequestForm(props: CompleteBunkerRequestFormProps) {
  const { onClose, onSubmit } = props;
  const [extraTime, setExtraTime] = useState<number>(0);

  return (
    <>
      <Text
        size="sm"
        pb={"sm"}
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        })}
      >
        You are about to complete a bunker request. The customer will be
        informed of this. Are you sure?
      </Text>
      <TextInput
        required
        pb={"sm"}
        type={"number"}
        step={0.01}
        min={0}
        label="Extra time"
        description={"Extra time in quarters of an hour"}
        value={extraTime}
        onChange={(evt: any) => setExtraTime(evt.currentTarget.value)}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          description: {
            color: "#8C9196",
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />
      <Group grow>
        <Button onClick={() => onClose()}>Go back</Button>
        <Button onClick={() => onSubmit(extraTime)} color="custom-red">
          Complete
        </Button>
      </Group>
    </>
  );
}

export default CompleteBunkerRequestForm;
