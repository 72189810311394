import React, { ReactNode } from "react";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  Global,
  MantineTheme,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { useLocalStorage } from "@mantine/hooks";
import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationsProvider } from "@mantine/notifications";
import SearchProvider from "./search";
import UserProvider from "./user";
import NavbarProvider from "./navbar";
import { MapProvider } from "react-map-gl";
import FancyTableProvider from "./fancyTable";

type ProvidersProps = {
  children?: ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

export default function Providers(props: ProvidersProps) {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: false,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={{
            fontFamily: "Lato, sans-serif",
            headings: {
              fontFamily: "Lato, sans-serif",
            },
            colors: {
              "ocean-blue": [
                "#3FAACE",
                "#5FCCDB",
                "#44CADC",
                "#2AC9DE",
                "#1AC2D9",
                "#11B7CD",
                "#3FAACE",
                "#3FAACE",
                "#128797",
                "#147885",
              ],
              "bright-pink": [
                "#E50069",
                "#ED9BCF",
                "#EC7CC3",
                "#ED5DB8",
                "#F13EAF",
                "#F71FA7",
                "#E50069",
                "#E00890",
                "#C50E82",
                "#AD1374",
              ],
              "bright-green": [
                "#289323",
                "#7DCB7D",
                "#5EBF5E",
                "#3EB23E",
                "#1FA51F",
                "#009900",
                "#289323",
                "#1C7B18",
                "#007700",
                "#006600",
              ],
              "custom-gray": [
                "#484948",
                "#F8F9FA",
                "#F0F0F0",
                "#F5F5F5",
                "#FCFCFC",
                "#F0F0F0",
                "#DEE2E5",
                "#D0D4D9",
                "#B0B5BB",
                "#8C9196",
              ],
              "custom-red": [
                "#C5043F",
                "#F0A3B1",
                "#E57F9A",
                "#E05B84",
                "#DA376E",
                "#D51358",
                "#C5043F",
                "#A70637",
                "#A80336",
                "#8B022E",
              ],
              "custom-green": [
                "#69B365",
                "#69B365",
                "#69B365",
                "#69B365",
                "#69B365",
                "#69B365",
                "#69B365",
                "#69B365",
                "#69B365",
                "#69B365",
              ],
            },
            primaryColor: "bright-green",
            colorScheme,
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <Global
            styles={(theme: MantineTheme) => ({
              body: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : "#F5F5F5",
                color: "#484948",
              },
            })}
          />
          <MapProvider>
            <NavbarProvider>
              <UserProvider>
                <NotificationsProvider>
                  <ModalsProvider>
                    <SearchProvider>
                      <FancyTableProvider>{props.children}</FancyTableProvider>
                    </SearchProvider>
                  </ModalsProvider>
                </NotificationsProvider>
              </UserProvider>
            </NavbarProvider>
          </MapProvider>
        </MantineProvider>
      </QueryClientProvider>
    </ColorSchemeProvider>
  );
}
