import {Center, Loader} from "@mantine/core";
import ContactPropertyForm from "../forms/admin/ContactPropertyForm";
import React from "react";
import useContactPropertyData from "../../hooks/admin/useContactPropertyData";
import useMutateContactPropertyData from "../../hooks/admin/useMutateContactPropertyData";
import {updateContactProperty} from "../../api/contactProperties";

export default function ContactSettingsTab() {
    const {data: contactPropertyData, isLoading: isLoadingContactData} = useContactPropertyData();
    const {
        mutate: mutateContactProperties,
        isLoading: isMutatingContactData
    } = useMutateContactPropertyData(updateContactProperty);

    return isLoadingContactData ? (
        <Center>
            <Loader my={"5rem"}/>
        </Center>
    ) : (
        <ContactPropertyForm
            contactProperties={contactPropertyData?.data}
            loading={isMutatingContactData}
            onSubmit={mutateContactProperties}
        />
    );
}