import {Group} from "@mantine/core";
import React from "react";
import UserMenu from "./_userMenu";
import {UserButton} from "./_userButton";

export default function User() {
    return (
        <>
            <Group>
                <UserMenu
                    withArrow
                    placement={"end"}
                    position={"bottom"}
                    control={<UserButton />}
                />
            </Group>
        </>
    );
}