import {useMutation, useQueryClient} from 'react-query'
import {Role} from "../../../types";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import {useContext} from "react";
import {UserContext} from "../../providers/user";

const onMutateSuccess = (values: Role) => {
    showNotification({
        title: 'Role updated',
        icon: <Check/>,
        message: `Role '${values.name}' has been updated!`,
    })
}

const onMutateError = (error: string) => {
    showNotification({
        title: `Something went wrong`,
        message: `An error occurred while updating the role. ${error}`,
        color: 'red'
    })
}

export default function useMutateRoleData(mutationFn: (args: any) => Promise<unknown>, onSuccess: any = onMutateSuccess, onError: any = onMutateError) {
    const queryClient = useQueryClient();
    const {refetch} = useContext(UserContext);

    return useMutation(mutationFn, {
        onSuccess: (data) => {
            // Reload role data
            queryClient.invalidateQueries(['roles']);

            // Reload the permissions of the current user, it might be possible that the user has changed permissions that are applicable to himself.
            refetch();

            onSuccess?.(data);
        },
        onError: (data) => onError?.(data)
    });
}
