import {UserSettings} from "../UserSettings";
import {useMantineColorScheme} from "@mantine/core";

export default function ApplicationSettingsTab() {
    const {colorScheme, toggleColorScheme} = useMantineColorScheme();

    return (
        <UserSettings
            title={"Application settings"}
            data={[{
                title: "Dark mode",
                description: "Enable or disable dark mode.",
                initialValue: colorScheme === "dark",
                onChange: () => toggleColorScheme()
            }]}
        />
    );
}