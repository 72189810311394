import {Button} from "@mantine/core";
import useCreateRole from "../../hooks/role/useCreateRole";
import {useContext} from "react";
import {UserContext} from "../../providers/user";

export default function CreateRoleButton() {
    const {openModal} = useCreateRole();
    const {isAuthorized} = useContext(UserContext);

    return (
        <>
            {isAuthorized('role_store') && <Button onClick={() => openModal()}>Create role</Button>}
        </>
    );
}