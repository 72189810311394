import {Anchor, Center, Container, Group, LoadingOverlay, Paper, Text} from '@mantine/core';
import useEmailVerify from "../hooks/useEmailVerify";
import VerifyEmailForm from "../components/forms/VerifyEmailForm";
import {ArrowNarrowLeft} from "tabler-icons-react";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Brand} from "../components/AppShell/_brand";

function VerifyEmail() {
    const { loading, setFormData, onSubmit } = useEmailVerify();
    const navigate = useNavigate();

    return (
        <Container size={420} my={40}>
            <Paper withBorder shadow="md" radius="md" p="xl">
                <Center mb={"md"}>
                    <Brand/>
                </Center>

                <div style={{position: "relative"}}>
                    <LoadingOverlay visible={loading} />
                    <VerifyEmailForm submitText={"Set password"} onSubmit={onSubmit} setValues={setFormData}/>
                </div>

                <Anchor inline onClick={() => navigate("/login")}>
                    <Group>
                        <ArrowNarrowLeft/>
                        <Text>Login</Text>
                    </Group>
                </Anchor>
            </Paper>
        </Container>
    );
}

export default VerifyEmail;