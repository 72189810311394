import {
  Button,
  createStyles,
  Group,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableShip, Ship } from "../../../../types";
import { validateNumber, validateString } from "../../../validator";
import { CustomerSelect } from "../../selects/CustomerSelect";
import { Phone } from "tabler-icons-react";

type Props = {
  ship?: Ship;
  submitAction: (values: PatchableShip) => void;
  submitText?: string;
  isLoading?: boolean;
};

const useStyles = createStyles((theme, _params, getRef) => ({
  group: {
    alignItems: "flex-start",
  },
}));

export default function ShipForm(props: Props) {
  const { ship, submitText, submitAction, isLoading } = props;
  const { classes } = useStyles();

  const form = useForm<PatchableShip>({
    initialValues: {
      id: ship?.id ?? "",
      name: ship?.name ?? "",
      imo_number: ship?.imo_number ?? undefined,
      phone: ship?.phone ?? "",
      storage_size: ship?.storage_size ?? undefined,
      customer_id: ship?.customer_id ?? "",
    },
    validate: {
      name: (value) => validateString(value, "Name"),
      imo_number: (value) => validateString(value, "Identification number"),
      phone: (value) => validateString(value, "Ship phone number"),
      storage_size: (value) => validateNumber(value, "storage size"),
      customer_id: (value) => validateString(value, "A valid customer"),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submitAction)}>
      <TextInput
        pb={"sm"}
        required
        placeholder="Ship name"
        label="Name"
        {...form.getInputProps("name")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          placeholder="0123456"
          label="Identification number"
          description="E.g. IMO number"
          {...form.getInputProps("imo_number")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />

        <></>
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <NumberInput
          required
          hideControls
          placeholder="2000"
          label="Storage size"
          description="Storage size in kilograms"
          {...form.getInputProps("storage_size")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />

        <></>
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          label="Phone number ship"
          placeholder={"+31 528 123456"}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
          icon={<Phone size={16} />}
        />

        <CustomerSelect
          error={form.errors?.customer_id}
          required
          defaultValue={!ship?.customer?.deleted_at ? ship?.customer?.name : ""}
          onClear={() => {
            form.setFieldValue("customer_id", "");
          }}
          onItemSubmit={(value) => {
            form.setFieldValue("customer_id", value.customer.id);
          }}
        />
      </Group>

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Save"}
        </Button>
      </Group>
    </form>
  );
}
