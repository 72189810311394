import React, {useEffect, useState} from 'react';
import {Button, Group, Title} from "@mantine/core";
import BunkerRequestsTable from "../../components/tables/BunkerRequestTable";
import CreateBunkerRequestButton from "../../components/buttons/CreateBunkerRequestButton";
import {Calendar, Filter} from "tabler-icons-react";
import FilterBunkerRequestsCollapse from "../../components/collapses/FilterBunkerRequestsCollapse";
import {TableDataFilter} from "../../../types";
import {exportBunkerRequestData} from "../../api/bunkerRequests";
import useExportTableData from "../../hooks/useExportTableData";
import {Link, useLocation, useNavigate} from "react-router-dom";

export default function BunkerRequests() {
    const { state, pathname }: any = useLocation();
    const navigate = useNavigate()
    const initialFilters = state?.filters;
    const [displayFilters, setDisplayFilters] = useState(initialFilters ?? undefined);

    const [filters, setFilters] = useState<TableDataFilter[]>();
    const {exportTable, isLoading} = useExportTableData({exportFunction: exportBunkerRequestData});

    useEffect(() => {
        navigate(pathname, {});
    }, []);

    return (
        <>
            <Group position={"apart"}>
                <Title>Bunker requests</Title>

                <Group>
                    <Button
                        pl={10}
                        pr={10}
                        onClick={() => setDisplayFilters(!displayFilters)}
                    >
                        <Filter/>
                    </Button>

                    <Button
                        component={Link}
                        to={"/requests/calendar"}
                        pl={10}
                        pr={10}
                    >
                        <Calendar/>
                    </Button>

                    <Button loading={isLoading} onClick={() => exportTable(filters)}>Export data</Button>

                    <CreateBunkerRequestButton/>
                </Group>
            </Group>

            <FilterBunkerRequestsCollapse initialValues={initialFilters} isOpened={displayFilters} setFilterQuery={setFilters}/>
            <BunkerRequestsTable filters={filters}/>
        </>
    )
}