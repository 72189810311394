import React from 'react';
import {Group, Title} from "@mantine/core";
import UsersTable from "../../components/tables/UserTable";
import CreateUserButton from "../../components/buttons/CreateUserButton";

export default function Users() {
    return (
        <>
            <Group position={"apart"}>
                <Title>Users</Title>
                <CreateUserButton />
            </Group>

            <UsersTable />
        </>
    )
}