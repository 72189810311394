import {useMutation, useQueryClient} from 'react-query'
import {Customer} from "../../../types";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

const onMutateSuccess = (values: Customer) => {
    showNotification({
        title: 'Customer updated',
        icon: <Check/>,
        message: `Customer '${values.name}' has been updated!`,
    })
}

const onMutateError = (error: string) => {
    showNotification({
        title: `Something went wrong`,
        message: `An error occurred while updating the customer. ${error}`,
        color: 'red'
    })
}

export default function useMutateCustomerData(mutationFn: (args: any) => Promise<unknown>, onSuccess: any = onMutateSuccess, onError: any = onMutateError) {
    const queryClient = useQueryClient();
    return useMutation(mutationFn, {
        onSuccess: (data) => {
            // Reload customer data
            queryClient.invalidateQueries(['customers']);

            onSuccess?.(data)
        },
        onError: (data) => onError?.(data)
    });
}
