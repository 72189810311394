/**
 * Get a environment variable.
 */
export function env(name: string, defaultValue: string = "") {
    return process.env[`REACT_APP_${name}`] || defaultValue;
}

/**
 * Get the default fetch headers.
 */
export const FETCH_HEADERS = () => {
    return {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
        },
    };
};

/**
 * Test if localstorage has a token.
 */
export function hasAuthToken() {
    return Boolean(getAuthToken());
}

/**
 * Test if localstorage has a token.
 */
export function getAuthToken() {
    return localStorage.getItem("token");
}

/**
 * Set the authentication token in the local storage.
 */
export function setAuthToken(token: string) {
    localStorage.setItem("token", token);
}

/**
 * Remove authorization token from the local storage.
 */
export function removeAuthToken(): void {
    localStorage.removeItem('token');
}