import { showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";
import { TimeSlotException } from "../../../../types";
import { createTimeSlotException } from "../../../api/timeSlotExceptions";
import useMutateTimeSlotExceptionData from "../../../hooks/timeSlotsExceptions/useMutateTimeSlotExceptionData";
import TimeSlotExceptionForm from "./TimeSlotExceptionForm";

type Props = {
  date?: Date;
  location_id: string;
  onClose: () => void;
};

export default function CreateTimeSlotExceptionForm(props: Props) {
  const { date, location_id, onClose } = props;

  const onSuccess = function (value: TimeSlotException) {
    showNotification({
      title: "Time slot exception added",
      icon: <Check />,
      message: `Time slot exception '${value.description}' has been added!`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: "Something went wrong",
      message: `An error occurred while adding the time slot exception. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateTimeSlotExceptionData(
    createTimeSlotException,
    onSuccess,
    onError
  );

  const onSubmit = async function (value: TimeSlotException) {
    mutate(value);
  };

  return (
    <TimeSlotExceptionForm
      onSubmit={(values) => onSubmit(values)}
      date={date}
      location_id={location_id}
      isLoading={isLoading}
    />
  );
}
