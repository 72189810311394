import {Group, Title} from "@mantine/core";
import RoleTable from "../../components/tables/RoleTable";
import CreateRoleButton from "../../components/buttons/CreateRoleButton";

export default function Roles() {
    return (
        <>
            <Group position="apart">
                <Title>Roles</Title>
                <CreateRoleButton />
            </Group>

            <RoleTable />
        </>
    );
}