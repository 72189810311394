import dayjs from "dayjs";
import { convertDateToTime, handleRequestErrors } from "../../tools";
import { TimeSlotException } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";

export const fetchTimeSlotExceptions = async (locationId: string) => {
  return await fetch(`${API_URL}/time-slot-exception?location_id=${locationId}`, {
    ...FETCH_HEADERS(),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
};

export const fetchTimeSlotException = async (id: string) => {
  return await fetch(`${API_URL}/time-slot-exception/${id}`, {
    ...FETCH_HEADERS(),
  })
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export async function createTimeSlotException(timeSlotException: TimeSlotException) {
  return await fetch(`${API_URL}/time-slot-exception`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify({
      ...timeSlotException,

      // Overwrite the date in the correct format.
      date: dayjs(timeSlotException.date).format("YYYY-MM-DD"),
      start_time: convertDateToTime(timeSlotException.start_time),
      end_time: convertDateToTime(timeSlotException.end_time),
    }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

export async function updateTimeSlotException(timeSlotException: TimeSlotException) {
  return await fetch(`${API_URL}/time-slot-exception/${timeSlotException.id}`, {
    ...FETCH_HEADERS(),
    method: "PUT",
    body: JSON.stringify({
      ...timeSlotException,

      // Overwrite the date in the correct format.
      date: dayjs(timeSlotException.date).format("YYYY-MM-DD"),
      start_time: convertDateToTime(timeSlotException.start_time),
      end_time: convertDateToTime(timeSlotException.end_time),
    }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

export async function deleteTimeSlotException(id: string) {
  return await fetch(`${API_URL}/time-slot-exception/${id}`, {
    ...FETCH_HEADERS(),
    method: "DELETE",
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
}
