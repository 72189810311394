import {
  generateFilterString,
  generateSortString,
  getAuthToken,
  handleRequestErrors,
} from "../../tools";
import { API_URL, FETCH_HEADERS } from "../constants";
import { SortingRule } from "react-table";
import dayjs from "dayjs";
import { BunkerRequest, TableDataFilter } from "../../types";
import { saveAs } from "file-saver";

export const fetchBunkerRequests = async (
  page: number,
  pageSize: number,
  sortByData: SortingRule<object>[] = [],
  query: string = "",
  filters: TableDataFilter[] = []
) => {
  let sortByString = generateSortString(sortByData);
  let shipId = filters.find((i) => i.column === "shipId")?.value ?? "";
  let filterString = generateFilterString(filters.filter((i) => i.column !== "shipId"));

  return await fetch(
    `${API_URL}/bunker-request?page=${
      page + 1
    }&limit=${pageSize}${sortByString}${filterString}&query=${query}&ship_id=${shipId}`,
    {
      ...FETCH_HEADERS(),
    }
  )
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
};

export const fetchBunkerRequestsCalendar = async (year: number) => {
  return await fetch(`${API_URL}/bunker-request/calendar?year=${year}`, {
    ...FETCH_HEADERS(),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
};

export const fetchBunkerRequest = async (id?: string) => {
  return await fetch(`${API_URL}/bunker-request/${id}`, {
    ...FETCH_HEADERS(),
  })
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export async function createBunkerRequest(bunkerRequest: BunkerRequest) {
  return await fetch(`${API_URL}/bunker-request`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify({
      ...bunkerRequest,
      date: dayjs(bunkerRequest.date).format("YYYY-MM-DD"),
    }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

export async function updateBunkerRequest(bunkerRequest: BunkerRequest) {
  return await fetch(`${API_URL}/bunker-request/${bunkerRequest.id}`, {
    ...FETCH_HEADERS(),
    method: "PUT",
    body: JSON.stringify({
      ...bunkerRequest,
      date: dayjs(bunkerRequest.date).format("YYYY-MM-DD"),
    }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

type DeleteBunkerRequestProps = {
  id: string | number;
  reason?: string;
};

export async function deleteBunkerRequest(props: DeleteBunkerRequestProps) {
  return await fetch(`${API_URL}/bunker-request/${props.id}`, {
    ...FETCH_HEADERS(),
    method: "DELETE",
    body: JSON.stringify({
      reason: props.reason,
    }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

export async function completeBunkerRequest({
  id,
  extraTime = 0,
}: {
  id: string;
  extraTime?: number;
}) {
  return await fetch(`${API_URL}/bunker-request/${id}/complete`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify({
      extra_time: extraTime,
    }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

type ProcessBunkerRequestRequest = {
  id: string;
  is_approved: boolean;
};

export async function processBunkerRequest(data: ProcessBunkerRequestRequest) {
  return await fetch(`${API_URL}/bunker-request/${data.id}/process`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify({
      is_approved: data.is_approved,
    }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
}

type UploadAttachmentToBunkerRequestRequest = {
  id: string;
  files: any;
};

export async function uploadAttachmentToBunkerRequest(
  data: UploadAttachmentToBunkerRequestRequest
) {
  const body = new FormData();

  for (const file of data.files) {
    body.append("files[]", file, file.name);
  }

  return await fetch(`${API_URL}/bunker-request/${data.id}/attachment`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: body,
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
}

export const exportBunkerRequestData = async (
  sortByData: SortingRule<object>[],
  query: string = "",
  filters: TableDataFilter[] = []
) => {
  let sortByString = generateSortString(sortByData);
  let shipId = filters.find((i) => i.column === "shipId")?.value ?? "";
  let filterString = generateFilterString(filters.filter((i) => i.column !== "shipId"));

  return await fetch(
    `${API_URL}/export/bunker-request?query=${query}${sortByString}${filterString}&ship_id=${shipId}`,
    {
      ...FETCH_HEADERS(),
    }
  )
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, "bunker_requests.csv"))
    .catch((error) => {
      throw error.message;
    });
};
