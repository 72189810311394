import { useForm } from "@mantine/form";
import { ContactProperties, ContactProperty } from "../../../../types";
import { Button, TextInput } from "@mantine/core";
import { CONTACT_PROPERTIES } from "../../../constants";
import { validateString } from "../../../validator";

type ContactPropertyFormProps = {
  contactProperties?: ContactProperty[];
  onSubmit: (values: ContactProperties) => void;
  loading: boolean;
};

export default function ContactPropertyForm(props: ContactPropertyFormProps) {
  const { contactProperties, onSubmit, loading } = props;

  const form = useForm<ContactProperties>({
    initialValues: {
      name:
        contactProperties?.find((item) => item.name === "name")?.value ?? "",
      location:
        contactProperties?.find((item) => item.name === "location")?.value ??
        "",
      main_phone:
        contactProperties?.find((item) => item.name === "main_phone")?.value ??
        "",
      main_mail:
        contactProperties?.find((item) => item.name === "main_mail")?.value ??
        "",
      helpdesk_phone:
        contactProperties?.find((item) => item.name === "helpdesk_phone")
          ?.value ?? "",
      helpdesk_mail:
        contactProperties?.find((item) => item.name === "helpdesk_mail")
          ?.value ?? "",
    },
    validate: {
      name: (value) => validateString(value),
      location: (value) => validateString(value),
      main_phone: (value) => validateString(value),
      main_mail: (value) => validateString(value),
      helpdesk_phone: (value) => validateString(value),
      helpdesk_mail: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      {CONTACT_PROPERTIES.map((item, key) => (
        <TextInput
          key={key}
          label={item}
          mb="sm"
          required
          {...form.getInputProps(item as keyof ContactProperties)}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            icon: {
              color: "#d5d9de",
            },
            required: {
              color: "#004F65",
            },
          })}
        />
      ))}

      <Button type="submit" loading={loading}>
        Save
      </Button>
    </form>
  );
}
