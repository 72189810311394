import { Button, Group, Select, Text, Textarea } from "@mantine/core";
import { useState } from "react";

type CancelBunkerRequestFormProps = {
  onClose: () => void;
  onSubmit: (reason: string) => void;
};

function CancelBunkerRequestForm(props: CancelBunkerRequestFormProps) {
  const { onClose, onSubmit } = props;
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [reason, setReason] = useState<string>("");

  const defaultOptions = [
    "Technical issues with the barges",
    "Client requested a change in planning",
    "Delay of the ship",
    "Other",
  ];

  const onSelectChange = (value: string | null) => {
    setSelectValue(value);
    value != "Other" && setReason(value ?? "");
  };

  return (
    <>
      <Text
        size="sm"
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        })}
      >
        You are about to cancel a bunker request. The customer will be informed
        of this. Are you sure?
      </Text>
      <Select
        label="Reason"
        placeholder="Cancellation reason"
        mb="md"
        data={defaultOptions.map((item) => ({ value: item, label: item }))}
        value={selectValue}
        onChange={onSelectChange}
        styles={(theme) => ({
          item: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />
      {selectValue == "Other" && (
        <Textarea
          mb="md"
          placeholder="The reason the customer wants to cancel the bunker request"
          value={reason}
          onChange={(evt: any) => setReason(evt.currentTarget.value)}
          autoFocus
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      )}
      <Group grow>
        <Button onClick={() => onClose()}>Go back</Button>
        <Button onClick={() => onSubmit(reason)} color="custom-red">
          Cancel bunker request
        </Button>
      </Group>
    </>
  );
}

export default CancelBunkerRequestForm;
