import { Button, Group, Select, TextInput, createStyles } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { convertTimeToDate } from "../../../../tools";
import { PatchableTimeSlot, TimeSlot } from "../../../../types";
import { DAYS_OF_WEEK } from "../../../constants";
import {
  validateDate,
  validateNumber,
  validateString,
} from "../../../validator";
import { LocationSelect } from "../../selects/LocationSelect";

type TimeSlotFormProps = {
  locationId?: string;
  timeSlot?: TimeSlot;
  submitText?: string;
  submitAction: (values: PatchableTimeSlot) => void;
  isLoading?: boolean;
};

const useStyles = createStyles((theme, _params, getRef) => ({
  group: {
    alignItems: "flex-start",
  },
}));

function TimeSlotForm(props: TimeSlotFormProps) {
  const {
    locationId: locationIdProp = undefined,
    timeSlot,
    submitText,
    submitAction,
    isLoading = false,
  } = props;
  const { classes } = useStyles();
  const [locationId, setLocationId] = useState<string | undefined>(
    timeSlot ? timeSlot && timeSlot.location?.id : locationIdProp
  );

  const form = useForm<PatchableTimeSlot>({
    initialValues: {
      id: timeSlot?.id ?? "",
      day: timeSlot?.day?.toString() ?? null,
      limit: timeSlot?.limit ?? "",
      start_time: convertTimeToDate(timeSlot?.start_time, null),
      end_time: convertTimeToDate(timeSlot?.end_time, null),
      location_id: locationId ?? "",
    },

    validate: {
      day: (value) => validateNumber(value, "Day", true, 0, 6),
      limit: (value) => validateNumber(value),
      start_time: (value) => validateDate(value, "This field"),
      end_time: (value) => validateDate(value, "This field"),
      location_id: (value) => validateString(value),
    },
  });

  useEffect(() => {
    form.setFieldValue("location_id", locationId ?? "");
  }, [locationId]);

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <Select
        pb={"sm"}
        required
        label="Day"
        description={"Day of week"}
        data={DAYS_OF_WEEK}
        {...form.getInputProps("day")}
        styles={(theme) => ({
          item: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <TextInput
        pb={"sm"}
        type={"number"}
        required
        min={1}
        label="Amount of bunkerings (limit)"
        {...form.getInputProps("limit")}
        styles={(theme) => ({
          item: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <Group className={classes.group} grow pb={"sm"}>
        <TimeInput
          label="Start time"
          required
          clearable
          {...form.getInputProps("start_time")}
          styles={(theme) => ({
            item: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />

        <TimeInput
          label="End time"
          required
          clearable
          {...form.getInputProps("end_time")}
          styles={(theme) => ({
            item: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      </Group>

      <LocationSelect
        required
        pb={"sm"}
        defaultValue={locationId}
        onClear={() => setLocationId(undefined)}
        onItemSubmit={(value) => setLocationId(value.location?.id)}
        error={form.errors.location_id}
      />

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Save"}
        </Button>
      </Group>
    </form>
  );
}

export default TimeSlotForm;
