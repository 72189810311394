import {generateSortString, handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import {Role} from "../../types";

export const fetchRoles = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/role?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchRole = async (roleId: string) => {
    return await fetch(`${API_URL}/role/${roleId}`, {
        method: 'GET',
        ...FETCH_HEADERS(),
    })
        .then((data) => data.json())
        .catch((err) => {
            throw err.message
        });
}

export const createRole = async (role: Role) => {
    return await fetch(`${API_URL}/role`, {
        method: 'POST',
        body: JSON.stringify({
            ...role,
            permissions: role?.permissions?.map((item) => item.id),
        }),
        ...FETCH_HEADERS(),
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => data.json())
        .catch((err) => {
            throw err.message
        });
}

export const updateRole = async (role: Role) => {
    return await fetch(`${API_URL}/role/${role.id}`, {
        method: 'PUT',
        body: JSON.stringify({
            ...role,
            permissions: role?.permissions?.map((item) => item.id),
        }),
        ...FETCH_HEADERS(),
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => data.json())
        .catch((err) => {
            throw err.message
        });
}

export const deleteRole = async (roleIds: string[]) => {
    return await fetch(`${API_URL}/role`, {
        method: 'DELETE',
        body: JSON.stringify({
            'roles': roleIds,
        }),
        ...FETCH_HEADERS(),
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => data.json())
        .catch((err) => {
            throw err.message
        });
}