import React, {useEffect, useState} from "react";
import {Center, Loader,} from '@mantine/core';
import {PatchableUser, User} from "../../../../types";
import UserForm from "./UserForm";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useMutateUserData from "../../../hooks/user/useMutateUserData";
import {fetchUser, updateUser} from "../../../api/users";

type EditUserFormProps = {
    userId: string;
    onClose: () => void;
}

function EditUserForm(props: EditUserFormProps) {
    const { userId, onClose } = props;
    const [user, setUser] = useState<User | undefined>();

    const onSuccess = (values: PatchableUser) => {
        showNotification({
            title: 'User updated',
            icon: <Check/>,
            message: `User '${values.name}' has been updated!`,
        })

        onClose()
    }

    const {mutate, isLoading} = useMutateUserData(updateUser, onSuccess);

    const onSubmit = (values: PatchableUser) => {
        mutate(values)
    }

    const onLoad = async () => {
        let data = await fetchUser(userId);
        setUser(data);
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <>
            {!user ?
                <Center>
                    <Loader height={200}/>
                </Center> :
                <UserForm
                    isLoading={isLoading}
                    user={user}
                    submitAction={(values) => onSubmit(values)}
                />
            }
        </>
    );
}

export default EditUserForm;
