import React, {
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  HeaderGroup,
  SortingRule,
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useQuery } from "react-query";
import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import {
  ChevronDown,
  ChevronUp,
  Direction,
  Eye,
  Pencil,
  Trash,
} from "tabler-icons-react";
import ErrorView from "../../pages/ErrorView";
import { TableDataFilter } from "../../../types";
import { SearchContext } from "../../providers/search";
import { FancyTableContext } from "../../providers/fancyTable";

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

// @ts-ignore
const reducer = (state: any, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, style = {}, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          style={{ accentColor: "#1C7B18", ...style }}
          {...rest}
        />
      </>
    );
  }
);

const useStyles = createStyles((theme) => ({
  tableDataCell: {
    whiteSpace: "nowrap",
  },
  paper: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

type FancyTableProps = {
  queryKey: string;
  queryFn: (
    queryPageIndex: number,
    queryPageSize: number,
    sortByState: SortingRule<object>[],
    ...args: any[]
  ) => any;
  queryPageSize?: number;
  columns: any;
  filters?: TableDataFilter[];

  disableSortBy?: boolean;
  disableMultiSelect?: boolean;
  initialSortBy?: SortingRule<object>[];

  onEdit?: (id: string) => void;
  onDelete?: (id: string[], onConfirm?: () => void) => void;
  onDetails?: (id: string) => void;
  additionalActionsRenderer?: (row: any) => ReactNode;
  subComponentRenderer?: (row: any) => ReactNode;
};

function FancyTable(props: FancyTableProps) {
  const { classes, theme } = useStyles();
  const {
    queryKey,
    queryFn,
    queryPageSize: queryPageSizeProps = 10,
    columns,
    filters = [],
    additionalActionsRenderer,
    subComponentRenderer,
    onEdit,
    onDelete,
    onDetails,
    disableSortBy = false,
    disableMultiSelect = false,
    initialSortBy,
  } = props;
  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] = useReducer(
    reducer,
    {
      queryPageIndex: 0,
      queryPageSize: queryPageSizeProps,
      totalCount: null,
    }
  );
  const [sortByState, setSortByState] = useState<SortingRule<object>[]>(
    initialSortBy ?? []
  );

  const { debouncedQuery } = useContext(SearchContext);
  const { setSortingState } = useContext(FancyTableContext);
  const {
    status,
    isError,
    isFetching,
    isSuccess,
    data: originalData,
  } = useQuery(
    [
      queryKey,
      queryPageIndex,
      queryPageSize,
      sortByState,
      debouncedQuery,
      filters,
    ],
    () =>
      queryFn(
        queryPageIndex,
        queryPageSize,
        sortByState,
        debouncedQuery,
        filters
      ),
    { keepPreviousData: true }
  );

  const data = React.useMemo(() => {
    return isSuccess ? originalData : { data: [], meta: { total: 0 } };
  }, [originalData]);

  const isLoading = status === "loading" || isFetching;
  const hasActions =
    onDetails ||
    onEdit ||
    onDelete ||
    additionalActionsRenderer ||
    subComponentRenderer;

  const tableReducer = (newState: any, action: any) => {
    if (action.type === "deselectAllRows") {
      return { ...newState, selectedRowIds: {} };
    }

    return newState;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    selectedFlatRows,
    visibleColumns,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, sortBy, expanded, selectedRowIds },
    dispatch: tableDispatch,
  } = useTable(
    {
      columns,
      data: data.data,
      stateReducer: tableReducer,
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: sortByState,
      },
      // Tell the usePagination hook that we'll handle our own data fetching
      // This means we'll also have to provide our own pageCount.
      manualPagination: true,
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : undefined,

      // Sorting
      manualSortBy: true,
      disableSortBy: disableSortBy,

      // Row checkboxes
      getRowId: (row: any, index) => row.id,
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      onDelete &&
        !disableMultiSelect &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  // This is needed because of a bug with the toggleAllRowsSelected function while using manual (server-side) pagination
  // @see https://github.com/TanStack/table/issues/3142
  const handleDeselectAll = () => {
    tableDispatch({ type: "deselectAllRows" });
  };

  // Update sortBy state and goto page zero on sortBy update
  useEffect(() => {
    setSortByState(sortBy);

    // Set to allow parent components to access the sorting state of the table.
    setSortingState(sortBy);
  }, [sortBy]);

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  useEffect(() => {
    if (data?.meta?.total) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.meta.total,
      });
    }
  }, [data?.meta?.total]);

  const isSortable = (column: HeaderGroup<any>) => {
    return (
      !disableSortBy &&
      column.id !== "selection" &&
      column.id !== "expander" &&
      !column.disableSortBy
    );
  };

  const onBulkDelete = () => {
    onDelete &&
      onDelete(Object.keys(selectedRowIds), () => handleDeselectAll());
  };

  const onDeleteRow = (id: string) => {
    onDelete && onDelete([id], () => handleDeselectAll());
  };

  return (
    <>
      <div style={{ position: "relative", marginBottom: "1rem" }}>
        <LoadingOverlay
          overlayColor={
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0]
          }
          visible={isLoading}
        />
        {isSuccess && (
          <ScrollArea>
            <Table
              striped
              highlightOnHover
              verticalSpacing="md"
              sx={(theme) => ({
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              })}
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup, index) => {
                  const { key, ...restProps } =
                    headerGroup.getHeaderGroupProps();
                  return (
                    index > 0 && (
                      <tr key={key} {...restProps}>
                        {headerGroup.headers.map((column) => {
                          const { key, ...restColumn } = column.getHeaderProps(
                            column.getSortByToggleProps()
                          );
                          return (
                            (column.parent !== undefined ||
                              column.id === "selection" ||
                              column.id === "expander") && (
                              <th key={key} {...restColumn}>
                                <Group
                                  noWrap
                                  sx={(theme) => ({
                                    color:
                                      theme.colorScheme === "dark"
                                        ? theme.colors.dark[0]
                                        : theme.colors["custom-gray"][0],
                                  })}
                                >
                                  <Text
                                    size={"sm"}
                                    styles={{
                                      root: {
                                        whiteSpace: "nowrap",
                                      },
                                    }}
                                  >
                                    {column.render("Header")}
                                  </Text>
                                  {isSortable(column) &&
                                    (column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <ChevronDown />
                                      ) : (
                                        <ChevronUp />
                                      )
                                    ) : (
                                      <Direction />
                                    ))}
                                </Group>
                              </th>
                            )
                          );
                        })}
                        {headerGroup.headers.length > 1 && hasActions && (
                          <th></th>
                        )}
                      </tr>
                    )
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  return (
                    <React.Fragment key={key}>
                      <tr {...restRowProps}>
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();
                          return (
                            <td
                              key={key}
                              {...restCellProps}
                              className={classes.tableDataCell}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        {hasActions && (
                          <td>
                            <Group
                              position="right"
                              noWrap
                              className={classes.paper}
                            >
                              {additionalActionsRenderer &&
                                additionalActionsRenderer(row)}
                              {onDetails && (
                                <Button onClick={() => onDetails(row.id)}>
                                  <Eye />
                                </Button>
                              )}
                              {onEdit && (
                                <Button onClick={() => onEdit(row.id)}>
                                  <Pencil />
                                </Button>
                              )}
                              {onDelete && (
                                <Button
                                  color={"custom-red"}
                                  onClick={() => onDeleteRow(row.id)}
                                >
                                  <Trash />
                                </Button>
                              )}
                              {subComponentRenderer && (
                                <Button
                                  {...row.getToggleRowExpandedProps()}
                                  color={
                                    row.isExpanded
                                      ? "custom-red"
                                      : "bright-green"
                                  }
                                >
                                  {row.isExpanded ? "Close" : "View"} details
                                </Button>
                              )}
                            </Group>
                          </td>
                        )}
                      </tr>
                      {subComponentRenderer && row.isExpanded && (
                        <tr style={{ padding: 0 }}>
                          {subComponentRenderer(row)}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td colSpan={100}>
                    <Group noWrap position={"apart"}>
                      <Group spacing={5}>
                        <Text>
                          Showing {page.length} of {data.meta.total} results
                        </Text>
                        {Object.keys(selectedRowIds).length > 0 && (
                          <Text>
                            ({Object.keys(selectedRowIds).length} selected)
                          </Text>
                        )}
                      </Group>
                      {onDelete && !disableMultiSelect && (
                        <Button
                          disabled={Object.keys(selectedRowIds).length < 2}
                          color={"custom-red"}
                          onClick={onBulkDelete}
                        >
                          Bulk delete
                        </Button>
                      )}
                    </Group>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ScrollArea>
        )}
        {isError && <ErrorView />}
      </div>
      {!isError && (
        <Pagination
          page={pageIndex + 1}
          onChange={(page) => !isLoading && gotoPage(page - 1)}
          total={pageCount}
          styles={(theme) => ({
            item: {
              color: "#484948",
              borderColor: theme.colors["custom-gray"][7],
              "&[data-active]": {
                backgroundColor: "#6AB366",
              },
              "&:hover": {
                borderColor: theme.colors["bright-green"][0],
              },
              "& svg": {
                color: "#6AB366",
                transform: "scale(1.3)",
              },
              "&[disabled] svg": {
                color: theme.colors["custom-gray"][7],
              },
            },
          })}
        />
      )}
    </>
  );
}

export default FancyTable;
