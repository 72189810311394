import {useContext, useState} from "react";
import {FancyTableContext} from "../providers/fancyTable";
import {SearchContext} from "../providers/search";
import {TableDataFilter} from "../../types";
import {SortingRule} from "react-table";

type Props = {
    exportFunction: (sortByData: SortingRule<object>[], query: string, ...args: any[]) => void
}

export default function useExportTableData(props: Props) {
    const {exportFunction} = props;
    const {sortingState} = useContext(FancyTableContext);
    const {query} = useContext(SearchContext);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Export the data of the table.
     * Optional filter(s) can be given.
     * @param filters
     */
    const exportTable = async function(filters?: TableDataFilter[]) {
        setIsLoading(true)
        await exportFunction(sortingState, query, filters);
        setIsLoading(false)
    }

    return {
        exportTable,
        isLoading,
    }
}