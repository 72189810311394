import {useModals} from "@mantine/modals";
import {useNavigate} from "react-router-dom";
import React from "react";
import CreateCustomerForm from "../../components/forms/customer/CreateCustomerForm";

export default function useCreateCustomer(navigateAway: boolean = false) {
    const modals = useModals();
    const navigate = useNavigate();

    const openModal = () => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Create customer',
            closeOnClickOutside: false,
            children: (
                <>
                    <CreateCustomerForm onClose={() => closeModal(modalId)} />
                </>
            ),
        });
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
        navigateAway && navigate(`/customers`)
    }

    return {
        openModal
    }
}
