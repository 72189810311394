import {Button, Group, Title} from "@mantine/core";
import ShipTable from "../../components/tables/ShipTable";
import CreateShipButton from "../../components/buttons/CreateShipButton";
import React from "react";
import useExportTableData from "../../hooks/useExportTableData";
import {exportShipData} from "../../api/ships";

export default function Ships() {
    const {exportTable, isLoading} = useExportTableData({exportFunction: exportShipData});

    return (
        <>
            <Group position="apart">
                <Title>Ships</Title>

                <Group>
                    <Button loading={isLoading} onClick={() => exportTable()}>Export data</Button>
                    <CreateShipButton/>
                </Group>
            </Group>

            <ShipTable />
        </>
    );
}