import {
  Button,
  Collapse,
  Group,
  Title,
  Text,
  useMantineTheme,
} from "@mantine/core";
import FilterStatusMenu from "../menus/FilterStatusMenu";
import { DatePicker } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { BUNKER_REQUESTS_STATUSES } from "../../constants";
import { TableDataFilter } from "../../../types";
import { useMediaQuery } from "@mantine/hooks";
import { ChevronDown, Trash } from "tabler-icons-react";

type FilterBunkerRequestCollapseProps = {
  setFilterQuery: (options: TableDataFilter[]) => void;
  isOpened: boolean;
  initialValues?: {
    selectedStatuses: any[];
    startDate: Date;
    endDate: Date;
  };
};

export default function FilterBunkerRequestsCollapse(
  props: FilterBunkerRequestCollapseProps
) {
  const { setFilterQuery, isOpened, initialValues } = props;
  const theme = useMantineTheme();
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  // Initial state = only hide the completed status.
  const [selectedStatuses, setSelectedStatuses] = useState(
    initialValues?.selectedStatuses ??
      BUNKER_REQUESTS_STATUSES.filter((s) => s.value !== "cancelled").map(
        (value) => value.value
      )
  );
  const [startDate, setStartDate] = useState<Date | null>(
    initialValues?.startDate ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    initialValues?.endDate ?? null
  );

  /**
   * Change the filter query.
   */
  useEffect(() => {
    setFilterQuery([
      {
        column: "status",
        value: selectedStatuses,
      },
      {
        column: "start_date",
        value: startDate?.toDateString() ?? "",
      },
      {
        column: "end_date",
        value: endDate?.toDateString() ?? "",
      },
    ]);
  }, [selectedStatuses, startDate, endDate]);

  const clearFilters = () => {
    setSelectedStatuses(
      BUNKER_REQUESTS_STATUSES.filter((s) => s.value !== "completed").map(
        (value) => value.value
      )
    );
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Collapse in={isOpened}>
      <Group
        mt={"sm"}
        spacing={"sm"}
        direction={smallScreen ? "column" : "row"}
        noWrap
        align={"stretch"}
      >
        <Title order={4} mt={5}>
          Filters
        </Title>

        <Button
          title={"Clear filters"}
          pr={10}
          pl={10}
          variant={"outline"}
          color={"custom-red"}
          onClick={clearFilters}
        >
          <Trash />
        </Button>

        <FilterStatusMenu
          controlButton={
            <Button variant={"outline"} pr={10} pl={10}>
              <Group spacing={5}>
                <Text size={"sm"}>Status</Text>
                <ChevronDown size={16} />
              </Group>
            </Button>
          }
          onChange={setSelectedStatuses}
          initialValues={selectedStatuses}
        />

        <DatePicker
          placeholder={"Start date"}
          value={startDate}
          maxDate={endDate ?? undefined}
          onChange={(date) => setStartDate(date)}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />

        <DatePicker
          placeholder={"End date"}
          value={endDate}
          minDate={startDate ?? undefined}
          onChange={(date) => setEndDate(date)}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      </Group>
    </Collapse>
  );
}
