import React, {useContext} from 'react';
import {Button, Group, Title} from "@mantine/core";
import LocationsTable from "../../components/tables/LocationTable";
import useCreateLocation from "../../hooks/location/useCreateLocation";
import {UserContext} from "../../providers/user";
import useExportTableData from "../../hooks/useExportTableData";
import {exportLocationData} from "../../api/locations";

export default function Locations() {
    const {openModal} = useCreateLocation();
    const {isAuthorized} = useContext(UserContext);
    const {exportTable, isLoading} = useExportTableData({exportFunction: exportLocationData});

    return (
        <>
            <Group position={"apart"}>
                <Title>Locations</Title>

                <Group>
                    <Button loading={isLoading} onClick={() => exportTable()}>Export data</Button>
                    {isAuthorized(['location_store']) && <Button onClick={openModal}>Create location</Button>}
                </Group>
            </Group>

            <LocationsTable/>
        </>
    )
}