import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {FullPageSpinner} from "../../components/FullPageSpinner";
import {Anchor, Group, Title, useMantineTheme} from "@mantine/core";
import LocationOptionsMenuButton from "../../components/buttons/LocationOptionsMenuButton";
import TimeSlotTable from "../../components/tables/TimeSlotTable";
import TimeSlotExceptionsCalendar from "../../components/calendars/TimeSlotExceptionsCalendar";
import useDeleteTimeSlot from "../../hooks/timeSlot/useDeleteTimeSlot";
import useLocationData from "../../hooks/location/useLocationData";
import LocationDetailTable from "../../components/tables/LocationDetailTable";
import {UserContext} from "../../providers/user";
import CreateTimeSlotButton from "../../components/buttons/CreateTimeSlotButton";

export default function LocationDetail() {
    const {id} = useParams();
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const {openModal: openDeleteModal} = useDeleteTimeSlot();
    const {data, isFetching, isRefetching} = useLocationData(id);
    const {isAuthorized} = useContext(UserContext);

    useEffect(() => {
        if (isFetching) {
            return;
        }

        if (data === undefined) {
            navigate('/locations');
            return;
        }
    }, [isFetching])

    return (
        <div style={{position: "relative", height: "100%", width: "100%"}}>
            {!data?.data || isRefetching ? (
                <FullPageSpinner overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]} />
            ) : (
                <>
                    <Anchor onClick={() => navigate('/locations')}>Return to the overview</Anchor>

                    <Group position="apart">
                        <Title>Location {data.data.name}</Title>

                        {isAuthorized(['notification_setting_update', 'location_update', 'location_destroy'])
                            && (
                                <Group>
                                    <LocationOptionsMenuButton id={data.data.id} />
                                </Group>
                            )
                        }

                    </Group>

                    <LocationDetailTable location={data.data}/>

                    <Group direction="column" grow mt={"md"}>
                        <Group position={"apart"}>
                            <Title order={2}>Time slots</Title>

                            <CreateTimeSlotButton />
                        </Group>

                        <TimeSlotTable
                            locationId={data.data.id}
                            onDelete={isAuthorized(['time_slot_destroy']) ? openDeleteModal : undefined}
                        />
                    </Group>

                    <Group mt={"md"} direction="column" grow>
                        <Title order={2}>Time slot exceptions</Title>
                        <TimeSlotExceptionsCalendar locationId={data.data.id}/>
                    </Group>
                </>
            )}
        </div>
    )
}
