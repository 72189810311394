import React, {useState} from "react";
import {showNotification} from "@mantine/notifications";
import {getAuthToken} from "../tools";
import {formatErrors} from "../../tools";

type Props = {
    url: string;
    options?: Object;
    skip?: boolean;
}

/**
 * Wrapper hook for the fetch API
 */
export default function useLazyFetch(props: Props) {
    const [response, setResponse] = useState<any>();
    const [errors, setErrors] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { url, options, skip } = props;

    const newOptions = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
        },
        ...options,
    };

    /**
     * Execute fetch
     */
    const doFetch = async () => {
        skip && setLoading(false);
        if (!skip) {
            setLoading(true);
            try {
                const res = await fetch(url, newOptions);
                const json = await res.json();
                json.errors && setErrors(formatErrors(json));
                json.error && setErrors([json.error]);
                setResponse(json);
                setLoading(false);
                return json;
            } catch (err) {
                showNotification({
                    title: `Something went wrong`,
                    message: `An unknown error occurred.`,
                    color: 'red'
                })

                setLoading(false);
            }
        }
    };

    return {
        response,
        errors,
        loading,
        doFetch,
    };
}
