import {Center, Loader} from "@mantine/core";
import SettingsForm from "../forms/admin/SettingsForm";
import React from "react";
import useEmailSettingsData from "../../hooks/admin/useEmailSettingsData";
import useMutateEmailSettingsData from "../../hooks/admin/useMutateEmailSettingsData";
import {updateSettings} from "../../api/emailSettings";

export default function EmailSettingsTab() {
    const {data: mailSettingsData, isLoading: isLoadingSettingsData} = useEmailSettingsData();
    const {mutate: mutateSettings, isLoading: isMutatingSettingsData} = useMutateEmailSettingsData(updateSettings);

    return isLoadingSettingsData ? (
        <Center>
            <Loader my={"5rem"}/>
        </Center>
    ) : (
        <SettingsForm
            defaultValues={mailSettingsData}
            isLoading={isMutatingSettingsData}
            onSubmit={mutateSettings}
        />
    );
}