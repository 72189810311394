import {Anchor, Center, Container, Group, LoadingOverlay, Paper, Text} from '@mantine/core';
import {ArrowNarrowLeft} from "tabler-icons-react";
import React from "react";
import {useNavigate} from "react-router-dom";
import VerifyEmailForm from "../components/forms/VerifyEmailForm";
import useResetPassword from "../hooks/useResetPassword";
import {Brand} from "../components/AppShell/_brand";

function ResetPassword() {
    const { loading, setFormData, onSubmit } = useResetPassword();
    const navigate = useNavigate();

    return (
        <Container size={420} my={40}>
            <Paper withBorder shadow="md" radius="md" p="xl">
                <Center mb={"md"}>
                    <Brand/>
                </Center>

                <div style={{position: "relative"}}>
                    <LoadingOverlay visible={loading} />
                    <VerifyEmailForm submitText={"Set new password"} onSubmit={onSubmit} setValues={setFormData}/>
                </div>

                <Anchor onClick={() => navigate("/login")}>
                    <Group>
                        <ArrowNarrowLeft/>
                        <Text>Login</Text>
                    </Group>
                </Anchor>
            </Paper>
        </Container>
    );
}

export default ResetPassword;