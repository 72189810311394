import {useModals} from "@mantine/modals";
import EditShipForm from "../../components/forms/ship/EditShipForm";

export default function useEditShip() {
    const modals = useModals();

    const openModal = (id: string) => {
        const modalId = modals.openModal({
            size: "xl",
            title: "Edit ship",
            closeOnClickOutside: false,
            children: (<EditShipForm id={id} onClose={() => closeModal(modalId)}/>)
        });
    };

    const closeModal = (modalId: string) => {
        modals.closeModal(modalId);
    }

    return {
        openModal,
    };
}