import {generateSortString, handleRequestErrors} from "../../tools";
import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import {Ship} from "../../types";
import {saveAs} from "file-saver";

export const fetchShips = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/ship?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchShip = async (id: string) => {
    return await fetch(`${API_URL}/ship/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createShip(ship: Ship) {
    return await fetch(`${API_URL}/ship`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(ship)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateShip(ship: Ship) {
    return await fetch(`${API_URL}/ship/${ship.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(ship)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function deleteShip (ids: string[]) {
    return await fetch(`${API_URL}/ship`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
        body: JSON.stringify({
            "ships": ids
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}

export const exportShipData = async (sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/export/ship?${generateSortString(sortByData)}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'ships.csv'))
        .catch((error) => {
            throw error.message;
        });
};
