import React, { useContext } from "react";
import { Navbar, ScrollArea } from "@mantine/core";
import {
  Briefcase,
  GasStation,
  Home,
  Lock,
  Settings,
  Ship,
  Users,
  ZoomQuestion,
} from "tabler-icons-react";
import LinksGroup from "./_linksGroup";
import { UserContext } from "../../providers/user";

type AppNavbarProps = {
  opened: boolean;
};

export default function AppNavbar(props: AppNavbarProps) {
  const { opened } = props;
  const { isAuthorized } = useContext(UserContext);

  const navData = [
    {
      label: "Home",
      icon: Home,
      link: "/",
    },
    {
      label: "Requests",
      icon: GasStation,
      link: "/requests",
    },
    ...(isAuthorized([
      "customer_index",
      "customer_show",
      "customer_store",
      "customer_update",
      "customer_destroy",
    ])
      ? [
          {
            label: "Customers",
            icon: Briefcase,
            link: "/customers",
          },
        ]
      : []),
    ...(isAuthorized([
      "user_index",
      "user_show",
      "user_store",
      "user_update",
      "user_destroy",
    ])
      ? [
          {
            label: "Users",
            icon: Users,
            link: "/users",
          },
        ]
      : []),
    {
      label: "Ships",
      icon: Ship,
      link: "/ships",
    },
    {
      label: "Locations",
      icon: ZoomQuestion,
      link: "/locations",
    },
    ...(isAuthorized([
      "role_index",
      "role_store",
      "role_update",
      "role_destroy",
      "user_store",
      "user_update",
    ])
      ? [
          {
            label: "Roles",
            icon: Lock,
            link: "/role",
          },
        ]
      : []),
    ...(isAuthorized(["email_settings", "contact_settings"])
      ? [
          {
            label: "Admin",
            icon: Settings,
            link: "/admin",
          },
        ]
      : []),
  ];

  return (
    <Navbar
      styles={{
        root: { color: "custom-red" },
        hidden: { color: "custom-red" },
      }}
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 300 }}
    >
      <ScrollArea
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {navData.map((item: any) => (
          <LinksGroup
            icon={item.icon}
            label={item.label}
            link={item.link}
            key={item.label}
          />
        ))}
      </ScrollArea>
    </Navbar>
  );
}
