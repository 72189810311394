import {useModals} from "@mantine/modals";
import BunkerAttachmentDropzone from "../../components/dropzones/bunkerAttachments";

export default function useAttachDocumentsToBunkerRequest() {
    const modals = useModals();

    const openModal = (id: string) => {
        const modalId = modals.openModal({
            centered: true,
            title: 'Upload attachments',
            closeOnClickOutside: false,
            size: "xl",
            children: (
                <div style={{position: "relative"}}>
                    <BunkerAttachmentDropzone onClose={() => closeModal(modalId)} id={id} />
                </div>
            ),
        })
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
    }

    return {
        openModal
    }
}
