import { fetchRoles } from "../../api/roles";
import FancyTable from "./FancyTable";
import React, { useContext } from "react";
import { List, ScrollArea, Text } from "@mantine/core";
import useEditRole from "../../hooks/role/useEditRole";
import useDeleteRole from "../../hooks/role/useDeleteRole";
import { Permission } from "../../../types";
import { UserContext } from "../../providers/user";

export default function RoleTable() {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openEditModal } = useEditRole();
  const { openModal: openDeleteModal } = useDeleteRole();

  const columns = React.useMemo(
    () => [
      {
        id: "role-table",
        columns: [
          {
            id: "name",
            Header: "Name",
            accessor: (d: any) => <Text size={"sm"}>{d.name}</Text>,
          },
          {
            id: "permissions",
            Header: "Permissions",
            disableSortBy: true,
            accessor: (d: any) => {
              return (
                <ScrollArea type="auto" style={{ height: 90 }}>
                  <List
                    size={"sm"}
                    sx={(theme) => ({
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.colors["custom-gray"][0],
                    })}
                  >
                    {d.permissions.map((value: Permission) => (
                      <List.Item key={value?.name}>
                        {value?.description}
                      </List.Item>
                    ))}
                  </List>
                </ScrollArea>
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <FancyTable
      queryKey={"roles"}
      queryFn={fetchRoles}
      columns={columns}
      onEdit={isAuthorized("role_update") ? openEditModal : undefined}
      onDelete={isAuthorized("role_destroy") ? openDeleteModal : undefined}
    />
  );
}
