import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, AutocompleteItem, Loader } from "@mantine/core";
import { fetchLocation, fetchLocations } from "../../api/locations";
import { Location } from "../../../types";
import { DefaultProps } from "@mantine/styles";
import { AutocompleteStylesNames } from "@mantine/core/lib/components/Autocomplete/Autocomplete";
import { DEBOUNCE_TIMEOUT } from "../../constants";

interface LocationSelectProps extends DefaultProps<AutocompleteStylesNames> {
  error?: React.ReactNode;
  defaultValue?: string;
  onItemSubmit: (value: AutocompleteItem) => void;
  onClear: () => void;
  required?: boolean;
  placeholder?: string;
  label?: string;
}

export function LocationSelect(props: LocationSelectProps) {
  const { onClear, defaultValue, placeholder, label, ...restProps } = props;
  const timeoutRef = useRef<number>(-1);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AutocompleteItem[]>([]);

  useEffect(() => {
    defaultValue && setInitialValue(defaultValue);
  }, [defaultValue]);

  const onChange = (val: string) => {
    // Reset the wait period before sending the request to prevent spamming the API.
    window.clearTimeout(timeoutRef.current);

    setValue(val);
    setData([]);
    onClear();

    if (val.trim().length === 0) {
      setLoading(false);
    } else {
      setLoading(true);

      // Send the request after waiting the global DEBOUNCE_TIMEOUT to ensure that the typing has stopped.
      timeoutRef.current = window.setTimeout(
        () => updateData(val),
        DEBOUNCE_TIMEOUT
      );
    }
  };

  const updateData = async (query: string) => {
    let filteredData = await fetchLocations(0, 20, [], query);
    setLoading(false);

    setData(
      filteredData.data.map((c: Location) => {
        return {
          location: c,
          value: `${c.name} (${c.location})`,
        };
      })
    );
  };

  const setInitialValue = async (value: string) => {
    setLoading(true);
    let c = await fetchLocation(value);
    setLoading(false);

    setData([
      {
        location: c,
        value: `${c.name} (${c.location})`,
      },
    ]);

    setValue(`${c.name} (${c.location})`);
  };

  return (
    <Autocomplete
      {...restProps}
      data={data}
      value={value}
      limit={20}
      onChange={onChange}
      rightSection={loading && <Loader size={16} />}
      placeholder={placeholder ?? "Select a location"}
      label={label ?? "Location"}
      styles={(theme) => ({
        rightSection: { pointerEvents: "none" },
        item: {
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
        label: {
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
        required: {
          color: "#004F65",
        },
        input: {
          borderColor: theme.colors["custom-gray"][7],
          "&:focus": {
            borderColor: theme.colors["bright-green"][0],
          },
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
      })}
    />
  );
}
