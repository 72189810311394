import {API_URL, FETCH_HEADERS} from "../constants";

export const fetchPermissions = async () => {
    return await fetch(`${API_URL}/permission`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};