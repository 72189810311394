import { Button, Card, Group, Stack, Text, createStyles } from "@mantine/core";
import { BunkerRequest } from "../../../types";
import { Ban, Check } from "tabler-icons-react";
import React, { useContext } from "react";
import useApproveBunkerRequest from "../../hooks/bunkerRequest/useApproveBunkerRequest";
import useRejectBunkerRequest from "../../hooks/bunkerRequest/useRejectBunkerRequest";
import { UserContext } from "../../providers/user";

type Props = {
  bunkerRequest: BunkerRequest;
};

const useStyles = createStyles((theme) => ({
  paper: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

export default function UnconfirmedBunkerRequestCard(props: Props) {
  const { bunkerRequest } = props;
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openApproveModal } = useApproveBunkerRequest();
  const { openModal: openRejectModal } = useRejectBunkerRequest();
  const { classes } = useStyles();

  return (
    <Card
      withBorder
      my={"md"}
      style={{ position: "relative" }}
      className={classes.paper}
    >
      <Group spacing={0} position="apart" noWrap>
        <Group>
          <Stack>
            <Text>
              {bunkerRequest.ship?.name && bunkerRequest.ship.name.length > 31
                ? `${bunkerRequest.ship?.name.substring(0, 32)}...`
                : bunkerRequest.ship?.name}
            </Text>

            <Group>
              <Text>{bunkerRequest.date?.toString()}</Text>
              <Text>
                {bunkerRequest.time_slot?.start_time} -{" "}
                {bunkerRequest.time_slot?.end_time}
              </Text>
              <Text>{bunkerRequest.expected_amount} kg</Text>
            </Group>
          </Stack>
        </Group>

        <Stack>
          {isAuthorized("bunker_request_reject") && (
            <Button
              variant="subtle"
              p="0px 5px 0px 5px"
              onClick={() => openRejectModal(bunkerRequest.id)}
              color={"custom-red"}
              sx={(theme) => ({
                "&:hover": {
                  backgroundColor: theme.colors["custom-red"][1],
                },
              })}
            >
              <Ban size={24} />
            </Button>
          )}

          {isAuthorized("bunker_request_approve") && (
            <Button
              variant="subtle"
              p="0px 5px 0px 5px"
              onClick={() => openApproveModal(bunkerRequest.id)}
              color={"bright-green"}
              sx={(theme) => ({
                "&:hover": {
                  backgroundColor: theme.colors["bright-green"][1],
                },
              })}
            >
              <Check size={24} />
            </Button>
          )}
        </Stack>
      </Group>
    </Card>
  );
}
