import {useModals} from "@mantine/modals";
import React from "react";
import EditTimeSlotExceptionForm from "../../components/forms/timeSlotException/EditTimeSlotExceptionForm";

export default function useEditTimeSlotException() {
    const modals = useModals();

    const openModal = function(timeSlotExceptionId: string) {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Edit time slot exception',
            closeOnClickOutside: false,
            children: (
                <>
                    <EditTimeSlotExceptionForm timeSlotExceptionId={timeSlotExceptionId} onClose={() => closeModal(modalId)} />
                </>
            ),
        });
    }

    const closeModal = function(modalId: string) {
        modals.closeModal(modalId);
    }

    return {
        openModal,
    };
}