import {useContext} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import {UserContext} from "../../providers/user";
import {ConditionalComponent} from "./ConditionalComponent";
import NotFound from "../../pages/NotFound";
import Users from "../../pages/users";
import Customers from "../../pages/customers";
import VerifyEmail from "../../pages/VerifyEmail";
import Locations from "../../pages/locations";
import Ships from "../../pages/ships";
import LocationDetail from "../../pages/locations/detail";
import BunkerRequests from "../../pages/bunker-requests";
import Reporting from "../../pages/reporting";
import ShipDetail from "../../pages/ships/detail";
import PasswordForgotten from "../../pages/PasswordForgotten";
import ResetPassword from "../../pages/ResetPassword";
import Admin from "../../pages/admin";
import Roles from "../../pages/roles";
import Settings from "../../pages/settings";
import BunkerRequestsCalendar from "../../pages/bunker-requests/calendar";

export default function FullRouteCollection() {
    const {isAuthed, isAuthorized} = useContext(UserContext);

    return (
        <Routes>
            <Route path={"/"} element={isAuthed ?
                <ConditionalComponent condition={true} Component={Home} title={"Home"} /> :
                <Navigate to={"/login"}/>}
            />

            <Route path={"/users"} element={<ConditionalComponent condition={isAuthed && isAuthorized(["user_index", "user_show", "user_store", "user_update", "user_destroy"])} Component={Users} title={"Users"} />}/>
            <Route path={"/customers"} element={<ConditionalComponent condition={isAuthed && isAuthorized(["customer_index", "customer_show", "customer_store", "customer_update", "customer_destroy"])} Component={Customers} title={"Customers"} />}/>
            <Route path={"/requests"} element={<ConditionalComponent condition={isAuthed} Component={BunkerRequests} title={"Bunker requests"} />}/>
            <Route path={"/requests/calendar"} element={<ConditionalComponent condition={isAuthed} Component={BunkerRequestsCalendar} title={"Bunker requests"} />}/>
            <Route path={"/role"} element={<ConditionalComponent condition={isAuthed && isAuthorized(['role_index', 'role_store', 'role_update', 'role_destroy', 'user_store', 'user_update'])} title={"Roles"} Component={Roles} />}/>
            <Route path={"/admin"} element={<ConditionalComponent condition={isAuthed && isAuthorized(['email_settings', 'contact_settings'])} title={"Admin"} Component={Admin} />}/>
            <Route path={"/settings"} element={<ConditionalComponent condition={isAuthed} title={"Settings"} Component={Settings} />}/>

            <Route path={"/locations"} element={<ConditionalComponent condition={isAuthed} Component={Locations} title={"Locations"} />}/>
            <Route path={"/locations/:id"} element={<ConditionalComponent condition={isAuthed} Component={LocationDetail} title={"Location details"} />}/>
            <Route path={"/locations/:id/reporting"} element={<ConditionalComponent condition={isAuthed && isAuthorized("notification_setting")} Component={Reporting} title={"Reporting"} />}/>

            <Route path={"/ships"} element={<ConditionalComponent condition={isAuthed} Component={Ships} title={"Ships"} />}/>
            <Route path={"/ships/:id"} element={<ConditionalComponent condition={isAuthed} Component={ShipDetail} title={"Ship details"} />}/>

            <Route path={"/login"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} title={"Login"} Component={Login} />}/>

            <Route path={"/password-forgotten"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={PasswordForgotten} title={"Password forgotten"} />}/>
            <Route path={"/password-reset/:token/:email/:expires/:signature"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={ResetPassword} title={"Reset password"} />}/>

            <Route path={"/verify/:id/:hash/:expires/:signature"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={VerifyEmail} title={"Set up account"} />}/>

            <Route path={"*"} element={<ConditionalComponent condition={isAuthed} Component={NotFound} title={"404"} />}/>
        </Routes>
    );
}
