import { useForm } from "@mantine/form";
import { PatchableUser, User } from "../../../../types";
import { validateEmail, validateString } from "../../../validator";
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { Mail, Phone } from "tabler-icons-react";

type AccountFormProps = {
  user: User;
  onSubmit: (values: PatchableUser) => void;
  loading: boolean;
};

export default function AccountForm(props: AccountFormProps) {
  const { user, onSubmit, loading } = props;

  const form = useForm<PatchableUser>({
    initialValues: {
      id: user.id,
      name: user.name,
      email: user.email,
      phone: user.phone,
    },
    validate: {
      id: (value) => validateString(value),
      name: (value) => validateString(value),
      email: (value) => validateEmail(value),
      phone: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack spacing={"sm"}>
        <TextInput
          label="Name"
          placeholder="Your name"
          required
          {...form.getInputProps("name")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
          })}
        />

        <TextInput
          label="E-mail address"
          description="Once changed, an e-mail to your new e-mail address will be sent for verification."
          placeholder="your@mail.com"
          required
          type="email"
          {...form.getInputProps("email")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            description: {
              color: "#8C9196",
            },
            icon: {
              color: "#d5d9de",
            },
            required: {
              color: "#004F65",
            },
          })}
          icon={<Mail size={16} />}
        />

        <TextInput
          label="Phone"
          placeholder="+31 528 123456"
          required
          icon={<Phone size={16} />}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            input: {
              "&::placeholder": {
                color: theme.colors["custom-gray"][8],
              },
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            icon: {
              color: "#d5d9de",
            },
            required: {
              color: "#004F65",
            },
          })}
        />

        <Group>
          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
