import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

type ForecastPerMonthChartProps = {
  bunkeredAmount: number;
  expectedAmount: number;
};

export default function ForecastPerMonthChart(
  props: ForecastPerMonthChartProps
) {
  const { bunkeredAmount, expectedAmount } = props;

  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
  };

  const data = {
    labels: ["Bunkered amount", "Expected amount remaining"],
    datasets: [
      {
        label: "Volume forecast current month",
        data: [bunkeredAmount, expectedAmount - bunkeredAmount],
        backgroundColor: ["#289323", "#7DCB7D"],
        borderColor: ["#289323", "#7DCB7D"],
        borderWidth: 1,
      },
    ],
  };

  return <Pie options={options} data={data} />;
}
