import React, {useContext} from "react";
import FancyTable from "./FancyTable";
import {fetchUsers} from "../../api/users";
import {Anchor, Text} from "@mantine/core";
import useDeleteUser from "../../hooks/user/useDeleteUser";
import useEditUser from "../../hooks/user/useEditUser";
import {UserContext} from "../../providers/user";

function UsersTable() {
    const {isAuthorized} = useContext(UserContext)
    const {openModal: openEditModal} = useEditUser();
    const {openModal: openDeleteModal} = useDeleteUser();

    const columns = React.useMemo(
        () => [
            {
                id: 'user-table',
                columns: [
                    {
                        id: 'name',
                        Header: 'Name',
                        accessor: (d: any) => <Text size={"sm"}>{d.name}</Text>
                    },
                    {
                        id: 'status',
                        Header: 'Status',
                        disableSortBy: true,
                        accessor: (d: any) => {
                            if (!d.active || !d.email_verified) return "Inactive";
                            return "Active";
                        }
                    },
                    {
                        id: 'phone',
                        Header: 'Phone number',
                        accessor: (d: any) => {
                            return <Anchor size={"sm"} href={`tel:${d.phone}`}>{d.phone}</Anchor>
                        }
                    },
                    {
                        id: 'email',
                        Header: 'E-mail address',
                        accessor: (d: any) => {
                            return <Anchor size={"sm"} href={`mailto:${d.email}`}>{d.email}</Anchor>
                        }
                    },
                ],
            },
        ],
        []
    )

    return (
        <>
            <FancyTable
                queryKey={'users'}
                queryFn={fetchUsers}
                columns={columns}
                onDelete={isAuthorized("user_destroy") ? openDeleteModal : undefined}
                onEdit={isAuthorized("user_update") ? openEditModal : undefined}
            />
        </>
    );
}

export default UsersTable;
