import React, { useContext } from "react";
import { useForm } from "@mantine/form";
import {
  Button,
  Checkbox,
  Group,
  MultiSelect,
  TextInput,
  Text,
} from "@mantine/core";
import { Customer, PatchableUser, Role, Ship, User } from "../../../../types";
import { validateEmail, validateString } from "../../../validator";
import FancyMultiSelect from "../../multiSelects/FancyMultiSelect";
import { fetchShips } from "../../../api/ships";
import { fetchRoles } from "../../../api/roles";
import { fetchCustomers } from "../../../api/customers";
import { NOTIFICATION_SETTING_EVENTS } from "../../../constants";
import { UserContext } from "../../../providers/user";
import { Mail, Phone, UserCircle } from "tabler-icons-react";

type UserFormProps = {
  user?: User;
  submitText?: string;
  submitAction: (values: PatchableUser) => void;
  isLoading?: boolean;
};

function UserForm(props: UserFormProps) {
  const { user, submitText, submitAction, isLoading = false } = props;
  const { data } = useContext(UserContext);

  const form = useForm<PatchableUser>({
    initialValues: {
      id: user?.id ?? "",
      name: user?.name ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      roles: user?.roles?.map((i: Role) => i.id) ?? [],
      ships: user?.ships?.map((i: Ship) => i.id) ?? [],
      customers: user?.customers?.map((i: Customer) => i.id) ?? [],
      notification_settings:
        user?.notification_settings ??
        NOTIFICATION_SETTING_EVENTS.map((value) => value.value),
      sms_notifications: user?.sms_notifications ?? true,
      email_notifications: user?.email_notifications ?? true,
    },

    validate: {
      name: (value) => validateString(value),
      email: (value) => validateEmail(value),
      phone: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput
        pb={"sm"}
        required
        label="Name"
        placeholder="Your name"
        {...form.getInputProps("name")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <TextInput
        pb={"sm"}
        required
        placeholder="your@mail.com"
        label="E-mail address"
        type={"email"}
        {...form.getInputProps("email")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
        icon={<Mail size={16} />}
      />

      <TextInput
        pb={"sm"}
        required
        label="Phone number"
        placeholder={"+31 528 123456"}
        {...form.getInputProps("phone")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
        icon={<Phone size={16} />}
      />

      <FancyMultiSelect<Role>
        onSelectedValuesChange={(values) =>
          form.setFieldValue(
            "roles",
            values.map((i) => parseInt(i))
          )
        }
        defaultValue={user?.roles?.map((val: Role) => `${val.id}`)}
        queryFn={() => fetchRoles(0, 100)}
        labelRenderer={(val) => `${val.name}`}
        label="Roles"
        placeholder="The roles of the user"
        pb={"sm"}
        disabled={data?.id === user?.id}
      />

      <FancyMultiSelect<Ship>
        onSelectedValuesChange={(values) => form.setFieldValue("ships", values)}
        defaultValue={user?.ships?.map((val: Ship) => `${val.id}`)}
        queryFn={() => fetchShips(0, 100)}
        labelRenderer={(val) => `${val.name} (${val.imo_number})`}
        label="Ships"
        placeholder="The ships of the user"
        description={
          "The user is able to view their own requests including all bunker requests that are linked to the selected ships. The selected ships are ignored if the user has a role that gives access to all bunker requests or ships."
        }
        pb={"sm"}
      />

      <FancyMultiSelect<Customer>
        onSelectedValuesChange={(values) =>
          form.setFieldValue("customers", values)
        }
        defaultValue={user?.customers?.map((val: Customer) => `${val.id}`)}
        queryFn={() => fetchCustomers(0, 100)}
        labelRenderer={(val) => `${val.name}`}
        label="Customers"
        placeholder="The customers of the user"
        description={
          "The user is able to view their own requests including all bunker requests that are linked to the selected customers. The selected customers are ignored if the user has a role that gives access to all bunker requests."
        }
        pb={"sm"}
      />

      <MultiSelect
        data={NOTIFICATION_SETTING_EVENTS}
        label="Notified for"
        placeholder="Notification event"
        description="The events where the user as creator of an bunker request will be notified for."
        pb={"sm"}
        {...form.getInputProps("notification_settings")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          item: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <Text
        weight={500}
        size={"sm"}
        pb={"sm"}
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        })}
      >
        Notified via
      </Text>
      <Group spacing={"sm"}>
        <Checkbox
          value="sms_notifications"
          label="SMS"
          {...form.getInputProps("sms_notifications", { type: "checkbox" })}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
        <Checkbox
          value="email_notifications"
          label="E-mail"
          {...form.getInputProps("email_notifications", { type: "checkbox" })}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      </Group>

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Save"}
        </Button>
      </Group>
    </form>
  );
}

export default UserForm;
