import React, { useContext } from "react";
import {
  Burger,
  createStyles,
  Group,
  Header,
  MediaQuery,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { Search } from "tabler-icons-react";
import { SearchContext } from "../../providers/search";
import User from "./_user";
import { Brand } from "./_brand";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "space-between",
  },
}));

type AppHeaderProps = {
  opened: boolean;
  setOpened: (value: boolean) => void;
};

export default function AppHeader(props: AppHeaderProps) {
  const { opened, setOpened } = props;
  const { query, setQuery } = useContext(SearchContext);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <Header height={70} p={"md"} className={classes.header}>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Burger
          opened={opened}
          onClick={() => setOpened(!opened)}
          size="sm"
          color={theme.colors.gray[6]}
          mr="xl"
        />
      </MediaQuery>

      <Brand />

      <Group>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <TextInput
            placeholder="Search here..."
            radius={"xl"}
            rightSection={<Search size={16} />}
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              },
              input: {
                "&::placeholder": {
                  color: theme.colors["custom-gray"][8],
                },
                borderColor: theme.colors["custom-gray"][7],
                "&:focus": {
                  borderColor: theme.colors["bright-green"][0],
                },
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors["custom-gray"][0],
              },
            })}
          />
        </MediaQuery>

        <User />
      </Group>
    </Header>
  );
}
