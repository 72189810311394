import { Anchor, createStyles, Table } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Ship } from "../../../types";

type Props = {
  ship: Ship;
};

const useStyles = createStyles((theme) => ({
  table: {
    border: "none",
    width: "auto",

    "tbody tr td": {
      border: "none",
      padding: "2px 50px 2px 0",
    },
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

export default function ShipDetailTable(props: Props) {
  const { ship } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Table className={classes.table}>
      <tbody>
        <tr>
          <td>Name</td>
          <td>{ship.name}</td>
        </tr>
        <tr>
          <td>Identification number</td>
          <td>{ship.imo_number}</td>
        </tr>
        <tr>
          <td>Phone number ship</td>
          <td>{ship.phone}</td>
        </tr>
        <tr>
          <td>Storage size</td>
          <td>{ship.storage_size} kg</td>
        </tr>
        <tr>
          <td>Customer</td>
          <td>
            {ship?.customer?.name ? (
              <Anchor size="sm" onClick={() => navigate("/customers")}>
                {ship.customer.name}
              </Anchor>
            ) : (
              "N/A"
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
