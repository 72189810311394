import React, { useContext } from "react";
import { Button } from "@mantine/core";
import { Ban, Check } from "tabler-icons-react";
import useEditBunkerRequest from "../../hooks/bunkerRequest/useEditBunkerRequest";
import useApproveBunkerRequest from "../../hooks/bunkerRequest/useApproveBunkerRequest";
import useRejectBunkerRequest from "../../hooks/bunkerRequest/useRejectBunkerRequest";
import FancyTable from "./FancyTable";
import { fetchBunkerRequests } from "../../api/bunkerRequests";
import { formattedRequestStatus } from "../../../tools";
import { UserContext } from "../../providers/user";

type Props = {
  shipId?: string;
};

export default function ShipBunkerRequestTable(props: Props) {
  const { shipId = "" } = props;
  const { openModal: openEditModal } = useEditBunkerRequest();
  const { openModal: openApproveModal } = useApproveBunkerRequest();
  const { openModal: openRejectModal } = useRejectBunkerRequest();
  const { isAuthorized } = useContext(UserContext);

  const columns = React.useMemo(
    () => [
      {
        id: "shipBunkerRequest-table",
        columns: [
          {
            id: "status",
            Header: "Status",
            accessor: (d: any) => {
              return formattedRequestStatus(d);
            },
          },
          {
            id: "date",
            Header: "Date",
            accessor: "date",
          },
          {
            id: "timeslot",
            Header: "Timeslot",
            accessor: (d: any) =>
              d.time_slot
                ? `${d.time_slot?.start_time} - ${d.time_slot?.end_time}`
                : "N/A",
          },
          {
            id: "expected_amount",
            Header: "Expected amount",
            accessor: (d: any) =>
              `${d.expected_amount ? `${d.expected_amount} kg` : "N/A"}`,
          },
          {
            id: "bunkered_amount",
            Header: "Bunkered amount",
            accessor: (d: any) =>
              `${d.bunkered_amount ? `${d.bunkered_amount} kg` : "N/A"}`,
          },
          {
            id: "application_form",
            Header: "Application form",
            disableSortBy: true,
            accessor: (d: any) => `${d.attachments.length > 0 ? "Yes" : "No"}`,
          },
        ],
      },
    ],
    []
  );

  const additionalActionsRenderer = (row: any) => {
    return (
      <>
        {isAuthorized("bunker_request_approve") && (
          <Button
            disabled={!!row.original.processed_at}
            onClick={() => openApproveModal(row.original.id)}
          >
            <Check />
          </Button>
        )}

        {isAuthorized("bunker_request_reject") && (
          <Button
            color={"custom-red"}
            disabled={!!row.original.processed_at}
            onClick={() => openRejectModal(row.original.id)}
          >
            <Ban />
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <FancyTable
        queryKey={"bunkerRequests"}
        queryFn={fetchBunkerRequests}
        filters={[{ column: "shipId", value: shipId }]}
        columns={columns}
        onEdit={
          isAuthorized("bunker_request_update") ? openEditModal : undefined
        }
        additionalActionsRenderer={
          isAuthorized(["bunker_request_approve", "bunker_request_reject"])
            ? additionalActionsRenderer
            : undefined
        }
      />
    </>
  );
}
