import RoleForm from "./RoleForm";
import {Center, Loader} from "@mantine/core";
import {Role} from "../../../../types";
import useMutateRoleData from "../../../hooks/role/useMutateRoleData";
import {fetchRole, updateRole} from "../../../api/roles";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import React, {useEffect, useState} from "react";

type EditRoleFormProps = {
    onClose: () => void,
    roleId: string,
}

export default function EditRoleForm(props: EditRoleFormProps) {
    const {onClose, roleId} = props;
    const [role, setRole] = useState();

    const onSuccess = function(value: any) {
        showNotification({
            title: 'Role updated',
            icon: <Check/>,
            message: `Role '${value.data.name}' has been updated!`,
        });

        onClose();
    }

    const {mutate, isLoading} = useMutateRoleData(updateRole, onSuccess);

    const onSubmit = async function(value: Role) {
        mutate(value);
    }

    const onLoad = async function() {
        const response = await fetchRole(roleId);

        if (response?.data) {
            setRole(response.data);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return !role ? (
        <Center>
            <Loader height={200} />
        </Center>
    ) : (
        <RoleForm onSubmit={onSubmit} role={role} loading={isLoading} />
    );
}