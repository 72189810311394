import React, {useState} from "react";
import {API_URL} from "../constants";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import useLazyFetch from "./useLazyFetch";
import {formatErrors, isEmptyArray} from "../../tools";

/**
 * Hook for handling email verification
 */
export default function usePasswordForgotten() {
    const [formData, setFormData] = useState({
        'email': '',
        'email_confirmation': '',
    });

    const {doFetch, loading} = useLazyFetch({
        url: `${API_URL}/auth/password-forgotten`,
        options: {
            method: "POST",
            body: JSON.stringify(formData)
        },
    });

    const onSubmit = async () => {
        const res = await doFetch();
        const errors = formatErrors(res);

        if (!isEmptyArray(errors)) {
            showNotification({
                title: `Something went wrong`,
                message: `We could not request a password reset. ${errors[0]}`,
                color: 'red',
                autoClose: 10000
            })

            return;
        }

        showNotification({
            title: 'Password reset request received',
            icon: <Check/>,
            message: `Your password reset request has been received, please check your email!`,
        });
    };

    return {
        onSubmit,
        setFormData,
        loading,
    };
}
