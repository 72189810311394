import React, { useState } from "react";
import {
  Anchor,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  createStyles,
} from "@mantine/core";
import { File } from "tabler-icons-react";
import { Attachment } from "../../../types";
import { formatFileSizeMetric, getAuthToken } from "../../../tools";
import { saveAs } from "file-saver";
import MimeTypeIcon from "../icons/MimeTypeIcon";

type BunkerRequestAttachmentOverviewProps = {
  item: Attachment;
};

const useStyles = createStyles((theme) => ({
  paper: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors["custom-gray"][0],
  },
}));

const DownloadableAttachment = (
  props: BunkerRequestAttachmentOverviewProps
) => {
  const { item } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { classes } = useStyles();

  const downloadFile = async (url: string, fileName: string) => {
    setIsLoading(true);

    await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName))
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Paper
      withBorder
      p={"md"}
      style={{ height: "100%" }}
      className={classes.paper}
    >
      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={isLoading} />
        <Stack spacing={0}>
          <Group mb={"sm"}>
            <MimeTypeIcon mimeType={item.mime_type} />
          </Group>
          <Anchor
            onClick={() => downloadFile(item.original_url, item.file_name)}
            size={"sm"}
          >
            {item.file_name}
          </Anchor>
          <Text size={"xs"} color={"dimmed"}>
            {formatFileSizeMetric(item.size)}
          </Text>
        </Stack>
      </div>
    </Paper>
  );
};

export default DownloadableAttachment;
