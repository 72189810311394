import React from 'react';
import {Center, Grid, Loader} from "@mantine/core";
import useBunkerRequestData from "../../hooks/bunkerRequest/useBunkerRequestData";
import {Attachment} from "../../../types";
import DownloadableAttachment from "./DownloadableAttachment";

type BunkerRequestAttachmentOverviewProps = {
    id: string;
}

const BunkerRequestAttachmentOverview = (props: BunkerRequestAttachmentOverviewProps) => {
    const {id} = props;
    const {data, isFetching, isRefetching} = useBunkerRequestData(id);
    const isLoading = isFetching || isRefetching;

    return (
        <>
            {isLoading ? (
                <Center>
                    <Loader/>
                </Center>
            ) : (
                <Grid columns={4}>
                    {data.attachments.map((item: Attachment) => {
                        return (
                            <Grid.Col sm={2} lg={1}>
                                <DownloadableAttachment item={item} key={item.id} />
                            </Grid.Col>
                        )
                    })}
                </Grid>
            )}
        </>
    );
};

export default BunkerRequestAttachmentOverview;
