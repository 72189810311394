import {Maybe} from "../types";
import dayjs from "dayjs";

export function validateString(string: Maybe<string>, name: string = "This field") {
    if (!(string && string.trim())) {
        return `${name} is required.`
    }

    return null;
}

export function validateSame(string: Maybe<string>, string2: Maybe<string>, field: string) {
    return string === string2 ? null : `This field has to be equal to ${field}.`;
}

export function validateDate(date: Maybe<Date | string>, name: string = "This date") {
    if(date === null || date === undefined) {
        return `${name} is required.`
    }

    if (!dayjs(date).isValid()) {
        return `${name} is not valid.`
    }

    return null;
}

export function validateEmail(string: Maybe<string>, name: string = "This e-mail address") {
    let valid = String(string)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (!valid) {
        return `${name} is not valid.`
    }

    return null;
}

export function validateNumber(number: Maybe<number | string>, name: string = "Value", required = true, min: number = 1, max?: number) {
    if (required && (number === null || number === undefined || number === "")) return `${name} is required.`;

    if (typeof number === 'string') {
        let newNumber = Number(number)

        if (isNaN(newNumber)) {
            return `'${number}' is not a valid ${name.toLowerCase()}.`
        }

        number = newNumber;
    }

    if(number) {
        if (number < min) {
            return `${name} must be greater than ${min}.`
        }

        if (max && number > max) {
            return `${name} may be at most ${max}.`
        }
    }

    return null;
}