import { Avatar, Group, Loader, Text, UnstyledButton } from "@mantine/core";
import React, { forwardRef, useContext } from "react";
import { ChevronDown } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { UserContext } from "../../providers/user";

export const UserButton = forwardRef<HTMLButtonElement>(
  ({ ...others }, ref) => {
    const { data: user } = useContext(UserContext);
    const largeScreen = useMediaQuery("(min-width: 769px)");

    return (
      <UnstyledButton
        ref={ref}
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.sm,
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
        {...others}
      >
        <Group>
          {!user ? (
            <Loader />
          ) : (
            <>
              <Avatar radius="xl" src={user.avatar} />

              {largeScreen && (
                <>
                  <Text size="sm">{user.name}</Text>
                  <ChevronDown size={16} />
                </>
              )}
            </>
          )}
        </Group>
      </UnstyledButton>
    );
  }
);
