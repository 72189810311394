import React from "react";
import {
  Button,
  Container,
  createStyles,
  Group,
  Text,
  Title,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 40,
    paddingBottom: 40,
  },

  title: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export function ErrorView() {
  const { classes } = useStyles();

  const forceReload = () => {
    window.location.reload();
  };

  return (
    <Container className={classes.root}>
      <Title className={classes.title}>Things didn't go as planned.</Title>
      <Title mb={"md"}></Title>

      <Text
        size="lg"
        align="center"
        className={classes.description}
        sx={(theme) => ({
          color: theme.colors["custom-gray"][9],
        })}
      >
        No worries, the developers have already been notified. A solution is
        being worked on.
      </Text>
      <Group position="center">
        <Button
          variant="subtle"
          size="md"
          sx={(theme) => ({
            "&:hover": {
              backgroundColor: theme.colors["bright-green"][1],
            },
          })}
          onClick={forceReload}
        >
          Reload the application
        </Button>
      </Group>
    </Container>
  );
}

export default ErrorView;
