import React from "react";
import { useForm } from "@mantine/form";
import { Button, createStyles, Group, Select, TextInput } from "@mantine/core";
import { Customer, PatchableCustomer } from "../../../../types";
import { validateEmail, validateString } from "../../../validator";
import { Mail, Phone, UserCircle } from "tabler-icons-react";

const { getData } = require("country-list");

type CustomerFormProps = {
  customer?: Customer;
  submitText?: string;
  submitAction: (values: PatchableCustomer) => void;
  isLoading?: boolean;
};

const useStyles = createStyles((theme, _params, getRef) => ({
  group: {
    alignItems: "flex-start",
  },
}));

function CustomerForm(props: CustomerFormProps) {
  const { customer, submitText, submitAction, isLoading = false } = props;
  const { classes } = useStyles();

  const form = useForm<PatchableCustomer>({
    initialValues: {
      id: customer?.id ?? "",
      name: customer?.name ?? "",
      address: customer?.address ?? "",
      postal_code: customer?.postal_code ?? "",
      country: customer?.country ?? "",
      phone: customer?.phone ?? "",
      email_finance: customer?.email_finance ?? "",
      email_operations: customer?.email_operations ?? "",
    },

    validate: {
      name: (value) => validateString(value),
      address: (value) => validateString(value),
      postal_code: (value) => validateString(value),
      country: (value) => validateString(value),
      phone: (value) => validateString(value),
      email_finance: (value) => validateEmail(value),
      email_operations: (value) => validateEmail(value),
    },
  });

  const countryList = getData().map((value: any) => ({
    label: value.name,
    value: value.code,
  }));

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput
        pb={"sm"}
        required
        label="Name"
        {...form.getInputProps("name")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          required: {
            color: "#004F65",
          },
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
        })}
      />

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          label="Address"
          {...form.getInputProps("address")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />

        <TextInput
          required
          label="Postal code"
          {...form.getInputProps("postal_code")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <Select
          required
          searchable
          label="Country"
          data={countryList}
          {...form.getInputProps("country")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            item: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
        />
        <></>
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          label="E-mail address finance"
          type={"email"}
          {...form.getInputProps("email_finance")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
          icon={<Mail size={16} />}
        />

        <TextInput
          required
          label="E-mail address operations"
          type={"email"}
          {...form.getInputProps("email_operations")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
          icon={<Mail size={16} />}
        />
      </Group>

      <Group className={classes.group} grow pb={"sm"}>
        <TextInput
          required
          label="Phone number"
          placeholder={"+31 528 123456"}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
            required: {
              color: "#004F65",
            },
            input: {
              borderColor: theme.colors["custom-gray"][7],
              "&:focus": {
                borderColor: theme.colors["bright-green"][0],
              },
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors["custom-gray"][0],
            },
          })}
          icon={<Phone size={16} />}
        />
        <></>
      </Group>

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Save"}
        </Button>
      </Group>
    </form>
  );
}

export default CustomerForm;
