import React, { ComponentProps } from "react";
import { Group, Text, MantineTheme, useMantineTheme } from "@mantine/core";
import {
  Dropzone,
  DropzoneStatus,
  IMAGE_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from "@mantine/dropzone";
import { Check, Icon, Photo, Upload, X } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import useMutateBunkerRequestData from "../../hooks/bunkerRequest/useMutateBunkerRequestData";
import { uploadAttachmentToBunkerRequest } from "../../api/bunkerRequests";

function getIconColor(status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
    : status.rejected
    ? theme.colors["custom-red"][theme.colorScheme === "dark" ? 4 : 6]
    : theme.colorScheme === "dark"
    ? theme.colors.dark[0]
    : theme.colors["custom-gray"][0];
}

function ImageUploadIcon({
  status,
  ...props
}: ComponentProps<Icon> & { status: DropzoneStatus }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <Photo {...props} />;
}

export const dropzoneChildren = (
  status: DropzoneStatus,
  theme: MantineTheme
) => (
  <Group
    position="center"
    spacing="xl"
    style={{ minHeight: 220, pointerEvents: "none" }}
  >
    <ImageUploadIcon
      status={status}
      style={{ color: getIconColor(status, theme) }}
      size={80}
    />

    <div>
      <Text
        size="xl"
        inline
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        })}
      >
        Drag files here or click to select files
      </Text>
      <Text
        size="sm"
        sx={(theme) => ({
          color: theme.colors["custom-gray"][9],
        })}
        inline
        mt={7}
      >
        Attach as many files as you like, each file should not exceed 5MB.
      </Text>
    </div>
  </Group>
);

type BunkerAttachmentDropzoneProps = {
  id: string;
  onClose?: () => void;
};

const BunkerAttachmentDropzone = (props: BunkerAttachmentDropzoneProps) => {
  const { id, onClose } = props;
  const theme = useMantineTheme();

  const onSuccess = () => {
    showNotification({
      title: "Documents attached to bunker requests",
      icon: <Check />,
      message: `Your documents have been attached to the bunker request!`,
    });

    onClose && onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Something went wrong`,
      message: `An error occurred while uploading your files. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateBunkerRequestData(
    uploadAttachmentToBunkerRequest,
    onSuccess,
    onError
  );

  const onDrop = (files: File[]) => {
    mutate({
      id: id,
      files: files,
    });
  };

  return (
    <Dropzone
      loading={isLoading}
      onDrop={onDrop}
      multiple={true}
      accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE, ...MS_WORD_MIME_TYPE]}
    >
      {(status) => dropzoneChildren(status, theme)}
    </Dropzone>
  );
};

export default BunkerAttachmentDropzone;
