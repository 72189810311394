import React, { useRef, useState } from "react";
import { Autocomplete, AutocompleteItem, Loader } from "@mantine/core";
import { DefaultProps } from "@mantine/styles";
import { AutocompleteStylesNames } from "@mantine/core/lib/components/Autocomplete/Autocomplete";
import { DEBOUNCE_TIMEOUT } from "../../constants";
import { SortingRule } from "react-table";

interface SearchSelectProps extends DefaultProps<AutocompleteStylesNames> {
  error?: React.ReactNode;
  onItemSubmit: (value: any) => void;
  placeholder?: string;
  fetch: (
    page: number,
    pageSize: number,
    sortByData: SortingRule<object>[],
    value: string,
    ...args: any[]
  ) => Promise<any>;
  valueFormatter: (obj: any) => string;
}

export function SearchSelect(props: SearchSelectProps) {
  const { onItemSubmit, placeholder, fetch, valueFormatter, ...restProps } =
    props;
  const timeoutRef = useRef<number>(-1);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AutocompleteItem[]>([]);

  const onChange = (val: string) => {
    // Reset the wait period before sending the request to prevent spamming the API.
    window.clearTimeout(timeoutRef.current);

    setValue(val);
    setData([]);

    if (val.trim().length === 0) {
      setLoading(false);
    } else {
      setLoading(true);

      // Send the request after waiting the global DEBOUNCE_TIMEOUT to ensure that the typing has stopped.
      timeoutRef.current = window.setTimeout(async () => {
        let filteredData = await fetch(0, 20, [], val);

        setData(
          filteredData.data.map((d: any) => {
            return {
              item: d,
              value: valueFormatter(d),
            };
          })
        );

        setLoading(false);
      }, DEBOUNCE_TIMEOUT);
    }
  };

  return (
    <Autocomplete
      {...restProps}
      data={data}
      value={value}
      limit={10}
      onChange={onChange}
      onItemSubmit={(value) => onItemSubmit(value.item)}
      rightSection={loading ? <Loader size={16} /> : null}
      placeholder={placeholder ?? "Search"}
      styles={(theme) => ({
        item: {
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
        rightSection: { pointerEvents: "none" },
        input: {
          borderColor: theme.colors["custom-gray"][7],
          "&:focus": {
            borderColor: theme.colors["bright-green"][0],
          },
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[0]
              : theme.colors["custom-gray"][0],
        },
      })}
    />
  );
}
