import React, {useContext} from 'react';
import {Group, Tabs, Title} from "@mantine/core";
import {UserContext} from "../../providers/user";
import EmailSettingsTab from "../../components/tabs/EmailSettingsTab";
import ContactSettingsTab from "../../components/tabs/ContactSettingsTab";

export default function Admin() {
    const {isAuthorized} = useContext(UserContext);

    return (
        <>
            <Group position={"apart"}>
                <Title>Settings</Title>
            </Group>

            <Tabs>
                {isAuthorized('email_settings') && (
                    <Tabs.Tab label="E-mail settings">
                        <EmailSettingsTab />
                    </Tabs.Tab>
                )}

                {isAuthorized('contact_settings') && (
                    <Tabs.Tab label="Contact settings">
                        <ContactSettingsTab />
                    </Tabs.Tab>
                )}
            </Tabs>
        </>
    );
}