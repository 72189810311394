import {useModals} from "@mantine/modals";
import {useNavigate} from "react-router-dom";
import React from "react";
import EditLocationForm from "../../components/forms/location/EditLocationForm";

export default function useEditLocation(navigateAway: boolean = false) {
    const modals = useModals();
    const navigate = useNavigate();

    const openModal = (id: string) => {
        const modalId = modals.openModal({
            size: "xl",
            title: 'Edit location',
            closeOnClickOutside: false,
            children: (
                <>
                    <EditLocationForm locationId={id} onClose={() => closeModal(modalId)}/>
                </>
            ),
        });
    };

    const closeModal = (id: string) => {
        modals.closeModal(id)
        navigateAway && navigate("/locations")
    }

    return {
        openModal
    }
}
