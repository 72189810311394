import {Button} from "@mantine/core";
import useCreateBunkerRequest from "../../hooks/bunkerRequest/useCreateBunkerRequest";

type Props = {
    buttonText?: string,
}

export default function CreateBunkerRequestButton(props: Props) {
    const {buttonText} = props;
    const {openModal} = useCreateBunkerRequest();

    return (
        <Button onClick={() => openModal()}>{buttonText ?? "Create bunker request"}</Button>
    )
}