import {Button} from "@mantine/core";
import useCreateUser from "../../hooks/user/useCreateUser";
import {useContext} from "react";
import {UserContext} from "../../providers/user";

type Props = {
    buttonText?: string,
}

export default function CreateUserButton(props: Props) {
    const {buttonText} = props;
    const {isAuthorized} = useContext(UserContext);
    const {openModal} = useCreateUser();

    return (
        <>
            {isAuthorized("user_store") && <Button onClick={() => openModal()}>{buttonText ?? "Create user"}</Button>}
        </>
    )
}