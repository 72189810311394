import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { Button, TextInput } from "@mantine/core";
import { validateSame, validateString } from "../../validator";
import { Mail } from "tabler-icons-react";

type ResetPasswordFormProps = {
  onSubmit: () => void;
  setValues: (values: any) => void;
};

function PasswordForgottenForm(props: ResetPasswordFormProps) {
  const { onSubmit, setValues } = props;

  const form = useForm({
    initialValues: {
      email: "",
      email_confirmation: "",
    },

    validate: (values) => ({
      email: validateString(values.email),
      email_confirmation: validateSame(
        values.email_confirmation,
        values.email,
        "the email field"
      ),
    }),
  });

  useEffect(() => {
    setValues(form.values);
  }, [form.values]);

  return (
    <form onSubmit={form.onSubmit(() => onSubmit())}>
      <TextInput
        mb={"md"}
        label="Email"
        placeholder="Email"
        required
        {...form.getInputProps("email")}
        styles={(theme) => ({
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          description: {
            color: "#8C9196",
          },
          icon: {
            color: "#d5d9de",
          },
          required: {
            color: "#004F65",
          },
        })}
        icon={<Mail size={16} />}
      />

      <TextInput
        mb={"md"}
        label="Email confirmation"
        placeholder="Email confirmation"
        required
        {...form.getInputProps("email_confirmation")}
        styles={(theme) => ({
          input: {
            borderColor: theme.colors["custom-gray"][7],
            "&:focus": {
              borderColor: theme.colors["bright-green"][0],
            },
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          label: {
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors["custom-gray"][0],
          },
          description: {
            color: "#8C9196",
          },
          icon: {
            color: "#d5d9de",
          },
          required: {
            color: "#004F65",
          },
        })}
        icon={<Mail size={16} />}
      />

      <Button mb={"md"} fullWidth type={"submit"}>
        Request password reset
      </Button>
    </form>
  );
}

export default PasswordForgottenForm;
