import {FETCH_HEADERS, MAPBOX_TOKEN} from "../constants";

export const fetchLocations = async (query: string = "") => {
    return await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_TOKEN}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};
