import {useModals} from "@mantine/modals";
import CreateShipForm from "../../components/forms/ship/CreateShipForm";

export default function useCreateShip() {
    const modals = useModals();

    const openModal = () => {
        const modalId = modals.openModal({
            size: 'xl',
            title: 'Add ship',
            closeOnClickOutside: false,
            children: (
                <CreateShipForm onClose={() => closeModal(modalId)}/>
            )
        });
    };

    const closeModal = function(modalId: string) {
        modals.closeModal(modalId);
    };

    return {
        openModal,
    };
}